import clone from 'lodash/clone'
import uniq from 'lodash/uniq'
import pluralize from 'pluralize'

import { uuid } from '@coko/client'

const wormPatterns = [
  {
    type: 'GENE',
    pattern: /(?:Bma-|Cel-|Cbr-|Cre-|Cbn-|Cjp-|Ovo-|Ppa-|Sra-|Tmu-)?[a-z]{1,4}-\d+/g,
    italicRequired: true,
    matchIsSource: false,
    urlConstuctor: 'https://wormbase.org/species/c_elegans/gene/',
    source: 'WormBase',
  },
  {
    type: 'PROTEIN',
    pattern: /[A-Z]{1,4}-\d+/g,
    italicRequired: false,
    matchIsSource: false,
    urlConstuctor: 'https://wormbase.org/species/c_elegans/protein/',
    source: 'WormBase',
  },
  {
    type: 'VARIANT',
    pattern: /[a-z]{1,3}\d+/g,
    italicRequired: true,
    matchIsSource: false,
    urlConstuctor: 'https://wormbase.org/species/c_elegans/variation/',
    source: 'WormBase',
  },
  {
    type: 'VARIANT',
    pattern: /[a-z]{1,3}\d+[a-z]{1,3}\d+(?!\.\d+)/g,
    italicRequired: true,
    matchIsSource: false,
    urlConstuctor: 'https://wormbase.org/species/c_elegans/variation/',
    source: 'WormBase',
  },
  {
    type: 'TRANSGENE',
    pattern: /[a-z]{1,3}(Is|Si|Ex)\d+/g,
    italicRequired: true,
    matchIsSource: false,
    urlConstuctor: 'https://wormbase.org/species/c_elegans/transgene/',
    source: 'WormBase',
  },
  {
    type: 'STRAIN',
    pattern: /[A-Z]{2,3}\d+/g,
    italicRequired: false,
    matchIsSource: false,
    urlConstuctor: 'https://wormbase.org/species/c_elegans/strain/',
    source: 'WormBase',
  },
]

const yeastPatterns = [
  {
    type: 'GENE',
    pattern: /[A-Z]{3}\d+/g,
    italicRequired: true,
    matchIsSource: false,
    urlConstuctor: 'https://yeastgenome.org/locus/',
    source: 'SGD',
  },
]

const flyPatterns = [
  {
    type: 'GENE',
    pattern: /C[GR]\d{4,5}/g,
    italicRequired: false,
    matchIsSource: false,
    urlConstuctor: 'https://flybase.org/reports/',
    source: 'FlyBase',
  },
  {
    type: 'GENE',
    pattern: /FBgn\d{7}/g,
    italicRequired: false,
    matchIsSource: true,
    urlConstuctor: 'https://flybase.org/reports/',
    source: 'FlyBase',
  },
]

const arabidopsisPatterns = [
  {
    type: 'GENE',
    pattern: /A[Tt]\d[Gg]\d+\.?\d?/g,
    italicRequired: false,
    matchIsSource: true,
    urlConstuctor: 'https://www.arabidopsis.org/locus?name=',
    source: 'TAIR',
  },
]

const pombePatterns = [
  {
    type: 'GENE',
    pattern: /[a-z]{3}\d+/g,
    italicRequired: true,
    matchIsSource: false,
    urlConstuctor: 'https://www.pombase.org/spombe/result/',
    source: 'PomBase',
  },
  {
    type: 'PROTEIN',
    pattern: /[A-Z][a-z]{2}\d+p*/g,
    italicRequired: false,
    matchIsSource: false,
    urlConstuctor: 'https://www.pombase.org/spombe/result/',
    source: 'PomBase',
  },
  {
    type: 'GENE',
    pattern: /SP[A-Z]+\d+\.\d+\w?/g,
    italicRequired: true,
    matchIsSource: true,
    urlConstuctor: 'https://www.pombase.org/spombe/result/',
    source: 'PomBase',
  },
]

const ncbiPatterns = [
  {
    type: 'OTHER',
    pattern: /GCA_\d+\.\d+/g,
    italicRequired: false,
    matchIsSource: true,
    urlConstuctor: 'https://www.ncbi.nlm.nih.gov/datasets/genome/',
    source: 'NCBI',
  },
  {
    type: 'GENE',
    pattern: /XM_\d+/g,
    italicRequired: false,
    matchIsSource: true,
    urlConstuctor: 'https://www.ncbi.nlm.nih.gov/nuccore/',
    source: 'NCBI',
  },
  {
    type: 'GENE',
    pattern: /LOC(\d+)/g,
    italicRequired: false,
    matchIsSource: true,
    urlConstuctor: 'https://www.ncbi.nlm.nih.gov/gene/',
    source: 'NCBI',
  },
  {
    type: 'PROTEIN',
    pattern: /[NX]P_\d+\.\d+/g,
    italicRequired: false,
    matchIsSource: true,
    urlConstuctor: 'https://www.ncbi.nlm.nih.gov/protein/',
    source: 'NCBI',
  },
  {
    type: 'OTHER',
    pattern: /SRP\d+/g,
    italicRequired: false,
    matchIsSource: true,
    urlConstuctor: 'https://trace.ncbi.nlm.nih.gov/Traces/?view=study&acc=',
    source: 'NCBI',
  },
  {
    type: 'OTHER',
    pattern: /CH\d+\.\d+/g,
    italicRequired: false,
    matchIsSource: true,
    urlConstuctor: 'https://www.ncbi.nlm.nih.gov/nuccore/',
    source: 'NCBI',
  },
  {
    type: 'OTHER',
    pattern: /PRJNA\d+/g,
    italicRequired: false,
    matchIsSource: true,
    urlConstuctor: 'https://www.ncbi.nlm.nih.gov/bioproject/',
    source: 'NCBI',
  },
  {
    type: 'OTHER',
    pattern: /SAMN\d+/g,
    italicRequired: false,
    matchIsSource: true,
    urlConstuctor: 'https://www.ncbi.nlm.nih.gov/biosample/',
    source: 'NCBI',
  },
  {
    type: 'OTHER',
    pattern: /BK\d+/g,
    italicRequired: false,
    matchIsSource: true,
    urlConstuctor: 'https://www.ncbi.nlm.nih.gov/nuccore/',
    source: 'NCBI',
  },
  {
    type: 'OTHER',
    pattern: /PP\d+/g,
    italicRequired: false,
    matchIsSource: true,
    urlConstuctor: 'https://www.ncbi.nlm.nih.gov/nuccore/',
    source: 'NCBI',
  },
  {
    type: 'OTHER',
    pattern: /SRX\d+/g,
    italicRequired: false,
    matchIsSource: true,
    urlConstuctor: 'https://www.ncbi.nlm.nih.gov/sra/',
    source: 'NCBI',
  },
  {
    type: 'OTHER',
    pattern: /KY\d+\.\d+/g,
    italicRequired: false,
    matchIsSource: true,
    urlConstuctor: 'https://www.ncbi.nlm.nih.gov/nuccore/',
    source: 'NCBI',
  },
  {
    type: 'OTHER',
    pattern: /PQ\d+/g,
    italicRequired: false,
    matchIsSource: true,
    urlConstuctor: 'https://www.ncbi.nlm.nih.gov/nuccore/',
    source: 'NCBI',
  },
  {
    type: 'OTHER',
    pattern: /MN\d+/g,
    italicRequired: false,
    matchIsSource: true,
    urlConstuctor: 'https://www.ncbi.nlm.nih.gov/nuccore/',
    source: 'NCBI',
  },
]

const sections = [
  'patternDescription',
  'abstract',
  'imageCaption',
  // 'imageTitle',
  'methods',
  'reagents',
]

const linkEntity = (doc, entity) => {
  /*
  if (entity.match.includes('<sup>')) {
    console.log(entity)
    // Handle fly alleles
    const italics = doc.getElementsByTagName('i')
    Array.from(italics).forEach(italic => {
      Array.from(italic.childNodes).forEach((child, index) => {
        if (child.nodeName === 'SUP') {
          if (
            italic.childNodes[index - 1].nodeValue !== '' &&
            entity.match ===
              `${italic.childNodes[index - 1].nodeValue}<sup>${
                child.firstChild.nodeValue
              }</sup>`
          )
            console.log('match')
        }
      })
    })
  }
  */

  const paragraphs = doc.getElementsByTagName('p')
  Array.from(paragraphs).forEach(paragraph => {
    const iter = document.createNodeIterator(paragraph, NodeFilter.SHOW_TEXT)

    let node = iter.nextNode()
    const textNodes = []
    // const anchorNodes = []

    while (node) {
      textNodes.push(node)
      node = iter.nextNode()
    }

    // const charTest = /[\w^:]/
    textNodes.forEach(textNode => {
      const parent = textNode.parentElement
      if (!parent) return
      const textValue = textNode.nodeValue

      if (entity.match.includes('<sup>') && parent.nodeName === 'SUP') {
        if (parent.parentElement.nodeName === 'I') {
          const children = parent.parentElement.childNodes
          children.forEach((child, index) => {
            const baseValue = index > 0 && children[index - 1].nodeValue

            if (
              child === parent &&
              baseValue !== '' &&
              entity.match === `${baseValue}<sup>${textValue}</sup>`
            ) {
              const linkText = doc.createTextNode(baseValue)
              const sup = doc.createElement('sup')
              const supText = doc.createTextNode(textValue)
              sup.appendChild(supText)

              const link = doc.createElement('a')

              if (!entity.deleted) {
                entity.sourceId &&
                  entity.url &&
                  link.setAttribute('href', entity.url)
                link.setAttribute('id', uuid())
              }

              link.appendChild(linkText)
              link.appendChild(sup)
              parent.parentElement.insertBefore(link, parent)

              parent.parentElement.removeChild(children[index - 1])
              parent.parentElement.removeChild(parent)
            }
          })
        }
      } else {
        //  const start = textValue.indexOf(entity.match)
        // Escape entity for regular expression
        const pattern = RegExp(
          entity.match.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
        )

        if (parent.nodeName.toLowerCase() !== 'a') {
          // if (!anchorNodes.some(a => a.isEqualNode(textNode))) {
          const result = pattern.exec(textValue)

          if (result) {
            /*
          if (
            result.index > 0 &&
            charTest.test(textValue.substring(result.index - 1, result.index))
          )
            return

          if (
            result.index + entity.match.length < textValue.length &&
            charTest.test(
              textValue.substring(
                result.index + entity.match.length,
                result.index + entity.match.length + 1,
              ),
            )
          ) {
            return
          }
          */

            if (
              entity.sourceId &&
              entity.sourceId.indexOf('FB') === 0 &&
              parent.nodeName !== 'I'
            )
              return

            if (
              entity.sourceId &&
              entity.sourceId.indexOf('FB') === 0 &&
              result[0].length < 4 &&
              result.input.length > result[0].length
            ) {
              return
            }

            const nextChar = textValue.substring(
              result.index + entity.match.length,
              result.index + entity.match.length + 1,
            )

            if (nextChar === '-' || nextChar === 'Δ' || nextChar === '∆') return

            const startText = textValue.substring(0, result.index)
            const startNode = doc.createTextNode(startText)
            parent.insertBefore(startNode, textNode)

            const linkText = doc.createTextNode(entity.match)

            /*
          const link =
            entity.method === 'prediction' || entity.method === 'added'
              ? doc.createElement('ins')
              : doc.createElement('a')
          */

            const link = doc.createElement('a')

            if (!entity.deleted) {
              entity.sourceId &&
                entity.url &&
                link.setAttribute('href', entity.url)
              link.setAttribute('id', uuid())
            }

            link.appendChild(linkText)
            parent.insertBefore(link, textNode)

            const endText = textValue.substring(
              result.index + entity.match.length,
            )

            const endNode = doc.createTextNode(endText)
            parent.insertBefore(endNode, textNode)
            parent.removeChild(textNode)

            // result = pattern.exec(textValue)
            linkEntity(doc, entity)
          }
        }
      }
    })
  })
}

const linkDoc = (html, entities) => {
  if (!entities) return html

  const domParser = new DOMParser()

  const mainDoc = domParser.parseFromString(
    html.replace(/’/g, "'"),
    'text/html',
  )

  const elements = mainDoc.getElementsByTagName('A')

  Array.from(elements).forEach(element => {
    if (!element.hasAttribute('href')) {
      const textNode = mainDoc.createTextNode(
        element.text || element.textContent,
      )

      element.parentNode.replaceChild(textNode, element)
    }
  })

  entities.forEach(entity => {
    linkEntity(mainDoc, entity)

    if (
      entity.type === 'LIFESTAGE' ||
      entity.type === 'ANATOMY' ||
      entity.type === 'GOCC'
    ) {
      const clonedEntity = clone(entity)
      clonedEntity.match = pluralize(entity.match)
      linkEntity(mainDoc, clonedEntity)
    }
  })

  const serializer = new XMLSerializer()

  const mainOutput = serializer.serializeToString(mainDoc.body)

  return mainOutput
    .replace('<body xmlns="http://www.w3.org/1999/xhtml">', '')
    .replace('</body>', '')
}

const linkHTML = (version, entities) => {
  if (!version) return entities
  const newData = clone(version)

  sections.forEach(section => {
    if (version[section]) newData[section] = linkDoc(version[section], entities)
  })

  return newData
}

const deleteEntity = (html, entity) => {
  const domParser = new DOMParser()

  const mainDoc = domParser.parseFromString(
    html.replace(/’/g, "'"),
    'text/html',
  )

  /* 
  const elements =
    entity.method === 'prediction' || entity.method === 'added'
      ? mainDoc.getElementsByTagName('INS')
      : mainDoc.getElementsByTagName('A')
  */

  const elements = mainDoc.getElementsByTagName('A')

  Array.from(elements).forEach(element => {
    if (element.text === entity.match || element.textContent === entity.match) {
      const textNode = mainDoc.createTextNode(entity.match)
      element.parentNode.replaceChild(textNode, element)
    }
  })

  const serializer = new XMLSerializer()
  const mainOutput = serializer.serializeToString(mainDoc.body)
  return mainOutput
    .replace('<body xmlns="http://www.w3.org/1999/xhtml">', '')
    .replace('</body>', '')
}

const deleteLinks = (version, entity) => {
  const newData = clone(version)

  sections.forEach(
    section => (newData[section] = deleteEntity(newData[section], entity)),
  )

  const pluralizedEntity = clone(entity)
  pluralizedEntity.match = pluralize(entity.match)
  sections.forEach(
    section =>
      (newData[section] = deleteEntity(newData[section], pluralizedEntity)),
  )

  return newData
}

const countEntity = (html, entity) => {
  const domParser = new DOMParser()

  const mainDoc = domParser.parseFromString(
    html.replace(/’/g, "'"),
    'text/html',
  )

  let linkCount = 0

  const anchors = mainDoc.getElementsByTagName('A')
  Array.from(anchors).forEach(anchor => {
    const plural = pluralize(entity.match)

    if (
      entity.match.includes('<sup>') &&
      anchor.lastChild.firstChild &&
      entity.match ===
        `${anchor.firstChild.nodeValue}<sup>${anchor.lastChild.firstChild.nodeValue}</sup>`
    ) {
      linkCount += 1
    }

    if (anchor.text === entity.match || anchor.text === plural) {
      linkCount += 1
    }
  })
  return linkCount
}

const countLinks = (version, entities) => {
  if (!entities) return []

  const newEntities = entities
    .map(entity => {
      const newEntity = clone(entity)
      newEntity.count = 0

      //   if (entity.method === 'prediction' || entity.method === 'added') {
      //     newEntity.count = 1
      //  } else {
      // newEntity.method = 'matched'
      sections.forEach(section => {
        if (version[section])
          newEntity.count += countEntity(version[section], entity)
      })
      // }

      return newEntity
    })
    .filter(entity => entity.count > 0)

  return newEntities
}

const applyRegex = (html, pattern, italicRequired) => {
  const domParser = new DOMParser()
  const mainDoc = domParser.parseFromString(html, 'text/html')

  const matches = []

  const charTest = /\w/

  const elements = italicRequired
    ? mainDoc.getElementsByTagName('I')
    : mainDoc.getElementsByTagName('P')

  Array.from(elements).forEach(element => {
    let result = pattern.exec(element.textContent)

    while (result) {
      if (
        result &&
        !charTest.test(
          result.input.substring(result.index - 1, result.index),
        ) &&
        !charTest.test(
          result.input.substring(
            result.index + result[0].length,
            result.index + result[0].length + 1,
          ),
        )
      ) {
        matches.push(result)
      }

      result = pattern.exec(element.textContent)
    }
  })
  return uniq(matches)
}

const patternsMap = {
  'c. elegans': wormPatterns,
  's. cerevisiae': yeastPatterns,
  drosophila: flyPatterns,
  arabidopsis: arabidopsisPatterns,
  's. pombe': pombePatterns,
  ncbi: ncbiPatterns,
}

const predictEntities = (version, kwsSpecies) => {
  const { species } = version

  const html = sections.map(section => version[section]).join(' ')

  const predictions = []

  const ncbiSpecies = [...species, kwsSpecies, 'ncbi']
  ncbiSpecies.forEach(s => {
    patternsMap[s] &&
      patternsMap[s].forEach(speciesPattern => {
        const {
          type,
          pattern,
          italicRequired,
          matchIsSource,
          urlConstuctor,
          source,
        } = speciesPattern

        const matches = applyRegex(html, pattern, italicRequired)
        matches.forEach(match => {
          predictions.push({
            match: match[0],
            type,
            method: 'prediction',
            url: matchIsSource
              ? `${urlConstuctor}${match[1] || match[0]}`
              : urlConstuctor,
            matchIsSource,
            sourceId: matchIsSource ? match[0] : null,
            source,
          })
        })
      })
  })

  return predictions
}

const deleteLinkById = (html, id) => {
  const domParser = new DOMParser()
  const mainDoc = domParser.parseFromString(html, 'text/html')

  const elements = mainDoc.getElementsByTagName('A')

  Array.from(elements).forEach(element => {
    if (element.id === id) {
      // const textNode = mainDoc.createTextNode(element.childNodes[0])
      // element.parentNode.replaceChild(element.childNodes[0], element)
      element.removeAttribute('href')
      element.removeAttribute('id')
    }
  })

  const serializer = new XMLSerializer()
  const mainOutput = serializer.serializeToString(mainDoc.body)
  return mainOutput
    .replace('<body xmlns="http://www.w3.org/1999/xhtml">', '')
    .replace('</body>', '')
}

const deleteLink = (version, id) => {
  const clonedVersion = clone(version)
  sections.forEach(
    section => (clonedVersion[section] = deleteLinkById(version[section], id)),
  )

  return clonedVersion
}

const unDeleteLink = (version, entityText) => {
  const clonedVersion = clone(version)
  sections.forEach(section => {
    const domParser = new DOMParser()
    const mainDoc = domParser.parseFromString(version[section], 'text/html')

    const elements = mainDoc.getElementsByTagName('A')

    Array.from(elements).forEach(element => {
      if (
        element.text === entityText &&
        !element.hasAttribute('href') &&
        !element.hasAttribute('id')
      ) {
        const textNode = mainDoc.createTextNode(entityText)
        element.parentNode.replaceChild(textNode, element)
      }
    })

    const serializer = new XMLSerializer()
    const mainOutput = serializer.serializeToString(mainDoc.body)
    clonedVersion[section] = mainOutput
      .replace('<body xmlns="http://www.w3.org/1999/xhtml">', '')
      .replace('</body>', '')
  })

  return clonedVersion
}

export {
  linkHTML,
  countLinks,
  deleteLink,
  deleteLinks,
  predictEntities,
  unDeleteLink,
}
