import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import clone from 'lodash/clone'

import {
  SPECIES,
  CATEGORIES,
  SUBMISSION_TYPES,
  RESEARCH_AREAS,
  SPECIALTIES,
  GROUPS,
  DELETE_TAXONOMY,
  ADD_TAXONOMY,
  UPDATE_TAXONOMY,
} from '../graphql'
import { TaxonomyManager } from '../../ui'

const TaxonomyManagerPage = () => {
  const { data: speciesData, loading: speciesLoading } = useQuery(SPECIES)

  const { data: categoriesData, loading: categoriesLoading } = useQuery(
    CATEGORIES,
  )

  const { data: typesData, loading: typesLoading } = useQuery(SUBMISSION_TYPES)

  const { data: researchData, loading: researchLoading } = useQuery(
    RESEARCH_AREAS,
  )

  const { data: specialtiesData, loading: specialtiesLoading } = useQuery(
    SPECIALTIES,
  )

  const { data: groupsData, loading: groupsLoading } = useQuery(GROUPS)

  const queryRefetch = {
    refetchQueries: [
      {
        query: SPECIES,
      },
      {
        query: CATEGORIES,
      },
      {
        query: SUBMISSION_TYPES,
      },
      { query: RESEARCH_AREAS },
      { query: SPECIALTIES },
      { query: GROUPS },
    ],
  }

  const [deleteTaxonomy] = useMutation(DELETE_TAXONOMY, queryRefetch)
  const [addTaxonomy] = useMutation(ADD_TAXONOMY, queryRefetch)
  const [updateTaxonomy] = useMutation(UPDATE_TAXONOMY, queryRefetch)

  const deleteTaxonomyFn = id => deleteTaxonomy({ variables: { id } })
  const addTaxonomyFn = taxonomy => addTaxonomy({ variables: { taxonomy } })

  const updateSpecies = (id, taxonomy) => {
    const newTaxonomy = clone(taxonomy)
    delete newTaxonomy.id
    newTaxonomy.type = 'species'
    return updateTaxonomy({ variables: { id, taxonomy: newTaxonomy } })
  }

  const updateCategory = (id, taxonomy) => {
    const newTaxonomy = clone(taxonomy)
    delete newTaxonomy.id
    newTaxonomy.type = 'categories'
    return updateTaxonomy({ variables: { id, taxonomy: newTaxonomy } })
  }

  const updateType = (id, taxonomy) => {
    const newTaxonomy = clone(taxonomy)
    delete newTaxonomy.id
    newTaxonomy.type = 'submissionTypes'
    return updateTaxonomy({ variables: { id, taxonomy: newTaxonomy } })
  }

  const updateResearchArea = (id, taxonomy) => {
    const newTaxonomy = clone(taxonomy)
    delete newTaxonomy.id
    newTaxonomy.type = 'researchAreas'
    return updateTaxonomy({ variables: { id, taxonomy: newTaxonomy } })
  }

  const updateSpecialty = (id, taxonomy) => {
    const newTaxonomy = clone(taxonomy)
    delete newTaxonomy.id
    newTaxonomy.type = 'specialties'
    return updateTaxonomy({ variables: { id, taxonomy: newTaxonomy } })
  }

  const updateGroup = (id, taxonomy) => {
    const newTaxonomy = clone(taxonomy)
    delete newTaxonomy.id
    newTaxonomy.type = 'groups'
    return updateTaxonomy({ variables: { id, taxonomy: newTaxonomy } })
  }

  /**
   * Prepare data for use by the UI
   */
  const species =
    speciesData &&
    speciesData.species.map(s => ({
      updateTaxonomy: updateSpecies,
      deleteTaxonomy: deleteTaxonomyFn,
      id: s.id,
      label: s.label,
      value: s.value,
      imageSrc: s.imageSrc,
      imageAlt: s.imageAlt,
      modLink: s.modLink,
      mod: s.mod,
      linkVariable: s.linkVariable,
      description: s.description,
      externalId: s.externalId,
    }))

  const categories =
    categoriesData &&
    categoriesData.categories.map(c => ({
      updateTaxonomy: updateCategory,
      deleteTaxonomy: deleteTaxonomyFn,
      id: c.id,
      label: c.label,
      value: c.value,
      description: c.description,
    }))

  const submissionTypes =
    typesData &&
    typesData.submissionTypes.map(s => ({
      updateTaxonomy: updateType,
      deleteTaxonomy: deleteTaxonomyFn,
      id: s.id,
      label: s.label,
      value: s.value,
      description: s.description,
    }))

  const researchAreas =
    researchData &&
    researchData.researchAreas.map(s => ({
      updateTaxonomy: updateResearchArea,
      deleteTaxonomy: deleteTaxonomyFn,
      id: s.id,
      label: s.label,
      value: s.value,
      description: s.description,
    }))

  const specialties =
    specialtiesData &&
    specialtiesData.specialties.map(s => ({
      updateTaxonomy: updateSpecialty,
      deleteTaxonomy: deleteTaxonomyFn,
      id: s.id,
      label: s.label,
      value: s.value,
      description: s.description,
    }))

  const groups =
    groupsData &&
    groupsData.groups.map(s => ({
      updateTaxonomy: updateGroup,
      deleteTaxonomy: deleteTaxonomyFn,
      id: s.id,
      label: s.label,
      value: s.value,

      modLink: s.modLink,
      mod: s.mod,

      description: s.description,
    }))

  return (
    <TaxonomyManager
      addTaxonomy={addTaxonomyFn}
      categories={categories}
      groups={groups}
      loading={
        speciesLoading ||
        categoriesLoading ||
        typesLoading ||
        researchLoading ||
        specialtiesLoading ||
        groupsLoading
      }
      researchAreas={researchAreas}
      specialties={specialties}
      species={species}
      submissionTypes={submissionTypes}
    />
  )
}

export default TaxonomyManagerPage
