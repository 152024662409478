import flatten from 'lodash/flatten'
import forIn from 'lodash/forIn'
import get from 'lodash/get'
import isUndefined from 'lodash/isUndefined'
import keys from 'lodash/keys'
import pickBy from 'lodash/pickBy'
import uniq from 'lodash/uniq'

const detectionMethodCorrelations = {
  antibody: ['antibodyUsed'],
  existingTransgene: ['transgeneUsed'],
  genomeEditing: ['variation'],
  inSituHybridization: ['inSituDetails'],
  newTransgene: [
    'genotype',
    'constructionDetails',
    'dnaSequence',
    'utr',
    'reporter',
    'backboneVector',
    'fusionType',
    'constructComments',
    'transgeneName',
    'strain',
    'coinjected',
    'injectionConcentration',
    'integratedBy',
  ],
}

const creditMap = {
  conceptualization: 'Conceptualization',
  dataCuration: 'Data curation',
  formalAnalysis: 'Formal analysis',
  fundingAcquisition: 'Funding acquisition',
  investigation: 'Investigation',
  methodology: 'Methodology',
  project: 'Project administration',
  resources: 'Resources',
  software: 'Software',
  supervision: 'Supervision',
  validation: 'Validation',
  visualization: 'Visualization',
  writing_originalDraft: 'Writing - original draft',
  writing_reviewEditing: 'Writing - review & editing',
}

/*
  Incoming:
    [
      {
        firstName: ...,
        lastName: ...,
        affiliations: ['NYU', 'UCLA'],
        correspondingAuthor: true,
      },
      ...
    ]

  Outgoing:
    authorData:
      [
        {
          name: ...,
          id: 'author-xxxxxxx',
          isCorresponding: true,
          affiliations: [1, 2]
        },
        ...
      ]
    affiliationData:
      [
        {
          index: 1,
          value: 'NYU',
        },
        ...
      ]
    correspondingAuthorData:
      [
        {
          id: ...,
          firstName: ...,
          lastName: ...,
          email: ...,
        }
      ]
*/

const creditString = credit =>
  credit.map(c => (creditMap[c] ? creditMap[c] : c)).join(', ')

const transformAuthorData = authors => {
  if (!authors) return {}

  const uniqueAffiliations = uniq(
    flatten(
      authors.map(author =>
        author.affiliations.map((a, i) =>
          author.departments && author.departments[i] !== ''
            ? `${author.departments[i]}, ${a}`
            : a,
        ),
      ),
    ),
  )

  const hasSingleAuthor = authors.length === 1

  const affiliationData = uniqueAffiliations.map((value, index) => ({
    index: index + 1,
    value,
    hasSingleAuthor,
  }))

  const authorData = authors.map(author => ({
    affiliations: author.affiliations.map((affiliation, i) =>
      author.departments && author.departments[i] !== ''
        ? uniqueAffiliations.indexOf(
            `${author.departments[i]}, ${affiliation}`,
          ) + 1
        : uniqueAffiliations.indexOf(affiliation) + 1,
    ),
    // id: author.id || uniqueId('author-'),
    hasEqualContribution: author.equalContribution || false,
    isCorresponding: author.correspondingAuthor || false,
    name: `${author.firstName} ${author.lastName}`,
  }))

  const contributionData = authors.map((author, index) => ({
    index: index + 1,
    value: `${author.firstName} ${author.lastName}: ${creditString(
      author.credit,
    )}.`,
  }))

  const correspondingAuthorData = authors
    .filter(author => author.correspondingAuthor)
    .map(a => ({
      email: a.email,
      firstName: a.firstName,
      // id: a.id || uniqueId('corresponding-author-'),
      lastName: a.lastName,
    }))

  const hasEqualContributionAuthor = authors.some(a => a.equalContribution)

  return {
    affiliationData,
    authorData,
    contributionData,
    correspondingAuthorData,
    hasEqualContributionAuthor,
  }
}

/*
  Incoming:
    {
      someKey: {
        name: 'val1'
      }
      someOtherKey: 'val2'
      someArrayKey: [
        {
          name: 'val3'
        }
        {
          name: 'val4'
        }
      ]
    }

  Outgoing:
    [
      {
        displayValue: 'val1',
        label: 'Some Key'
      }
      {
        displayValue: 'val2',
        label: 'Some Other Key'
      }
      {
        displayValue: ['val3', 'val4'],
        label: 'Some Array Key'
       }
    ]
*/
const transformGeneExpressionData = (
  geneExpression,
  previousGeneExpression,
) => {
  if (!geneExpression) return []

  const { detectionMethod } = geneExpression
  // const previousDetectionMethod = get(previousGeneExpression, 'detectionMethod')

  const correlations = detectionMethodCorrelations[detectionMethod]
  // const previousCorrelations =
  //   detectionMethodCorrelations[previousDetectionMethod]

  if (isUndefined(correlations)) return []

  const makeDisplayValue = value =>
    value && (value.name || (isUndefined(value.name) && value) || '-')

  const extracted = correlations.map((item, i) => {
    const key = correlations[i]
    const label = unCamelCase(key)

    const value = geneExpression[key]
    const previousValue = get(previousGeneExpression, key)

    const displayValue = Array.isArray(value)
      ? value.map(
          (v, pos) =>
            `${makeDisplayValue(v)}${pos === v.length - 1 ? ' ,' : ''}`,
        )
      : makeDisplayValue(value)

    const previousDisplayValue = Array.isArray(previousValue)
      ? previousValue.map(
          (v, pos) =>
            `${makeDisplayValue(v)}${pos === v.length - 1 ? ' ,' : ''}`,
        )
      : makeDisplayValue(previousValue)

    return { displayValue, previousDisplayValue, label }
  })

  return extracted
}

/*
  Incoming:
    {
      certainly: [
        {
          certainly: {
            name: ...,
          }
          during: {
            name: ...
          }
          subcellularLocalization: {
            name: ...
          }
        }
        {
          certainly: {
            name: ...,
          }
          during: {
            name: ...
          }
          subcellularLocalization: {
            name: ...
          }
        }
      ]
    }
    {
      proabably: [
        {
          proabably: {
            name: ...,
          }
          during: {
            name: ...
          }
          subcellularLocalization: {
            name: ...
          }
        }
      ]
    }
    {
      possibly: [
        {
          possibly: {
            name: ...,
          }
          during: {
            name: ...
          }
          subcellularLocalization: {
            name: ...
          }
        }
      ]
    }
    {
      not: [
        {
          not: {
            name: ...,
          }
          during: {
            name: ...
          }
          subcellularLocalization: {
            name: ...
          }
        }
      ]
    }

  Outgoing: [
    {
      value: [
        ["certainly", ...]
        ["during", ...]
        ["subcellularLocalization", ...]
      ]
    }
    {
      value: [
        ["certainly", ...]
        ["during", ...]
        ["subcellularLocalization", ...]
      ]
    }
    {
      value: [
        ["probably", ...]
        ["during", ...]
        ["subcellularLocalization", ...]
      ]
    }
    {
      value: [
        ["possibly", ...]
        ["during", ...]
        ["subcellularLocalization", ...]
      ]
    }
    {
      value: [
        ["not", ...]
        ["during", ...]
        ["subcellularLocalization", ...]
      ]
    }
  ]
*/
const transformObserveExpressionData = data => {
  if (!data) return null

  const rows = []

  const keysOfInterest = [
    'certainly',
    'partially',
    'possibly',
    'not',
    'during',
    'subcellularLocalization',
  ]

  forIn(data, (v, k) => {
    if (!Array.isArray(v)) return

    /* eslint-disable array-callback-return */
    v.map(item => {
      const picked = pickBy(item, (value, key) => keysOfInterest.includes(key))
      const row = keys(picked).map(key => [key, picked[key].name || '-'])
      rows.push({ value: row })
    })
  })

  return rows
}

/*
  Reverts camelCase, Source: https://stackoverflow.com/a/4149393

  Incoming: 'helloWorld'
  Outgoing: 'Hello World'
*/
const unCamelCase = string =>
  string &&
  string.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())

export {
  transformAuthorData,
  transformGeneExpressionData,
  transformObserveExpressionData,
  unCamelCase,
}
