import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import * as yup from 'yup'
import { useHistory } from 'react-router-dom'

import {
  Accordion,
  Button,
  Chat,
  Checkbox,
  DiscreetButton,
  Form,
  Radio,
  Status,
  TextEditor,
} from '../common'
import { stripHTML } from '../_helpers'

const Wrapper = styled.div``

const ChatWrapper = styled.div`
  /* align-items: flex-end; */
  display: flex;
  justify-content: flex-end;
`

const Editor = styled(TextEditor)`
  width: 100%;
`

const StyledRadio = styled(Radio)`
  width: 100%;
`

const validations = yup.object().shape({
  content: yup
    .string()
    .test('content-not-empty', 'Cannot leave review empty', val => {
      if (!val) return false
      return stripHTML(val).length > 0
    }),
  recommendation: yup.string().required('You need to make a recommendation'),
  openAcknowledgement: yup.boolean(),
  askedToSeeRevision: yup.boolean(),
  confidentialComments: yup.string(),
})

/* eslint-disable react/prop-types */
class AutoSave extends React.Component {
  save = debounce(() => {
    const { onSave, values } = this.props
    Promise.resolve(onSave(values))
  }, 500)

  /* eslint-disable-next-line camelcase */
  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const { values } = this.props

    if (!isEqual(nextProps.values, values)) {
      this.save()
    }
  }

  render() {
    return null
  }
}
/* eslint-enable react/prop-types */

const ReviewerPanel = props => {
  const {
    canStillReview,
    dbReferenceId,
    decision,
    doi,
    linkerLink,
    makeCuratedOptions,
    makeOptions,
    onClickChat,
    openAcknowledgementLabel,
    review,
    reviewTextAreaLabel,
    reviewTextAreaPlaceholder,
    reviewerMessage,
    save,
    showConfidentialCommentsEditor,
    showCuratedCheckbox,
    showLinkingButton,
    showBar,
    showRequestToSeeRevision,
    showReviewerGuidelines,
    submitCuration,
    submit,
    submitted,
    theme,
    getSavedChat,
    chatMessages,
    saveChat,
    sendChatMessage,
  } = props

  const decisionExists = !!decision
  const { curated } = review

  if (!canStillReview && !submitted && !showCuratedCheckbox)
    return (
      <Wrapper>
        <Status status="error">
          You did not submit a review for this version
        </Status>
      </Wrapper>
    )

  const recommendation =
    get(review, 'recommendation') === 'revise'
      ? get(review, 'reviseQualifier')
      : get(review, 'recommendation')

  const initialValues = {
    content: get(review, 'content') || '',
    confidentialComments: get(review, 'confidentialComments') || '',
    recommendation: recommendation || '',
    openAcknowledgement: get(review, 'openAcknowledgement') || false,
    curated,
  }

  const handleSubmit = values => {
    if (!submitted) return submit(values)

    return submitCuration(values.curated)
  }

  const handleSubmitCuration = values => submitCuration(values.curated)

  const curatedOptions = makeCuratedOptions && makeCuratedOptions(theme)

  if (showRequestToSeeRevision) {
    initialValues.askedToSeeRevision =
      get(review, 'askedToSeeRevision') || false
  }

  const radioOptions = makeOptions(theme)

  const navigate = useHistory()

  return (
    <Wrapper>
      <ChatWrapper>
        {showReviewerGuidelines && (
          <DiscreetButton onClick={showReviewerGuidelines}>
            reviewer guidelines
          </DiscreetButton>
        )}
        {showBar && (
          <DiscreetButton onClick={showBar}>
            Bar of a microPublication
          </DiscreetButton>
        )}
        {showLinkingButton && (
          <DiscreetButton onClick={() => navigate.push(linkerLink)}>
            Linker
          </DiscreetButton>
        )}
        <DiscreetButton onClick={onClickChat}>
          chat with the editors
        </DiscreetButton>
      </ChatWrapper>

      {submitted && (
        <div>
          <Status status="primary">Submitted</Status>
        </div>
      )}

      {showCuratedCheckbox && (
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmitCuration}
          validationSchema={yup.object().shape({ curated: yup.string() })}
        >
          {formProps => {
            const { setFieldValue, touched, values } = formProps

            return (
              <>
                <StyledRadio
                  label="Curation status"
                  name="curated"
                  options={curatedOptions}
                  readOnly={curated}
                  setFieldValue={setFieldValue}
                  theme={theme}
                  touched={touched}
                  value={get(values, 'curated')}
                />

                {!curated && (
                  <Button primary type="submit">
                    Set Curated
                  </Button>
                )}
                {curated && (
                  <Button onClick={() => submitCuration(null)} primary>
                    Reset
                  </Button>
                )}
              </>
            )
          }}
        </Form>
      )}

      {dbReferenceId && (
        <div>
          <Status>
            db reference id: <Status status="primary">{dbReferenceId}</Status>
          </Status>
        </div>
      )}

      {doi && (
        <div>
          <Status>
            doi: <Status status="primary">{doi}</Status>
          </Status>
        </div>
      )}

      {submitted && decisionExists && (
        <Status>
          editor decision: <Status status={decision}>{decision}</Status>
        </Status>
      )}
      <Accordion label="Chat with the Editors">
        <Chat
          getSavedChat={getSavedChat}
          messages={chatMessages}
          saveChat={saveChat}
          sendMessage={sendChatMessage}
        />
      </Accordion>

      {reviewerMessage && (
        <Status>
          message from editor: <TextEditor readOnly value={reviewerMessage} />
        </Status>
      )}

      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validations}
      >
        {formProps => {
          const {
            errors,
            handleChange,
            setFieldTouched,
            setFieldValue,
            touched,
            values,
          } = formProps

          const setRecommendation = (_, value) => {
            if (!['reject', 'accept'].includes(value)) {
              setFieldValue('reviseQualifier', value)
              setFieldValue('recommendation', 'revise')
            } else {
              setFieldValue('reviseQualifier', '')
              setFieldValue('recommendation', value)
            }
          }

          return (
            <>
              <StyledRadio
                error={errors.recommendation}
                label="Recommendation to the Editors"
                name="recommendation"
                options={radioOptions}
                readOnly={submitted}
                required
                setFieldValue={setRecommendation}
                theme={theme}
                touched={touched}
                value={get(values, 'reviseQualifier') || values.recommendation}
              />

              <Editor
                bold
                error={errors.content}
                italic
                key={submitted}
                label={reviewTextAreaLabel}
                name="content"
                placeholder={reviewTextAreaPlaceholder}
                readOnly={submitted}
                required
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                subscript
                superscript
                touched={touched}
                value={values.content}
              />

              {showConfidentialCommentsEditor && (
                <Editor
                  bold
                  error={errors.confidentialComments}
                  italic
                  key={submitted}
                  label="Confidential comments to the editor"
                  name="confidentialComments"
                  placeholder="Comments could include concerns about the data quality, issues related to plagiarism, inappropriate figure manipulation, etc. These comments will not be shown to the author(s)."
                  readOnly={submitted}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  subscript
                  superscript
                  touched={touched}
                  value={values.confidentialComments}
                />
              )}

              <Checkbox
                checkBoxText={openAcknowledgementLabel}
                checked={values.openAcknowledgement}
                name="openAcknowledgement"
                onChange={handleChange}
                readOnly={submitted}
                setFieldTouched={setFieldTouched}
                touched={touched}
              />

              {showRequestToSeeRevision && (
                <Checkbox
                  checkBoxText="I would like to see the revised article"
                  checked={values.askedToSeeRevision}
                  name="askedToSeeRevision"
                  onChange={handleChange}
                  readOnly={submitted}
                  setFieldTouched={setFieldTouched}
                  touched={touched}
                />
              )}

              {!submitted && (
                <Button primary type="submit">
                  Submit
                </Button>
              )}

              {submitted && !curated && showCuratedCheckbox && (
                <Button primary type="submit">
                  Set Curated
                </Button>
              )}

              <AutoSave onSave={save} values={values} />
            </>
          )
        }}
      </Form>
    </Wrapper>
  )
}

const validDecisions = ['accept', 'reject', 'revise']

ReviewerPanel.propTypes = {
  canStillReview: PropTypes.bool.isRequired,
  dbReferenceId: PropTypes.string,
  decision: PropTypes.oneOf(validDecisions),
  linkerLink: PropTypes.string,
  doi: PropTypes.string,
  onClickChat: PropTypes.func.isRequired,
  openAcknowledgementLabel: PropTypes.string,
  makeCuratedOptions: PropTypes.func,
  makeOptions: PropTypes.func,
  review: PropTypes.shape({
    content: PropTypes.string,
    recommendation: PropTypes.oneOf(validDecisions),
    reviseQualifier: PropTypes.string,
    openAcknowledgement: PropTypes.bool,
    askedToSeeRevision: PropTypes.bool,
    curated: PropTypes.string,
  }),
  reviewTextAreaLabel: PropTypes.string,
  reviewTextAreaPlaceholder: PropTypes.string,
  save: PropTypes.func,
  showConfidentialCommentsEditor: PropTypes.bool,
  showCuratedCheckbox: PropTypes.bool,
  showLinkingButton: PropTypes.bool,
  showRequestToSeeRevision: PropTypes.bool,
  showReviewerGuidelines: PropTypes.func,
  showBar: PropTypes.func,
  submitCuration: PropTypes.func,
  submit: PropTypes.func,
  submitted: PropTypes.bool,
  reviewerMessage: PropTypes.string,
  getSavedChat: PropTypes.func,
  chatMessages: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }),
  ),
  saveChat: PropTypes.func,
  sendChatMessage: PropTypes.func,
}

ReviewerPanel.defaultProps = {
  dbReferenceId: null,
  decision: null,
  doi: null,
  linkerLink: null,
  openAcknowledgementLabel:
    'I would like to be openly acknowledged as the reviewer',
  makeCuratedOptions: null,
  makeOptions: null,
  review: null,
  reviewTextAreaLabel:
    'In the space below please explain acceptance, rejection, or detail the modifications that should be made. If modifications are requested, enumerate each point the author(s) should address.',
  reviewTextAreaPlaceholder: 'Write your review',
  save: null,
  showConfidentialCommentsEditor: true,
  showCuratedCheckbox: false,
  showLinkingButton: false,
  showRequestToSeeRevision: true,
  showReviewerGuidelines: null,
  showBar: null,
  submitCuration: null,
  submit: null,
  submitted: false,
  reviewerMessage: null,
  getSavedChat: null,
  chatMessages: [],
  saveChat: null,
  sendChatMessage: null,
}

export default withTheme(ReviewerPanel)
