/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'

import { Status } from '../common'

const StyledStatus = styled(Status)`
  margin-left: 5px;
`

const MetadataLabels = props => {
  const { metadata, status = 'primary' } = props

  if (!metadata) return null

  return (
    <span>
      {metadata.map(item => (
        <StyledStatus key={item} reverseColors status={status}>
          {item}
        </StyledStatus>
      ))}
    </span>
  )
}

export default MetadataLabels
