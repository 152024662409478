import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as yup from 'yup'

import { grid, th } from '../_helpers'
import { Button, Form, Select, TextField } from '../common'
import ValueList from './ValueList'

const Wrapper = styled.div``

const DoiWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const MetadataValues = styled(ValueList)`
  margin-bottom: ${grid(2)};
`

const StyledSelect = styled(Select)`
  margin-bottom: ${grid(2)};
`

const Label = styled.label`
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  padding: 10px 10px 0 0;
`

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledField = styled(TextField)`
  margin-right: calc(${th('gridUnit')} * 3);
  width: calc(${th('gridUnit')} * 35);
`

const validations = yup.object().shape({
  citations: yup.array().of(yup.string()),
  dataRelease: yup.string(),
  dbReferenceId: yup.string(),
  doi: yup.string(),
  categories: yup.array().of(yup.string()),
  pmcId: yup.string(),
  pmId: yup.string(),
  proteopedia: yup.string(),
  reviewPanel: yup.string(),
  speciesOptions: yup.array().of(yup.string()),
  submissionTypes: yup.array().of(yup.string()),
  topics: yup.array().of(yup.string()),
})

const MetadataSection = props => {
  const {
    categories,
    categoryOptions,
    citations,
    createDoi,
    dataRelease,
    dbReferenceId,
    doi,
    groups,
    groupOptions,
    pmcId,
    pmId,
    proteopedia,
    reviewPanel,
    species,
    speciesOptions,
    submissionTypes,
    submissionTypeOptions,
    topics,
    updateMetadata,
    specialtyOptions,
  } = props

  const [showForm, setShowForm] = useState(false)
  const [fetchDoi, setFetchDoi] = useState(false)

  /**
   * Either value list
   */
  const data = [
    { label: 'Species', status: 'primary', value: species },
    {
      label: 'Categories',
      status: 'primary',
      value: categories,
    },
    {
      label: 'Submission Types',
      status: 'primary',
      value: submissionTypes,
    },
    {
      label: 'Topics',
      status: 'primary',
      value: topics,
    },
    {
      label: 'Groups',
      status: 'primary',
      value: groups,
    },
    {
      label: 'DOI',
      status: 'primary',
      value: doi,
    },
    { label: 'PMC ID', status: 'primary', value: pmcId },
    { label: 'PubMed ID', status: 'primary', value: pmId },
    {
      label: 'DB Reference ID',
      status: 'primary',
      value: dbReferenceId,
    },
    {
      label: 'Data Release',
      status: 'primary',
      value: dataRelease,
    },
    {
      label: 'Proteopedia Source',
      status: 'primary',
      value: proteopedia,
    },
    {
      label: 'Review Panel Name',
      status: 'primary',
      value: reviewPanel,
    },
    {
      label: 'Cited By',
      status: 'primary',
      value: citations,
    },
  ]

  if (!showForm)
    return (
      <Wrapper>
        <MetadataValues data={data} />

        <div>
          <Button onClick={() => setShowForm(true)} primary>
            Edit
          </Button>
        </div>
      </Wrapper>
    )

  /**
   * Or form
   */

  const handleSubmit = (values, formikBag) => {
    updateMetadata(values).then(() => setShowForm(false))
  }

  const initialValues = {
    citations: citations || [],
    dbReferenceId: dbReferenceId || '',
    dataRelease: dataRelease || '',
    doi: doi || '',
    categories: categories || [],
    pmId: pmId || '',
    pmcId: pmcId || '',
    proteopedia: proteopedia || '',
    reviewPanel: reviewPanel || '',
    species: species || [],
    submissionTypes: submissionTypes || [],
    topics: topics || [],
    groups: groups || [],
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validations}
    >
      {formProps => {
        const {
          errors,
          handleBlur,
          handleChange,
          setFieldError,
          setFieldValue,
          setFieldTouched,
          touched,
          values,
        } = formProps

        const currentValues = (items, options) =>
          items
            ? items.map(value =>
                options.find(
                  option => option.value.toLowerCase() === value.toLowerCase(),
                ),
              )
            : []

        const currentCategoriesValues = currentValues(
          values.categories,
          categoryOptions,
        )

        const currentSpeciesValues = currentValues(
          values.species,
          speciesOptions,
        )

        const currentSubmissionTypesValues = currentValues(
          values.submissionTypes,
          submissionTypeOptions,
        )

        const currentTopicsValues = currentValues(
          values.topics,
          specialtyOptions,
        )

        const currentGroupsValues = currentValues(values.groups, groupOptions)

        const handleSelect = (type, newValues) => {
          const selectValues = newValues.map(item => item.value)
          setFieldValue(type, selectValues)
        }

        const handleCategories = newValues =>
          handleSelect('categories', newValues)

        const handleSpecies = newValues => handleSelect('species', newValues)

        const handleSubmissionTypes = newValues =>
          handleSelect('submissionTypes', newValues)

        const handleTopics = newValues => handleSelect('topics', newValues)
        const handleGroups = newValues => handleSelect('groups', newValues)

        const generateDoi = async () => {
          setFetchDoi(true)
          await createDoi()
            .then(newDoi => {
              setFieldValue('doi', newDoi)
              values.doi = newDoi
              updateMetadata(values)
            })
            .catch(error => {
              setFieldError('doi', `Generate DOI Failed: ${error}`)
              setFieldTouched('doi', true, false)
            })
            .finally(() => {
              setFetchDoi(false)
            })
        }

        return (
          <Wrapper>
            <Label>Species</Label>
            <StyledSelect
              closeMenuOnSelect={false}
              isMulti
              name="species"
              onBlur={handleBlur}
              onChange={handleSpecies}
              options={speciesOptions}
              placeholder="Select Species"
              value={currentSpeciesValues}
            />

            <Label>Categories</Label>
            <StyledSelect
              closeMenuOnSelect={false}
              isMulti
              name="categories"
              onBlur={handleBlur}
              onChange={handleCategories}
              options={categoryOptions}
              placeholder="Select Categories"
              value={currentCategoriesValues}
            />

            <Label>Submission Types</Label>
            <StyledSelect
              closeMenuOnSelect={false}
              isMulti
              name="submissionTypes"
              onBlur={handleBlur}
              onChange={handleSubmissionTypes}
              options={submissionTypeOptions}
              placeholder="Select Submission Types"
              value={currentSubmissionTypesValues}
            />

            <Label>Topics</Label>
            <StyledSelect
              closeMenuOnSelect={false}
              isMulti
              name="topics"
              onBlur={handleBlur}
              onChange={handleTopics}
              options={specialtyOptions}
              placeholder="Select Topics"
              value={currentTopicsValues}
            />

            <Label>Groups</Label>
            <StyledSelect
              closeMenuOnSelect={false}
              isMulti
              name="groups"
              onBlur={handleBlur}
              onChange={handleGroups}
              options={groupOptions}
              placeholder="Select Groups"
              value={currentGroupsValues}
            />

            <DoiWrapper>
              <TextField
                error={errors.doi}
                handleBlur={handleBlur}
                handleChange={handleChange}
                label="DOI"
                name="doi"
                touched={touched}
                value={values.doi}
              />
              <Button loading={fetchDoi} onClick={() => generateDoi()}>
                Generate DOI
              </Button>
            </DoiWrapper>

            <FieldWrapper>
              <StyledField
                error={errors.pmcId}
                handleBlur={handleBlur}
                handleChange={handleChange}
                label="PMC ID"
                name="pmcId"
                touched={touched}
                value={values.pmcId}
              />
              <StyledField
                error={errors.pmId}
                handleBlur={handleBlur}
                handleChange={handleChange}
                label="PubMed ID"
                name="pmId"
                touched={touched}
                value={values.pmId}
              />
            </FieldWrapper>

            <TextField
              error={errors.dbReferenceId}
              handleBlur={handleBlur}
              handleChange={handleChange}
              label="DB Reference ID"
              name="dbReferenceId"
              touched={touched}
              value={values.dbReferenceId}
            />

            <TextField
              error={errors.dataRelease}
              handleBlur={handleBlur}
              handleChange={handleChange}
              label="Data Release"
              name="dataRelease"
              touched={touched}
              value={values.dataRelease}
            />

            <TextField
              error={errors.proteopedia}
              handleBlur={handleBlur}
              handleChange={handleChange}
              label="Proteopedia iFrame Source"
              name="proteopedia"
              touched={touched}
              value={values.proteopedia}
            />

            <TextField
              error={errors.reviewPanel}
              handleBlur={handleBlur}
              handleChange={handleChange}
              label="Review Panel Name"
              name="reviewPanel"
              touched={touched}
              value={values.reviewPanel}
            />

            <Button onClick={() => setShowForm(false)}>Cancel</Button>

            <Button primary type="submit">
              Save
            </Button>
          </Wrapper>
        )
      }}
    </Form>
  )
}

MetadataSection.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  categoryOptions: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  ),
  createDoi: PropTypes.func.isRequired,
  citations: PropTypes.arrayOf(PropTypes.string),
  dataRelease: PropTypes.string,
  dbReferenceId: PropTypes.string,
  doi: PropTypes.string,
  pmcId: PropTypes.string,
  pmId: PropTypes.string,
  proteopedia: PropTypes.string,
  reviewPanel: PropTypes.string,
  species: PropTypes.arrayOf(PropTypes.string),
  speciesOptions: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  ),
  submissionTypes: PropTypes.arrayOf(PropTypes.string),
  submissionTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  ),
  topics: PropTypes.arrayOf(PropTypes.string),
  updateMetadata: PropTypes.func.isRequired,
  specialtyOptions: PropTypes.arrayOf(PropTypes.object.isRequired),
  groups: PropTypes.arrayOf(PropTypes.string),
  groupOptions: PropTypes.arrayOf(PropTypes.object.isRequired),
}

MetadataSection.defaultProps = {
  categories: null,
  categoryOptions: [],
  citations: [],
  dataRelease: null,
  dbReferenceId: null,
  doi: null,
  pmcId: null,
  pmId: null,
  proteopedia: null,
  reviewPanel: null,
  species: null,
  speciesOptions: [],
  submissionTypes: null,
  topics: [],
  submissionTypeOptions: [],
  specialtyOptions: [],
  groups: null,
  groupOptions: [],
}

export default MetadataSection
