/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import last from 'lodash/last'
import uniq from 'lodash/uniq'
import flatten from 'lodash/flatten'

import { Action, List, TextEditor } from '../common'
import { th } from '../_helpers'

const StyledTextEditor = styled(TextEditor)`
  background: white;
  border: 0;
  margin: 0;
  padding: 0;

  del {
    color: ${th('colorError')};
    text-decoration: none;
  }
`

const StyledListItem = styled.div`
  border-bottom: 2px solid;
`

const ListItem = item => {
  const { id, name } = item
  return (
    <StyledListItem>
      <StyledTextEditor italic readOnly value={name} />
      <Action to={`/article/${id}`}>Go to Article</Action>
    </StyledListItem>
  )
}

const PreviousSubmissions = props => {
  const { authorArticles } = props

  if (!authorArticles || authorArticles.otherSubmissions.length === 0)
    return <>This author has not previously submitted any articles</>

  const items = authorArticles.otherSubmissions.map(a => {
    const invitedReviewers = []

    const reviewers = a.versions.map(v => {
      v.teams.forEach(t => {
        if (t.role === 'reviewer') {
          t.members.forEach(m => {
            if (
              [
                'invited',
                'rejectedInvitation',
                'invitationRevoked',
                'declinedInvitation',
                'acceptedInvitation',
              ].includes(m.status)
            ) {
              invitedReviewers.push(m.user.displayName)
            }
          })
        }
      })
      return v.reviews.map(r =>
        r.status.submitted ? r.reviewer.displayName : null,
      )
    })

    const version = last(a.versions)
    let name = version.title
    if (version.decision === 'reject') name = `<del>REJECTED:</del> ${name}`
    else if (version.decision === 'decline')
      name = `<del>DECLINED:</del> ${name}`

    return {
      id: a.id,
      name: `${name} Reviewed by: ${uniq(flatten(reviewers)).join(
        ', ',
      )}. Number of reviewers invited: ${uniq(invitedReviewers).length} (${uniq(
        invitedReviewers,
      ).join(', ')}).`,
    }
  })

  return <List component={ListItem} items={items} />
}

export default PreviousSubmissions
