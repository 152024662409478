/* eslint-disable react/prop-types */

import React, { useState } from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import { override, th } from '@pubsweet/ui-toolkit'
import { IconButton, Steps } from '@pubsweet/ui'

import { getWBLaboratory } from '../../fetch/WBApi'

import {
  onAutocompleteChange,
  onSuggestionSelected,
  countWords,
} from './helpers'

import AutoComplete from './AutoComplete'
import Checkbox from './Checkbox'
import Image from '../../../ui/src/submissionForm/Image'
import TextEditor from './TextEditor'
import ExtendedData from '../../../ui/src/submissionForm/ExtendedData'
import AuthorSection from '../../../ui/src/submissionForm/AuthorSection'
import { Button, TextEditor as TitleEditor } from '../../../ui/src/common'
import ReferenceSection from '../../../ui/src/submissionForm/ReferenceSection'
import DndList from '../../../ui/src/common/DndList'
import Metadata from '../../../ui/src/submissionForm/Metadata'

const Error = styled.span`
  color: ${th('colorError')};
  padding-left: ${th('gridUnit')};
`

const Info = styled.div`
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  margin-bottom: ${th('gridUnit')};
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
`

const TabSection = styled.div`
  margin-top: calc(${th('gridUnit')} * 8);
  width: 100%;
`

const StyledSteps = styled(Steps)`
  min-width: 600px;
`

const StyledTitle = styled(TitleEditor)`
  background-color: white;
  border: 0;
  cursor: default;
`

const disclaimerDescription = (
  <>
    <p>
      I/we declare to the best of my/our knowledge that the experiment is
      reproducible; that the submission has been approved by all authors; that
      the submission has been approved by the laboratory&#39;s Principal
      Investigator, and that the results have not been published elsewhere. I/we
      understand that if untruthful information is provided, this action can
      lead to an editorial rejection. The author(s) declare no conflict of
      interest. I/we have disclosed the use of Artificial Intelligence tools in
      the Methods section.
    </p>
  </>
)

const apcDisclaimerDescription = (
  <>
    <p>
      I/we understand that for articles submitted starting April 2nd, 2022,
      microPublication Biology will charge $250 per article upon acceptance for
      publication. Article Processing Charges for articles received on or after
      January 1st, 2025 will be charged $350. No article will be refused because
      of an author&#39;s inability to pay page charges. For more information on
      waivers, please see the{' '}
      <a
        href="https://www.micropublication.org/about/for-authors"
        rel="noreferrer"
        target="_blank"
      >
        Instructions for Authors
      </a>
      .
    </p>
  </>
)

const proteopediaOptIn = (
  <>
    <p>
      You have indicated that your article contains Structural Biology Data.
      microPublication Biology is now partnering with{' '}
      <a href="https://proteopedia.org/" rel="noreferrer" target="_blank">
        Proteopedia
      </a>
      , a 3D web resource with pages that describe protein and nucleic acid
      structures and their structure/function relationships through descriptive
      text linked to rotatable, zoomable 3D structures. You can access an
      article with the embedded Proteopedia Molecular Tour{' '}
      <a
        href="https://www.micropublication.org/journals/biology/micropub-biology-000570"
        rel="noreferrer"
        target="_blank"
      >
        here
      </a>
      . You have the opportunity to opt in and create 3D structures with models
      built from PDB data. This page is linked from the published article and
      accessible both through the journal and through the Proteopedia website.
      To opt in please check the box below. If you opt in, you will be contacted
      by Proteopedia personnel and will be asked to provide additional data
      files to build the Interactive 3D Complement (I3DC). More information{' '}
      <a href="https://proteopedia.org/jrnld/" rel="noreferrer" target="_blank">
        here
      </a>
      . Author participation to the initiative is optional and will not affect
      any editorial decision.
    </p>
  </>
)

const InitialSubmission = props => {
  const {
    categoryOptions,
    coAuthors,
    errors,
    fileProgress,
    handleBlur,
    handleChange,
    readOnly,
    isInitialSubmission,
    onClickAddAward,
    setCurrentAward,
    setFieldTouched,
    setFieldValue,
    setErrors,
    showCategoryDescriptions,
    showSubmissionTypeDescriptions,
    setShowFundingModal,
    specialtyOptions,
    speciesOptions,
    submissionTypeOptions,
    touched,
    values,
    upload,
  } = props

  const [activeStep, setActiveStep] = useState(0)
  const [showOptIn, setShowOptIn] = useState(false)

  const [abstractCounter, setAbstractCounter] = useState(
    countWords(get(values, 'abstract')),
  )

  const hasError = section => !!get(errors, section)

  const tabKeys = [
    'metadata',
    'authors',
    'figure',
    'article',
    'references',
    'funding',
    'disclaimer',
  ]

  const updateWordCounter = event => {
    setAbstractCounter(countWords(event))
  }

  const nextStep = () => {
    if (activeStep === 6) {
      setActiveStep(0)
    } else {
      setActiveStep(activeStep + 1)
    }
  }

  const previousStep = () => {
    if (activeStep === 0) {
      setActiveStep(6)
    } else {
      setActiveStep(activeStep - 1)
    }
  }

  const StyledStep = styled.div`
    align-items: center;
    border: 2px solid #000;
    border-radius: 50%;
    display: flex;
    height: 16px;
    justify-content: center;

    ${override('ui.Steps.Step')};

    position: relative;
    width: 16px;
  `

  const Bullet = styled.div`
    background-color: #000;
    border-radius: 50%;
    height: 10px;

    ${override('ui.Steps.Bullet')};

    width: 10px;
  `

  const Success = styled(Bullet)`
    align-items: center;
    color: #fff;
    display: flex;
    font-size: 12px;
    height: 18px;
    justify-content: center;

    ${override('ui.Steps.Success')};

    width: 18px;
  `

  const StepTitle = styled.span`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 0.9em;
    justify-content: center;

    ${override('ui.Steps.StepTitle')};

    position: absolute;
    text-align: center;
    top: 25px;
    white-space: normal;
    width: 120px;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      left: -50px;
    }
  `

  const handleAwardReorder = newList => {
    setFieldValue('awards', newList)
    handleChange('awards')
  }

  const handleRemoveAward = id => {
    const data = get(values, 'awards')
    const changed = data.filter(val => val.id !== id)

    setFieldValue('awards', changed)
    handleChange('awards')
  }

  const handleEditAward = id => {
    setCurrentAward(values.awards.findIndex(award => award.id === id))
    setShowFundingModal(true)
  }

  const Step = ({ title, index, currentStep, showError }) => (
    <StyledStep isCurrent={index === currentStep} isPast={index < currentStep}>
      {index === currentStep && <Bullet />}
      {index < currentStep && <Success />}
      {title && (
        <StepTitle
          isCurrent={index === currentStep}
          isPast={index < currentStep}
          onClick={() => setActiveStep(index)}
        >
          {!showError && title}
          {showError && <Error>{title}</Error>}
        </StepTitle>
      )}
    </StyledStep>
  )

  const Nav = ({ prevLabel, nextLabel }) => (
    <RowWrapper>
      <IconButton
        icon="chevrons-left"
        onClick={() => previousStep()}
        onMouseOver={e => e.target.focus()}
        primary
      >
        {prevLabel}
      </IconButton>
      <IconButton
        icon="chevrons-right"
        iconPosition="end"
        onClick={() => nextStep()}
        onMouseOver={e => e.target.focus()}
        primary
      >
        {nextLabel}
      </IconButton>
    </RowWrapper>
  )

  return (
    <Wrapper>
      <StyledTitle
        name="articleTitle"
        readOnly
        setFieldValue={setFieldValue}
        value={values.title}
      />
      <StyledSteps currentStep={activeStep}>
        <Step
          showError={
            hasError('abstract') ||
            hasError('title') ||
            hasError('species') ||
            hasError('categories') ||
            hasError('submissionTypes')
          }
          title="Title and Abstract"
        />
        <Step showError={hasError('authors')} title="Authors" />
        <Step
          showError={
            hasError('image') ||
            hasError('imageCaption') ||
            hasError('imageTitle')
          }
          title="Figure"
        />
        <Step
          showError={
            hasError('patternDescription') ||
            hasError('methods') ||
            hasError('reagents')
          }
          title="Article Text and Data"
        />
        <Step showError={hasError('references')} title="References" />
        <Step
          showError={hasError('funding') || hasError('suggestedReviewer')}
          title="Funding and Acknowledgements"
        />
        <Step
          showError={hasError('disclaimer') || hasError('apcDisclaimer')}
          title="Comments to the Editor and Disclaimer"
        />
      </StyledSteps>

      {tabKeys[activeStep] === 'metadata' && (
        <TabSection>
          <Metadata
            categoryOptions={categoryOptions}
            errors={errors}
            isInitialSubmission={isInitialSubmission}
            setFieldValue={setFieldValue}
            setShowOptIn={setShowOptIn}
            showCategoryDescriptions={showCategoryDescriptions}
            showSubmissionTypeDescriptions={showSubmissionTypeDescriptions}
            speciesOptions={speciesOptions}
            submissionTypeOptions={submissionTypeOptions}
            topicOptions={specialtyOptions}
            values={values}
          />

          {showOptIn && (
            <Checkbox
              checkBoxText="Yes, I want to have an Interactive 3D Complement associated with my paper"
              checked={get(values, 'proteopediaOptIn')}
              data-test-id="proteopediaOptIn"
              description={proteopediaOptIn}
              errors={errors}
              label="Structural Biology Data"
              name="proteopediaOptIn"
              // onBlur
              onChange={handleChange}
              setFieldTouched={setFieldTouched}
              touched={touched}
              value={get(values, 'proteopediaOptIn')}
            />
          )}

          <TextEditor
            data-test-id="title"
            error={get(errors, 'title')}
            italic
            key={`title-${readOnly}`}
            label="Title"
            name="title"
            placeholder="Please enter the title of your manuscript"
            required
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            subscript
            superscript
            touched={touched}
            value={get(values, 'title')}
          />

          <TextEditor
            data-test-id="abstract"
            error={get(errors, 'abstract')}
            handleChange={updateWordCounter}
            italic
            key={`abstract-${readOnly}`}
            label={`Abstract (${abstractCounter} words)`}
            name="abstract"
            placeholder="Please enter an abstract with a suggested length of 100 words"
            required
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            subscript
            superscript
            touched={touched}
            underline
            value={get(values, 'abstract')}
          />

          <Nav nextLabel="Authors" prevLabel="Disclaimer and Comments" />
        </TabSection>
      )}

      {tabKeys[activeStep] === 'authors' && (
        <TabSection>
          <AuthorSection
            coAuthors={coAuthors}
            errors={errors}
            handleChange={handleChange}
            label="Authors"
            name="authors"
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            touched={touched}
            values={values}
          />
          <Nav nextLabel="Figure" prevLabel="Title and Abstract" />
        </TabSection>
      )}

      {tabKeys[activeStep] === 'figure' && (
        <TabSection>
          <Image
            data-test-id="image"
            errors={errors}
            fileProgress={fileProgress}
            label="Figure"
            name="image"
            required
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            touched={touched}
            upload={upload}
            values={values}
          />

          <TextEditor
            bold
            data-test-id="image-title"
            error={get(errors, 'imageTitle')}
            italic
            key={`image-title-${readOnly}`}
            label="Figure title"
            name="imageTitle"
            placeholder="The 'Figure 1' label will be added automatically"
            required
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            subscript
            superscript
            touched={touched}
            value={get(values, 'imageTitle')}
          />

          <TextEditor
            bold
            data-test-id="image-caption"
            error={get(errors, 'imageCaption')}
            italic
            key={`image-caption-${readOnly}`}
            label="Figure caption"
            name="imageCaption"
            placeholder="Figure caption"
            required
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            subscript
            superscript
            touched={touched}
            underline
            value={get(values, 'imageCaption')}
          />
          <Nav nextLabel="Article Text and Data" prevLabel="Authors" />
        </TabSection>
      )}

      {tabKeys[activeStep] === 'article' && (
        <TabSection>
          <Info>
            As part of <i>microPublication Biology&#39;s</i> commitment to
            protecting the integrity of the scholarly record, we employ
            iThenticate to detect plagiarism upon submission. Please revise your
            text to avoid plagiarized content.
          </Info>
          <TextEditor
            bold
            createtable
            data-test-id="pattern-description"
            error={get(errors, 'patternDescription')}
            italic
            key={`pattern-description-${readOnly}`}
            label="Main article text"
            link
            name="patternDescription"
            placeholder="Enter article text here"
            required
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            subscript
            superscript
            table
            touched={touched}
            underline
            value={get(values, 'patternDescription')}
          />

          <TextEditor
            bold
            createtable
            data-test-id="methods"
            error={get(errors, 'methods')}
            italic
            key={`methods-${readOnly}`}
            label="Methods"
            link
            name="methods"
            placeholder="Describe the methods (optional)"
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            subscript
            superscript
            table
            touched={touched}
            underline
            value={get(values, 'methods')}
          />

          <TextEditor
            bold
            createtable
            data-test-id="reagents"
            error={get(errors, 'reagents')}
            italic
            key={`reagents-${readOnly}`}
            label="Reagents"
            link
            name="reagents"
            placeholder="Provide the reagents used (optional)"
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            subscript
            superscript
            table
            touched={touched}
            underline
            value={get(values, 'reagents')}
          />

          <ExtendedData
            data-test-id="extendedData"
            error={get(errors, 'extendedData')}
            fileProgress={fileProgress}
            handleChange={handleChange}
            label="Extended Data"
            setFieldValue={setFieldValue}
            upload={upload}
            value={get(values, 'extendedData')}
          />
          <Nav nextLabel="References" prevLabel="Figure" />
        </TabSection>
      )}

      {tabKeys[activeStep] === 'references' && (
        <TabSection>
          <ReferenceSection
            data-test-id="references"
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            name="references"
            references={get(values, 'references')}
            setErrors={setErrors}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            touched={touched}
            values={values}
          />

          <Nav
            nextLabel="Funding and Acknowledgements"
            prevLabel="Article Text and Data"
          />
        </TabSection>
      )}

      {tabKeys[activeStep] === 'funding' && (
        <TabSection>
          <TextEditor
            bold
            data-test-id="acknowledgements"
            error={get(errors, 'acknowledgements')}
            italic
            key={`acknowledgements-${readOnly}`}
            label="Acknowledgements (optional)"
            link
            name="acknowledgements"
            placeholder="Make sure to thank your knowledgebase and/or your stock center!"
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            subscript
            superscript
            touched={touched}
            value={get(values, 'acknowledgements')}
          />

          <AutoComplete
            data-test-id="laboratory"
            error={get(errors, 'laboratory.name')}
            fetchData={getWBLaboratory}
            handleBlur={handleBlur}
            label="Laboratory (optional)"
            name="laboratory.name"
            onChange={e =>
              onAutocompleteChange(
                e,
                'laboratory.name',
                setFieldValue,
                handleChange,
              )
            }
            onSuggestionSelected={onSuggestionSelected}
            placeholder="Enter your PI's registered WormBase Laboratory"
            setFieldValue={setFieldValue}
            value={get(values, 'laboratory.name')}
          />

          <TextEditor
            bold
            data-test-id="funding"
            error={get(errors, 'funding')}
            italic
            key={`reagents-${readOnly}`}
            label="Funding Statement"
            link
            name="funding"
            placeholder="Please state the sources of your funding or click 'Add Award'"
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            subscript
            superscript
            touched={touched}
            value={get(values, 'funding')}
          />
          <Button onClick={onClickAddAward} primary>
            Add Award
          </Button>

          <DndList
            emptyMessage="No awards have been added"
            items={values.awards}
            onClickEditRow={handleEditAward}
            onClickRemoveRow={handleRemoveAward}
            onReorder={handleAwardReorder}
          />

          <Info>
            You can exclude reviewers in the &ldquo;Comments to the
            Editor&rdquo; on the next page.
          </Info>
          <TextEditor
            bold
            data-test-id="suggested-reviewer"
            error={get(errors, 'suggestedReviewer.name')}
            italic
            key={`suggestedReviewer-${readOnly}`}
            label="Suggested Reviewers"
            link
            name="suggestedReviewer.name"
            placeholder="Please suggest reviewers, including email or ORCiD if possible"
            required
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            subscript
            superscript
            touched={touched}
            value={get(values, 'suggestedReviewer.name')}
          />

          <Nav nextLabel="Disclaimer and Comments" prevLabel="References" />
        </TabSection>
      )}

      {tabKeys[activeStep] === 'disclaimer' && (
        <TabSection>
          <Info>
            If you are planning to submit similar articles within a week or if
            this microPublication is part of an integration set, please notify
            the editor with a comment in the box below. This will help us speed
            the review process.
          </Info>

          <TextEditor
            bold
            data-test-id="comments"
            error={get(errors, 'comments')}
            italic
            key={`comments-${readOnly}`}
            label="Comments to the editor"
            name="comments"
            placeholder="Enter comments (optional)"
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            subscript
            superscript
            touched={touched}
            underline
            value={get(values, 'comments')}
          />

          <Checkbox
            checkBoxText="I agree"
            checked={get(values, 'disclaimer')}
            data-test-id="disclaimer"
            description={disclaimerDescription}
            errors={errors}
            label="Disclaimer"
            name="disclaimer"
            // onBlur
            onChange={handleChange}
            required
            setFieldTouched={setFieldTouched}
            touched={touched}
            value={get(values, 'disclaimer')}
          />

          <Checkbox
            checkBoxText="I agree"
            checked={get(values, 'apcDisclaimer')}
            data-test-id="apcDisclaimer"
            description={apcDisclaimerDescription}
            errors={errors}
            label="Article Processing Charges (APC) Disclaimer"
            name="apcDisclaimer"
            // onBlur
            onChange={handleChange}
            required
            setFieldTouched={setFieldTouched}
            touched={touched}
            value={get(values, 'apcDisclaimer')}
          />

          <Nav
            nextLabel="Title and Abstract"
            prevLabel="Funding and Acknowledgements"
          />
        </TabSection>
      )}
    </Wrapper>
  )
}

export default InitialSubmission
