import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useApolloClient, useQuery, useMutation } from '@apollo/client'
import first from 'lodash/first'
import last from 'lodash/last'
import clone from 'lodash/clone'
import startCase from 'lodash/startCase'

import { serverUrl } from '@coko/client'

import {
  EDITOR_PANEL,
  FULL_PREVIEW,
  REINVITE_REVIEWER,
  SEND_CHAT,
  SET_DATA_TYPE,
  SUBMIT_DECISION,
  UPDATE_ENTITIES,
  UPDATE_MANUSCRIPT_HISTORY,
  UPDATE_MANUSCRIPT_METADATA,
  UPDATE_MANUSCRIPT_TEAMS,
  ADD_CORRECTION,
  UPDATE_PAYMENT_STATUS,
  SPECIES,
  CATEGORIES,
  SUBMISSION_TYPES,
  SET_PROTEOPEDIA_READY,
  EDIT_CORRECTION,
  SEARCH_FOR_ARTICLE,
  UPDATE_INTREGRATIONS,
  RATE_REVIEW,
  OTHER_SUBMISSIONS,
  GROUPS,
  SPECIALTIES,
} from '../../graphql'
import { stripHTML } from '../../../ui/src/_helpers'

import {
  ChatModal,
  DataTypeConfirmation,
  DateParser,
  EditConfirm,
  EditorPanel,
  ManuscriptTeamManager,
  SyncedTabs,
} from '../../../ui'

// import LinkingModal from '../../../ui/src/modals/LinkingModal'
import AcceptConfirmation from '../../../ui/src/modals/AcceptConfirmation'

import { ArticlePreview } from '../../components/ui'

import {
  exportManuscriptToPDF,
  // exportManuscriptEntities,
} from '../../fetch/exportManuscript'
import transform from '../_helpers/transformEditorPanelData'

import {
  getFromStorage,
  transformChatMessages,
  saveToStorage,
  parseCsv,
} from '../_helpers/common'
import {
  createDoi,
  publishDoi,
  publishCorrectionDoi,
} from '../../fetch/DataCiteApi'
import { getExtendedDataEntities, getEntities } from '../../fetch/BioentityApi'
import { uploadData, publishData } from '../../fetch/CaltechDataApi'
import { predictEntities, linkHTML, countLinks } from '../_helpers/linking'

/*
import {
  countLinks,
  deleteLinks,
  linkHTML,
  predictEntities,
} from '../_helpers/linking'
*/
import {
  uploadDocument,
  getReportUrl,
  getScore,
} from '../../fetch/IthenticateApi'
import ExtendedDataEntityTable from '../../../ui/src/modals/ExtendedDataEntityTable'

const pdfUrl =
  process.env.CLIENT_PDF_URL ||
  'https://www.micropublication.org/convert?auth=arachnys-weaver&url='

/* eslint-disable-next-line react/prop-types */
const Label = ({ created, text }) => (
  <DateParser dateFormat="MM.DD.YY HH:mm" timestamp={new Date(Number(created))}>
    {timestamp => (
      <span>
        {text}: {timestamp}
      </span>
    )}
  </DateParser>
)

const EditorView = props => {
  const { showEditorAssignment, manuscriptId } = props

  /**
   * Handle modals with state
   */
  const [selectedDataType, setSelectedDataType] = useState(null)
  const [showDataTypeConfirm, setShowDataTypeConfirm] = useState(false)

  const [chatModalThreadId, setChatModalThreadId] = useState(null)
  const [chatModalHeader, setChatModalHeader] = useState(null)
  const [showChatModal, setShowChatModal] = useState(false)
  const [showTeamManager, setShowTeamManager] = useState(false)
  // const [showLinkingModal, setShowLinkingModal] = useState(false)
  const [showAcceptConfirm, setShowAcceptConfirm] = useState(false)
  const [updatedDecisionLetter, setDecisionLetter] = useState(null)
  const [showEditConfirm, setShowEditConfirm] = useState(false)
  const [showExtendedDataEntities, setShowExtendedDataEntites] = useState(false)
  const [extendedDataEntities, setExtendedDataEntities] = useState(null)

  const [
    loadingExtendedDataEntities,
    setLoadingExtendedDataEntities,
  ] = useState(false)

  const client = useApolloClient()

  const onClickSetDataType = selected => {
    setSelectedDataType(selected)
    setShowDataTypeConfirm(true)
  }

  const onClickAcceptConfirm = letter => {
    setDecisionLetter(letter)
    setShowAcceptConfirm(true)
  }

  let chatData, assignedCurator // will be populated further down

  const onClickReviewerChat = reviewerId => {
    const selectedChat = chatData.find(item => item.reviewerId === reviewerId)
    setChatModalThreadId(selectedChat.id)
    setShowChatModal(true)
  }

  const onClickCuratorChat = curatorId => {
    const selectedChat = chatData.find(
      item => item.chatType === 'curator' && item.reviewerId === curatorId,
    )

    setChatModalThreadId(selectedChat.id)
    setChatModalHeader('Chat with Curator')
    setShowChatModal(true)
  }

  const setReportUrl = async () =>
    getReportUrl(manuscriptId).then(async url => {
      const reportUrl = await url.text()

      if (reportUrl) {
        window.open(reportUrl, '_blank')
      }
    })

  const setScore = async () =>
    getScore(manuscriptId).then(async result => {
      const score = await result.text()

      if (score) {
        setReportUrl(manuscriptId)
      }
    })

  /**
   * Queries & mutations
   */

  const {
    data: previewData,
    loading: previewLoading,
    refetch: previewRefetch,
  } = useQuery(FULL_PREVIEW, {
    variables: { id: manuscriptId },
  })

  const {
    data: editorData,
    loading: editorLoading,
    refetch: editorRefetch,
  } = useQuery(EDITOR_PANEL, {
    variables: { id: manuscriptId },
  })

  const { data: authorArticles } = useQuery(OTHER_SUBMISSIONS, {
    variables: { manuscriptId },
  })

  const { data: speciesQuery } = useQuery(SPECIES)
  const { data: categoryQuery } = useQuery(CATEGORIES)
  const { data: submissionTypeQuery } = useQuery(SUBMISSION_TYPES)
  const { data: specialtyQuery } = useQuery(SPECIALTIES)
  const { data: groupQuery } = useQuery(GROUPS)
  const speciesOptions = speciesQuery && speciesQuery.species
  const categoryOptions = categoryQuery && categoryQuery.categories
  const groupOptions = groupQuery && groupQuery.groups
  const specialtyOptions = specialtyQuery && specialtyQuery.specialties

  const submissionTypeOptions =
    submissionTypeQuery && submissionTypeQuery.submissionTypes

  const queryRefetch = {
    refetchQueries: [
      {
        query: EDITOR_PANEL,
        variables: { id: manuscriptId },
      },
    ],
  }

  const articleSearch = async input => {
    const res = await client.query({
      query: SEARCH_FOR_ARTICLE,
      fetchPolicy: 'network-only',
      variables: {
        searchQuery: input,
        limit: 10,
      },
    })

    if (res.data && res.data.searchForArticle) {
      const result = res.data.searchForArticle.map(item => {
        const { id, versions } = item

        const { title } = last(versions)

        return {
          value: id,
          label: stripHTML(title),
        }
      })

      return result
    }

    return []
  }

  const [reinviteReviewerMutation] = useMutation(
    REINVITE_REVIEWER,
    queryRefetch,
  )

  const [sendChatMutation] = useMutation(SEND_CHAT, {
    refetchQueries: [
      {
        query: EDITOR_PANEL,
        variables: { id: manuscriptId },
      },
      {
        query: FULL_PREVIEW,
        variables: { id: manuscriptId },
      },
    ],
  })

  const [setDataTypeMutation] = useMutation(SET_DATA_TYPE, queryRefetch)

  const [setProteopediaReadyMutation] = useMutation(
    SET_PROTEOPEDIA_READY,
    queryRefetch,
  )

  const [submitDecisionMutation] = useMutation(SUBMIT_DECISION, {
    refetchQueries: [
      {
        query: EDITOR_PANEL,
        variables: { id: manuscriptId },
      },
      {
        query: FULL_PREVIEW,
        variables: { id: manuscriptId },
      },
    ],
  })

  const [updateManuscriptTeamsMutation] = useMutation(
    UPDATE_MANUSCRIPT_TEAMS,
    queryRefetch,
  )

  const [updateMetadataMutation] = useMutation(
    UPDATE_MANUSCRIPT_METADATA,
    queryRefetch,
  )

  const [updateHistoryMutation] = useMutation(
    UPDATE_MANUSCRIPT_HISTORY,
    queryRefetch,
  )

  const [addCorrectionMutation] = useMutation(ADD_CORRECTION, queryRefetch)

  const [editCorrectionMutation] = useMutation(EDIT_CORRECTION, queryRefetch)

  const [updateEntitiesMutation] = useMutation(UPDATE_ENTITIES, {
    refetchQueries: [{ query: FULL_PREVIEW, variables: { id: manuscriptId } }],
  })

  const [updatePaymentStatusMutation] = useMutation(
    UPDATE_PAYMENT_STATUS,
    queryRefetch,
  )

  const [updateIntegrationsMutation] = useMutation(
    UPDATE_INTREGRATIONS,
    queryRefetch,
  )

  const [rateReviewMutation] = useMutation(RATE_REVIEW, queryRefetch)

  /**
   * Left side: preview
   */

  let leftSections,
    saveAuthorChat,
    authorChatMessages,
    getSavedAuthorChat,
    sendAuthorChatMessage

  if (
    previewData &&
    previewData.manuscript &&
    previewData.manuscript.versions
  ) {
    // there is only one chat here, the author chat
    const authorChat = previewData.manuscript.chatThreads[0]

    authorChatMessages = transformChatMessages(authorChat.messages)

    sendAuthorChatMessage = content =>
      sendChatMutation({
        variables: {
          input: {
            chatThreadId: authorChat.id,
            content,
          },
        },
      })

    saveAuthorChat = message => saveToStorage(message, `chat_${authorChat.id}`)

    getSavedAuthorChat = () => getFromStorage(`chat_${authorChat.id}`)

    const filteredVersions = previewData.manuscript.versions.filter(
      v =>
        (!v.submitted &&
          previewData.manuscript.isInitiallySubmitted &&
          !previewData.manuscript.isDataTypeSelected) ||
        v.submitted,
    )

    leftSections = filteredVersions.map((version, index) => {
      let text = 'Revision'
      if (index === 0) text = 'Original'
      if (index > 0 && filteredVersions[index - 1].editorEdit) text = 'Copyedit'

      const clonedVersion = clone(version)

      if (version.image.data) {
        const parsedCsv = parseCsv(version.image.data)
        clonedVersion.csvHeader = parsedCsv.csvHeader
        clonedVersion.csvData = parsedCsv.csvData
      }

      return {
        key: version.id,
        label: <Label created={version.created} text={text} />,
        content: (
          <ArticlePreview
            article={clonedVersion}
            authorChatMessages={authorChatMessages}
            categories={previewData.manuscript.categories}
            exportManuscript={exportManuscriptToPDF}
            getSavedAuthorChat={getSavedAuthorChat}
            isEditor
            manuscriptId={manuscriptId}
            previousVersion={filteredVersions[index - 1]}
            saveAuthorChat={saveAuthorChat}
            sendAuthorChatMessage={sendAuthorChatMessage}
            showAdditionalData
            showHeader={false}
            submissionTypes={previewData.manuscript.submissionTypes}
          />
        ),
      }
    })
  }

  /**
   * Right side: editor panel
   */

  let rightSections
  let editors, sectionEditors, scienceOfficers, curators
  let assignedEditor, assignedSectionEditor, assignedScienceOfficer
  let sendModalChatMessage,
    setDataType,
    updateManuscriptTeams,
    updateMetadata,
    updateHistory,
    updateIntegrations
  let categories,
    citations,
    dbReferenceId,
    doi,
    species,
    submissionTypes,
    submitDecision,
    curated,
    editorCanEdit,
    submitDecisionFn,
    dataUploaded,
    addCorrection,
    editCorrection,
    corrections,
    isDataTypeSelected,
    proteopedia,
    proteopediaVerify,
    topics,
    groups

  const getSavedChat = () => getFromStorage(`chat_${chatModalThreadId}`)

  const saveChat = content =>
    saveToStorage(content, `chat_${chatModalThreadId}`)

  const finalizeDoi = () => publishDoi(manuscriptId)

  if (!editorLoading && editorData) {
    const transformed = transform(editorData, {
      finalizeDoi,
      reinviteReviewerMutation,
      sendChatMutation,
      setDataTypeMutation,
      setProteopediaReadyMutation,
      submitDecisionMutation,
      updateManuscriptTeamsMutation,
      updateMetadataMutation,
      updateHistoryMutation,
      updateIntegrationsMutation,
      addCorrectionMutation,
      editCorrectionMutation,
      publishCorrectionDoi,
      updateEntitiesMutation,
      updatePaymentStatusMutation,
      publishData,
    })

    ;({
      assignedCurator,
      assignedEditor,
      assignedSectionEditor,
      assignedScienceOfficer,

      editors,
      sectionEditors,
      scienceOfficers,
      curators,

      chatData,

      setDataType,
      // updateEntities,
      updateManuscriptTeams,
      updateMetadata,
      updateHistory,
      updateIntegrations,
      submitDecision: submitDecisionFn,
      species,
      submissionTypes,
      addCorrection,
      editCorrection,
      categories,
      citations,
      corrections,
      dbReferenceId,
      doi,
      isDataTypeSelected,
      proteopedia,
      topics,
      groups,
    } = transformed)

    const {
      dataRelease,
      pmId,
      pmcId,
      history,
      ithenticate,
      integrations,
      reinviteReviewer: reinviteReviewerFn,
      sendChatMessage,
      versions,
      paymentStatus,
      setProteopediaReady,
      updatePaymentStatus,
      reviewPanel,
    } = transformed

    const scienceOfficerChat = chatData.find(
      thread => thread.chatType === 'scienceOfficer',
    )

    const scienceOfficerChatMessages = scienceOfficerChat.messages

    const sendScienceOfficerChatMessage = content =>
      sendChatMessage(content, scienceOfficerChat.id)

    const getSavedSOChat = () => getFromStorage(`chat_${scienceOfficerChat.id}`)

    const saveSOChat = message =>
      saveToStorage(message, `chat_${scienceOfficerChat.id}`)

    sendModalChatMessage = content =>
      sendChatMessage(content, chatModalThreadId)

    const curatorAssessments = versions.filter(
      v => v.curatorReview.filter(r => r.submitted).length > 0,
    )

    if (curatorAssessments.length > 0) {
      curated = 'Yes'
    }

    const milestoneTypes = {
      received: [],
      initialRevision: [],
      reviewerInvited: [],
      reviewReceived: [],
      reviewRevision: [],
      thirdParty: [],
      curatedRevision: [],
      proof: [],
      published: [],
    }

    if (history.curated) {
      milestoneTypes.curatedRevision.push(history.curated)
    }

    if (history.thirdParty) {
      milestoneTypes.thirdParty.push(history.thirdParty)
    }

    versions.forEach((v, i) => {
      const previousVersion = versions[i - 1]

      if (v.reviews.some(review => !review.pending)) {
        v.reviews
          .filter(r => !r.pending)
          .forEach(r => {
            milestoneTypes.reviewReceived.push(r.updated)
          })

        if (v.invitedReviewers.length > 0)
          milestoneTypes.reviewerInvited.push(v.invitedReviewers[0].created)
      }

      if (i === 0) {
        milestoneTypes.received.push(history.received)

        if (
          v.decision === 'revise' &&
          previousVersion &&
          !previousVersion.reviews.some(r => !r.pending)
        ) {
          milestoneTypes.initialRevision.push(versions[1].updated)
        }
      } else if (
        previousVersion.decision === 'revise' &&
        previousVersion.reviews.some(r => !r.pending)
      ) {
        milestoneTypes.reviewRevision.push(v.created)
      }

      /*
        if (v.decision === 'curate') {
          milestoneTypes.thirdParty.push(v.updated)
        }
        
        if (previousVersion.decision === 'curate') {
          milestoneTypes.curatedRevision.push(v.updated)
        }
        */

      if (v.decision === 'accept') {
        milestoneTypes.proof.push(v.updated)
      }

      if (v.decision === 'publish') {
        milestoneTypes.published.push(v.updated)
      }
    })

    const milestones = Object.keys(milestoneTypes).map(type => ({
      label: startCase(type),
      dates: milestoneTypes[type].map(d =>
        Number(d) ? new Date(Number(d)) : new Date(d),
      ),
    }))

    rightSections = versions.map((version, index) => {
      // setDecisionLetter(version.decisionLetter)

      const {
        authorEmail,
        authorName,
        created,
        citation,
        confidentialComments,
        dataType,
        decision,
        decisionLetter,
        id,
        latest,
        title,
        extendedData,
        proteopediaOptIn,

        invitedReviewersCount,
        acceptedReviewersCount,
        rejectedReviewersCount,
        revokedReviewersCount,

        curatorReview,
        reviews,
        previousReviewers,
      } = version

      const getSavedDecision = () =>
        JSON.parse(getFromStorage(`decision_${id}`))

      const saveDecision = message =>
        saveToStorage(JSON.stringify(message), `decision_${id}`)

      const editorName = assignedEditor && assignedEditor.displayName
      const curatorId = assignedCurator && assignedCurator.id

      const curatorNames =
        assignedCurator && assignedCurator.map(c => c.displayName)

      const sectionEditorName =
        assignedSectionEditor && assignedSectionEditor.displayName

      const scienceOfficerName =
        assignedScienceOfficer && assignedScienceOfficer.displayName

      const reinviteReviewer = reviewerId =>
        reinviteReviewerFn(version.id, reviewerId)

      const uploadCaltechData = file =>
        uploadData(id, file).then(() => {
          editorRefetch()
          previewRefetch()
        })

      submitDecision = input => submitDecisionFn(version.id, input)

      const printLink = `${serverUrl}/api/export/${version.id}/print`
      const proofLink = pdfUrl ? `${pdfUrl}${printLink}` : printLink
      const articleUrl = `${serverUrl}/article/${manuscriptId}`
      const paymentUrl = `${serverUrl}/payment/${manuscriptId}`
      const htmlInvoice = `${serverUrl}/api/export/${manuscriptId}/invoice`
      const invoiceUrl = pdfUrl ? `${pdfUrl}${htmlInvoice}` : htmlInvoice

      const invoiceNo = doi && doi.split('.').pop()

      const doiSuffix = doi && doi.split('/')[1].replace(/\./g, '-')
      const publishedUrl = `https://micropublication.org/journals/biology/${doiSuffix}`
      const linkerLink = `/linker/${manuscriptId}`

      const reviewExists = reviews.some(review => !review.pending)

      const reviseQualifier = first(
        reviews
          .filter(review => review.reviseQualifier)
          .map(review => review.reviseQualifier),
      )

      const isResubmission = versions.length > 1

      editorCanEdit = decision === 'publish' || !decision

      const ithenticateUpload = async () => uploadDocument(manuscriptId)

      if (latest && extendedData && extendedData.length > 0) {
        const numNotUploaded = extendedData.filter(e => !e.doi).length
        dataUploaded =
          numNotUploaded === 0 ? 'Yes' : `${numNotUploaded} not uploaded`
      }

      proteopediaVerify = proteopediaOptIn ? proteopedia : null

      let text = 'Revision'
      if (index === 0) text = 'Original'
      if (index > 0 && versions[index - 1].editorEdit) text = 'Copyedit'

      const addArticleToIntegration = option => {
        const currentIntegrations = integrations || []

        const newIntegrations = [
          ...currentIntegrations,
          { manuscriptId: option.value, title: option.label },
        ]

        return updateIntegrations(newIntegrations)
      }

      const reorderIntegrations = newOrderedOptions => {
        const transformedOptions = newOrderedOptions.map(i => {
          const clonedOption = clone(i)
          clonedOption.manuscriptId = i.id
          return clonedOption
        })

        updateIntegrations(transformedOptions)
      }

      const removeIntegration = articleId =>
        updateIntegrations(
          integrations.filter(i => i.manuscriptId !== articleId),
        )

      const curatorWarning = assignedCurator.length === 0

      const skippedProof = !versions.some(v => v.decision === 'accept')

      const kwsMap = {
        'c. elegans': 'WB Entity',
        drosophila: 'Fly Entities',
        's. cerevisiae': 'SGD_gap',
        's. pombe': 'Pombe_gene',
        arabidopsis: 'TAIR_GCAT',
        mouse: 'Mouse Genes',
        human: 'Hum_genes',
        zebrafish: 'Zebrafish Genes',
        rat: 'Rat Entities',
      }

      const onClickShowExtendedDataEntities = async filename => {
        const kws = kwsMap[species[0]]
        setLoadingExtendedDataEntities(true)
        return getExtendedDataEntities(filename, kws)
          .then(result => result.json())
          .then(result => {
            setExtendedDataEntities(result)
            setShowExtendedDataEntites(true)
            setLoadingExtendedDataEntities(false)
          })
      }

      return {
        key: id,
        label: <Label created={created} text={text} />,
        content: (
          <EditorPanel
            acceptedReviewersCount={acceptedReviewersCount}
            articleSearch={articleSearch}
            articleTitle={title}
            articleUrl={articleUrl}
            authorArticles={authorArticles}
            authorChatMessages={authorChatMessages}
            authorEmail={authorEmail}
            authorName={authorName}
            categories={categories}
            categoryOptions={categoryOptions}
            citation={citation}
            citations={citations}
            confidentialComments={confidentialComments}
            corrections={corrections}
            createDoi={createDoi}
            curatorId={curatorId}
            curatorName={curatorNames}
            curatorReview={curatorReview}
            curatorWarning={curatorWarning}
            dataRelease={dataRelease}
            dataType={dataType}
            dbReferenceId={dbReferenceId}
            decision={decision}
            decisionLetter={decisionLetter}
            decisionSubmitted={!!decision}
            doi={doi}
            editCorrection={editCorrection}
            editorCanEdit={editorCanEdit}
            editorName={editorName}
            extendedData={extendedData}
            getSavedAuthorChat={getSavedAuthorChat}
            getSavedDecision={getSavedDecision}
            getSavedSOChat={getSavedSOChat}
            groupOptions={groupOptions}
            groups={groups}
            history={history}
            integrations={integrations}
            invitedReviewersCount={invitedReviewersCount}
            invoiceNo={invoiceNo}
            invoiceUrl={invoiceUrl}
            isResubmission={isResubmission}
            ithenticate={ithenticate}
            ithenticateUpload={ithenticateUpload}
            key={version.id}
            linkerLink={linkerLink}
            loadingExtendedDataEntities={loadingExtendedDataEntities}
            milestones={milestones}
            onClickAcceptConfirm={onClickAcceptConfirm}
            onClickCuratorChat={onClickCuratorChat}
            onClickEdit={() => setShowEditConfirm(true)}
            onClickIntegrationArticle={addArticleToIntegration}
            onClickReviewerChat={onClickReviewerChat}
            onClickReviewerReinvite={reinviteReviewer}
            onClickSetDataType={onClickSetDataType}
            onClickShowExtendedDataEntities={onClickShowExtendedDataEntities}
            onClickTeamManager={() => setShowTeamManager(true)}
            paymentStatus={paymentStatus}
            paymentUrl={paymentUrl}
            pmcId={pmcId}
            pmId={pmId}
            proofLink={proofLink}
            proteopedia={proteopedia}
            publishedUrl={publishedUrl}
            rateReview={rateReviewMutation}
            rejectedReviewersCount={rejectedReviewersCount}
            removeIntegration={removeIntegration}
            reorderIntegrations={reorderIntegrations}
            reviewersFromPreviousVersions={previousReviewers}
            reviewersPageUrl={`/assign-reviewers/${manuscriptId}`}
            reviewExists={reviewExists}
            reviewPanel={reviewPanel}
            reviews={reviews}
            reviseQualifier={reviseQualifier}
            revokedReviewersCount={revokedReviewersCount}
            saveAuthorChat={saveAuthorChat}
            saveDecision={saveDecision}
            saveSOChat={saveSOChat}
            scienceOfficerChatMessages={scienceOfficerChatMessages}
            scienceOfficerName={scienceOfficerName}
            sectionEditorName={sectionEditorName}
            sendAuthorChatMessage={sendAuthorChatMessage}
            sendScienceOfficerChatMessage={sendScienceOfficerChatMessage}
            setProteopediaReady={setProteopediaReady}
            setReportUrl={setReportUrl}
            setScore={setScore}
            showAuthorArticles
            showAuthorChat
            showCorrections
            showCuratorChat
            showCurators
            showDataType={!dataType}
            showDataTypeSelection
            showDecision
            showExtendedData={latest && extendedData && extendedData.length > 0}
            showHistory={latest}
            showInfo={latest}
            showIntegrations={latest}
            showManageReviewers={latest}
            showMetadata={latest}
            showPaymentStatus
            showPreviousReviewers={latest && versions.length > 1}
            showProteopedia={proteopediaOptIn}
            showReviewersChat
            showReviews={dataType !== null}
            showScienceOfficerChat={latest}
            showSOLabels
            showTeamManager
            skippedProof={skippedProof}
            specialtyOptions={specialtyOptions}
            species={species}
            speciesOptions={speciesOptions}
            startAuthorArticlesExpanded={!dataType}
            startDataTypeExpanded={!dataType}
            submissionTypeOptions={submissionTypeOptions}
            submissionTypes={submissionTypes}
            submitCorrection={addCorrection}
            submitDecision={submitDecision}
            topics={topics}
            updateHistory={updateHistory}
            updateMetadata={updateMetadata}
            updatePaymentStatus={updatePaymentStatus}
            uploadData={uploadCaltechData}
            versionId={version.id}
          />
        ),
      }
    })
  }

  /**
   * Render
   */
  const savedEntities =
    previewData && previewData.manuscript && previewData.manuscript.entities

  const latestVersion =
    previewData &&
    previewData.manuscript &&
    previewData.manuscript.versions &&
    last(previewData.manuscript.versions)

  //  const exportEntities = () =>
  //  exportManuscriptEntities(previewData.manuscript.id)
  const autoLink = async version => {
    const kwsMap = {
      'c. elegans': 'WB Entity',
      drosophila: 'Fly Entities',
      's. cerevisiae': 'SGD_gap',
      's. pombe': 'Pombe Entities',
      arabidopsis: 'TAIR_GCAT',
      mouse: 'Mouse Genes',
      human: 'Hum_genes',
      zebrafish: 'Zebrafish Genes',
      rat: 'Rat Entities',
    }

    await getEntities(version.id, kwsMap[species[0].toLowerCase()])
      .then(res => res.json())
      .then(async result => {
        const sortedEntities = result.sort((a, b) => {
          if (a.match.length < b.match.length) return 1
          if (a.match.length > b.match.length) return -1
          return 0
        })

        const clonedVersion = clone(version)
        clonedVersion.species = species
        const predictions = predictEntities(clonedVersion)

        const filteredPredictions = predictions.filter(
          p => sortedEntities.findIndex(e => e.match === p.match) === -1,
        )

        const linkedVersion = await linkHTML(clonedVersion, [
          ...sortedEntities,
          ...filteredPredictions,
        ])

        const linkedEntities = countLinks(linkedVersion, [
          ...sortedEntities,
          ...filteredPredictions,
        ]).map(e => {
          delete e.count
          delete e.matchIsSource
          return e
        })

        updateEntitiesMutation({
          variables: {
            manuscriptId,
            data: linkedEntities,
          },
        })
      })
  }

  return (
    <>
      <SyncedTabs
        leftHeader="Article Preview"
        leftLoading={previewLoading}
        leftSections={leftSections}
        rightHeader="Editor Panel"
        rightLoading={editorLoading}
        rightSections={rightSections}
      />
      {/* Modals */}
      {!editorLoading && (
        <ManuscriptTeamManager
          assignedCurator={assignedCurator}
          assignedEditor={assignedEditor}
          assignedScienceOfficer={assignedScienceOfficer}
          assignedSectionEditor={assignedSectionEditor}
          curators={curators}
          editors={editors}
          // isOpen
          isOpen={showTeamManager}
          onRequestClose={() => setShowTeamManager(false)}
          scienceOfficers={scienceOfficers}
          sectionEditors={sectionEditors}
          showEditorAssignment={showEditorAssignment}
          updateTeams={updateManuscriptTeams}
        />
      )}
      {!isDataTypeSelected && (
        <DataTypeConfirmation
          dataTypeLabel={selectedDataType && selectedDataType.label}
          isOpen={showDataTypeConfirm}
          onConfirm={() => {
            setDataType(selectedDataType.value).then(() => {
              // uploadDocument(manuscriptId)
              if (!savedEntities) {
                autoLink(latestVersion).then(() =>
                  setShowDataTypeConfirm(false),
                )
              } else {
                setShowDataTypeConfirm(false)
              }
            })
          }}
          onRequestClose={() => setShowDataTypeConfirm(false)}
        />
      )}
      {chatModalThreadId && (
        <ChatModal
          getSavedChat={getSavedChat}
          headerText={chatModalHeader}
          isOpen={showChatModal}
          messages={
            chatData.find(thread => thread.id === chatModalThreadId).messages
          }
          onRequestClose={() => {
            setShowChatModal(false)
            setChatModalHeader(null)
          }}
          saveChat={saveChat}
          sendMessage={sendModalChatMessage}
        />
      )}
      <AcceptConfirmation
        categories={categories}
        curated={curated}
        dataUploaded={dataUploaded}
        dbReferenceId={dbReferenceId}
        doi={doi}
        isOpen={showAcceptConfirm}
        onConfirm={() => {
          submitDecision({
            decision: 'accept',
            decisionLetter: updatedDecisionLetter,
          }).then(() => {
            setShowAcceptConfirm(false)
          })
        }}
        onRequestClose={() => setShowAcceptConfirm(false)}
        proteopediaVerify={proteopediaVerify}
        species={species}
        submissionTypes={submissionTypes}
      />

      {editorCanEdit && (
        <EditConfirm
          isOpen={showEditConfirm}
          onConfirm={() => {
            localStorage.setItem('edit', manuscriptId)
            return submitDecisionFn(latestVersion.id, {
              decision: 'edit',
              decisionLetter: 'Editor needs to edit',
            })
          }}
          onRequestClose={() => setShowEditConfirm(false)}
        />
      )}

      <ExtendedDataEntityTable
        extendedDataEntities={extendedDataEntities}
        isOpen={showExtendedDataEntities}
        onRequestClose={() => setShowExtendedDataEntites(false)}
      />
    </>
  )
}

EditorView.propTypes = {
  manuscriptId: PropTypes.string.isRequired,
  showEditorAssignment: PropTypes.bool.isRequired,
}

export default EditorView
