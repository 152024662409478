import { gql } from '@apollo/client'

const previewBaseFragment = gql`
  fragment PreviewBase on ManuscriptVersion {
    id
    created
    active
    submitted
    editorEdit
    abstract
    acknowledgements
    authors {
      affiliations
      credit
      departments
      email
      firstName
      lastName
      submittingAuthor
      correspondingAuthor
      equalContribution
      WBId
      orcid
      academicStatus
    }
    awards {
      funderId
      funderName
      awardId
      awardRecipient
    }
    decision
    disclaimer
    apcDisclaimer
    proteopediaOptIn
    funding
    image {
      name
      url
      data
    }
    imageCaption
    imageTitle
    laboratory {
      name
      WBId
    }
    methods
    reagents
    patternDescription
    references {
      reference
      pubmedId
      doi
    }
    title
    extendedData {
      name
      url
      description
      resourceType
      softwareUrl
      softwareVersion
      softwareLicense
      licenseLink
      doi
    }

    # TO DO - SHOULD GENE EXPRESSION FORM DATA BE AVAILABLE TO REVIEWERS?
  }
`

const RESTRICTED_PREVIEW = gql`
  ${previewBaseFragment}

  query RestricedPreview($id: ID!) {
    manuscript(id: $id) {
      id
      versions {
        ...PreviewBase
      }
    }
  }
`

const REVIEWER_PREVIEW = gql`
  ${previewBaseFragment}

  query RestricedPreview($id: ID!) {
    manuscript(id: $id) {
      id
      categories
      submissionTypes
      versions(invitedToReviewOnly: true) {
        ...PreviewBase
      }
    }
  }
`

const FULL_PREVIEW = gql`
  ${previewBaseFragment}

  query FullPreview($id: ID!) {
    manuscript(id: $id) {
      id
      authorEntitiesComments
      categories
      dbReferenceId
      isDataTypeSelected
      isInitiallySubmitted
      submissionTypes
      chatThreads(type: author) {
        id
        chatType
        messages {
          id
          content
          timestamp
          user {
            displayName
          }
        }
      }
      entities {
        match
        url
        sourceId
        source
        type
        deleted
        method
      }
      species
      versions {
        ...PreviewBase
        comments
        suggestedReviewer {
          name
          WBId
        }
      }
    }
  }
`

// full preview without the author chat
const SCIENCE_OFFICER_PREVIEW = gql`
  ${previewBaseFragment}

  query FullPreview($id: ID!) {
    manuscript(id: $id) {
      id
      categories
      isDataTypeSelected
      isInitiallySubmitted
      submissionTypes
      versions {
        ...PreviewBase
        comments
        suggestedReviewer {
          name
          WBId
        }
      }
    }
  }
`

const CURATOR_PREVIEW = SCIENCE_OFFICER_PREVIEW

const MANUSCRIPT_STATUS = gql`
  query ManuscriptStatus($id: ID!) {
    manuscript(id: $id) {
      id
      isInitiallySubmitted
      versions {
        id
        decision
        submitted
        editorEdit
      }
    }
  }
`

const MANUSCRIPT_SUBMISSION_FORM = gql`
  query manuscript($id: ID!) {
    manuscript(id: $id) {
      id
      categories
      dataType
      species
      submissionTypes
      topics
      versions {
        id
        editorEdit
        decisionLetter
        submitted
        abstract
        acknowledgements
        authors {
          affiliations
          credit
          departments
          email
          firstName
          lastName
          submittingAuthor
          correspondingAuthor
          equalContribution
          WBId
          orcid
          academicStatus
        }
        awards {
          funderId
          funderName
          awardId
          awardRecipient
        }
        comments
        disclaimer
        apcDisclaimer
        proteopediaOptIn
        funding
        image {
          name
          url
          data
        }
        imageCaption
        imageTitle
        laboratory {
          name
          WBId
        }
        methods
        reagents
        patternDescription
        references {
          reference
          pubmedId
          doi
        }
        suggestedReviewer {
          name
          WBId
        }
        title
        extendedData {
          name
          url
          description
          resourceType
          softwareUrl
          softwareLicense
          softwareVersion
          licenseLink
          doi
        }
      }
    }
  }
`

const USER_COAUTHORS = gql`
  query UserCoauthors($id: ID!) {
    userCoauthors(id: $id) {
      affiliations
      credit
      departments
      email
      firstName
      lastName
      submittingAuthor
      correspondingAuthor
      equalContribution
      WBId
      orcid
      academicStatus
    }
  }
`

const EDITOR_PANEL = gql`
  query EditorPanel($id: ID!) {
    getGlobalTeamsForEditor {
      role
      id
      displayName
      species
      numTeamArticles
    }

    manuscript(id: $id) {
      chatThreads {
        id
        chatType
        messages {
          content
          timestamp
          user {
            displayName
          }
        }
        userId
      }
      # currentlyWith
      categories
      citations
      corrections {
        correctionType
        description
        doi
        date
        dbReferenceId
      }
      dataRelease
      dataType
      dbReferenceId
      doi
      history {
        received
        sentForReview
        reviewReceived
        revisionReceived
        accepted
        published
        indexed
        curated
        thirdParty
      }
      id
      integrations {
        manuscriptId
        title
      }
      isDataTypeSelected
      isInitiallySubmitted
      ithenticate {
        id
        docId
        score
      }
      paymentStatus
      pmId
      pmcId
      proteopedia
      reviewPanel
      species
      submissionTypes
      topics
      teams {
        id
        role
        members {
          id
          user {
            id
            displayName
          }
        }
      }
      versions {
        id
        title
        created
        updated
        active
        isApprovedByScienceOfficer
        submitted
        authors {
          email
          firstName
          lastName
          submittingAuthor
        }
        curatorReviews {
          id
          content
          curator {
            id
            displayName
          }
          recommendation
          openAcknowledgement
          submitted
          curated
        }
        confidentialComments
        decision
        decisionLetter
        editorEdit
        extendedData {
          name
          description
          url
          resourceType
          doi
          softwareUrl
          softwareLicense
          softwareVersion
          licenseLink
        }
        proteopediaOptIn
        reviews {
          id
          content
          confidentialComments
          openAcknowledgement
          rating
          recommendation
          reviewer {
            id
            displayName
          }
          reviseQualifier
          askedToSeeRevision
          status {
            pending
            submitted
          }
          updated
        }
        teams {
          id
          role
          members {
            id
            status
            created
            user {
              id
              displayName
              manuscripts {
                id
                history {
                  received
                  published
                }
                versions {
                  authors {
                    firstName
                    lastName
                  }
                  decision
                  title
                  reviews {
                    openAcknowledgement
                    reviewer {
                      displayName
                    }
                    status {
                      pending
                      submitted
                    }
                  }
                  teams {
                    role
                    members {
                      status
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const SCIENCE_OFFICER_PANEL = gql`
  query EditorPanel($id: ID!) {
    manuscript(id: $id) {
      chatThreads(type: scienceOfficer) {
        id
        chatType
        messages {
          content
          timestamp
          user {
            displayName
          }
        }
      }
      # currentlyWith
      dataType
      entities {
        match
        url
        sourceId
        source
        type
        deleted
        method
      }
      # doi
      id
      isDataTypeSelected
      isInitiallySubmitted
      teams {
        id
        role
        members {
          id
          user {
            id
            displayName
          }
        }
      }
      versions {
        id
        created
        active
        # isApprovedByScienceOfficer
        submitted
        authors {
          email
          firstName
          lastName
          submittingAuthor
        }
        confidentialComments
        decision
        decisionLetter
        reviews {
          id
          content
          confidentialComments
          openAcknowledgement
          rating
          recommendation
          reviewer {
            id
            displayName
          }
          reviseQualifier
          askedToSeeRevision
          status {
            pending
            submitted
          }
        }
        teams {
          id
          role
          members {
            id
            status
            user {
              id
              displayName
            }
          }
        }
      }
    }
  }
`

const REVIEWER_PANEL = gql`
  query ReviewerPanel($id: ID!) {
    manuscript(id: $id) {
      id
      chatThreads(currentUserOnly: true, type: reviewer) {
        id
        messages {
          id
          content
          timestamp
          user {
            id
            displayName
          }
        }
      }
      versions(invitedToReviewOnly: true) {
        id
        created
        decision
        reviews(currentUserOnly: true) {
          id
          confidentialComments
          content
          recommendation
          reviseQualifier
          openAcknowledgement
          askedToSeeRevision
          status {
            pending
            submitted
          }
        }
        reviewerMessage
      }
    }
  }
`

const CURATOR_PANEL = gql`
  query ReviewerPanel($id: ID!) {
    manuscript(id: $id) {
      id
      chatThreads(currentUserOnly: true, type: curator) {
        id
        messages {
          id
          content
          timestamp
          user {
            id
            displayName
          }
        }
      }
      dbReferenceId
      doi
      versions {
        id
        created
        decision
        submitted
        curatorReviews(currentUserOnly: true) {
          id
          content
          recommendation
          openAcknowledgement
          submitted
          curated
        }
      }
    }
  }
`

const SEARCH_FOR_ARTICLE = gql`
  query SearchForArticle($searchQuery: String!) {
    searchForArticle(searchQuery: $searchQuery, limit: 10) {
      id
      versions {
        title
      }
    }
  }
`

const CURATION_ANNOTATIONS = gql`
  query CurationAnnotations($manuscriptId: ID!) {
    curationAnnotations(manuscriptId: $manuscriptId) {
      id
      anatomyTerms {
        value
        modId
        options {
          Insufficient
          Unnecessary
        }
      }
      assay {
        value
      }
      cellularComponent {
        value
        modId
      }
      dateAssigned
      evidence
      gene {
        value
        modId
      }
      genes {
        value
        modId
      }
      genotype
      involved
      lifeStages {
        value
        modId
      }
      noctuamodel
      notObserved
      object {
        value
        modId
      }
      phenotype {
        value
        modId
      }
      phenotypeStatement
      phenotypeTerms {
        value
        modId
      }
      remark
      strains {
        value
        modId
      }
      whenExpressed {
        value
        modId
      }
      whereExpressed {
        value
        modId
      }
      type
    }
  }
`

const REINVITE_REVIEWER = gql`
  mutation ReinviteReviewer($input: ReinviteReviewerInput!) {
    reinviteReviewer(input: $input)
  }
`

const SAVE_CURATOR_REVIEW = gql`
  mutation SaveCuratorReview($id: ID!, $input: CuratorReviewInput!) {
    saveCuratorReview(id: $id, input: $input) {
      id
      content
      recommendation
      openAcknowledgement
      curated
    }
  }
`

const SUBMIT_CURATOR_REVIEW = gql`
  mutation SubmitCuratorReview($id: ID!, $input: CuratorReviewInput!) {
    submitCuratorReview(id: $id, input: $input) {
      id
      content
      recommendation
      openAcknowledgement
      submitted
      curated
    }
  }
`

const SUBMIT_CURATION = gql`
  mutation SubmitCurations($id: ID!, $curated: String) {
    submitCuration(id: $id, curated: $curated) {
      id
      content
      recommendation
      openAcknowledgement
      submitted
      curated
    }
  }
`

const SAVE_FORM = gql`
  mutation saveSubmissionForm($input: SubmissionFormInput!) {
    saveSubmissionForm(input: $input)
  }
`

const SAVE_REVIEW = gql`
  mutation SaveReview($reviewId: ID!, $input: SubmitReviewInput!) {
    saveReview(reviewId: $reviewId, input: $input)
  }
`

const SEND_CHAT = gql`
  mutation SendChatMessage($input: SendChatMessageInput!) {
    sendChatMessage(input: $input)
  }
`

const SET_DATA_TYPE = gql`
  mutation SetDataType($manuscriptId: ID!, $input: SetDataTypeInput!) {
    setDataType(manuscriptId: $manuscriptId, input: $input)
  }
`

const SUBMIT_DECISION = gql`
  mutation SubmitDecision(
    $manuscriptVersionId: ID!
    $input: SubmitDecisionInput!
  ) {
    submitDecision(manuscriptVersionId: $manuscriptVersionId, input: $input)
  }
`

const SUBMIT_MANUSCRIPT = gql`
  mutation SubmitManuscript($input: SubmissionFormInput!) {
    submitManuscript(input: $input)
  }
`

const SUBMIT_REVIEW = gql`
  mutation SubmitReview($reviewId: ID!, $input: SubmitReviewInput!) {
    submitReview(reviewId: $reviewId, input: $input)
  }
`

const UPDATE_MANUSCRIPT_HISTORY = gql`
  mutation UpdateManuscriptHistory($manuscriptId: ID!, $data: HistoryInput!) {
    updateManuscriptHistory(manuscriptId: $manuscriptId, data: $data)
  }
`

const UPDATE_MANUSCRIPT_METADATA = gql`
  mutation UpdateManuscriptMetadata($manuscriptId: ID!, $data: MetadataInput!) {
    updateManuscriptMetadata(manuscriptId: $manuscriptId, data: $data)
  }
`

const UPDATE_MANUSCRIPT_TEAMS = gql`
  mutation UpdateManuscriptTeams($input: UpdateTeamMembershipInput!) {
    updateManuscriptTeamMembership(input: $input)
  }
`

const UPLOAD_FILE = gql`
  mutation UploadFile($file: Upload!) {
    upload(file: $file) {
      url
    }
  }
`

const UPLOAD_PROGRESS = gql`
  subscription UploadProgress {
    uploadProgress
  }
`

const ADD_CORRECTION = gql`
  mutation AddCorrection($manuscriptId: ID!, $input: CorrectionInput!) {
    addCorrection(manuscriptId: $manuscriptId, input: $input)
  }
`

const EDIT_CORRECTION = gql`
  mutation EditCorrection($manuscriptId: ID!, $input: CorrectionInput!) {
    editCorrection(manuscriptId: $manuscriptId, input: $input)
  }
`

const DELETE_ANNOTATION = gql`
  mutation DeleteAnnotation($id: ID!) {
    deleteAnnotation(id: $id)
  }
`

const SAVE_ANNOTATION = gql`
  mutation SaveAnnotation(
    $manuscriptId: ID!
    $type: String!
    $input: AnnotationInput!
  ) {
    saveAnnotation(manuscriptId: $manuscriptId, type: $type, input: $input)
  }
`

const UPDATE_ENTITIES = gql`
  mutation UpdateEntities(
    $manuscriptId: ID!
    $data: [EntityInput]
    $authorEntitiesComments: String
  ) {
    updateEntities(
      manuscriptId: $manuscriptId
      data: $data
      authorEntitiesComments: $authorEntitiesComments
    )
  }
`

const UPDATE_PAYMENT_STATUS = gql`
  mutation UpdatePaymentStatus(
    $manuscriptId: ID!
    $input: PaymentStatusInput!
  ) {
    updatePaymentStatus(manuscriptId: $manuscriptId, input: $input)
  }
`

const SET_PROTEOPEDIA_READY = gql`
  mutation SetProteopediaReady($manuscriptId: ID!, $proteopedia: String!) {
    setProteopediaReady(manuscriptId: $manuscriptId, proteopedia: $proteopedia)
  }
`

const UPDATE_INTREGRATIONS = gql`
  mutation UpdateIntegrations($manuscriptId: ID!, $articles: [ID]!) {
    updateIntegrations(manuscriptId: $manuscriptId, articles: $articles)
  }
`

const CANCEL_EDIT = gql`
  mutation CancelEdit($manuscriptId: ID!) {
    cancelEdit(manuscriptId: $manuscriptId)
  }
`

const CREATE_SO_REVIEW = gql`
  mutation CreateSOReview($versionId: ID!) {
    createSOReview(versionId: $versionId)
  }
`

const RATE_REVIEW = gql`
  mutation RateReview($reviewId: ID!, $input: SubmitReviewInput!) {
    rateReview(reviewId: $reviewId, input: $input)
  }
`

export {
  CURATOR_PANEL,
  CURATOR_PREVIEW,
  EDITOR_PANEL,
  MANUSCRIPT_SUBMISSION_FORM,
  MANUSCRIPT_STATUS,
  RESTRICTED_PREVIEW,
  REVIEWER_PANEL,
  REVIEWER_PREVIEW,
  FULL_PREVIEW,
  REINVITE_REVIEWER,
  SAVE_CURATOR_REVIEW,
  SAVE_FORM,
  SAVE_REVIEW,
  SCIENCE_OFFICER_PANEL,
  SCIENCE_OFFICER_PREVIEW,
  SEND_CHAT,
  SET_DATA_TYPE,
  SUBMIT_CURATOR_REVIEW,
  SUBMIT_CURATION,
  SUBMIT_DECISION,
  SUBMIT_MANUSCRIPT,
  SUBMIT_REVIEW,
  UPDATE_MANUSCRIPT_HISTORY,
  UPDATE_MANUSCRIPT_METADATA,
  UPDATE_MANUSCRIPT_TEAMS,
  UPLOAD_FILE,
  UPLOAD_PROGRESS,
  ADD_CORRECTION,
  EDIT_CORRECTION,
  CURATION_ANNOTATIONS,
  DELETE_ANNOTATION,
  SAVE_ANNOTATION,
  UPDATE_ENTITIES,
  UPDATE_PAYMENT_STATUS,
  SET_PROTEOPEDIA_READY,
  USER_COAUTHORS,
  SEARCH_FOR_ARTICLE,
  UPDATE_INTREGRATIONS,
  CANCEL_EDIT,
  CREATE_SO_REVIEW,
  RATE_REVIEW,
}
