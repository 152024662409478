/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Loader } from '../common'
import { Section } from '../../../app/components/ui'
import ManuscriptItem from './ManuscriptItem'
import Select from '../../../app/components/ui/Select'

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1024px;
`

const curatorOptions = [
  { label: 'Assessment Requested', value: 'Assessment Requested' },
  { label: 'Assessment Sent', value: 'Assessment Sent' },
  { label: 'Waiting Curation in DB', value: 'Waiting Curation in DB' },
  { label: 'Nothing to Curate', value: 'Nothing to Curate' },
  { label: 'Curated in DB', value: 'Curated in DB' },
]

const PublishedManuscripts = props => {
  const { loading, manuscripts, onClickOpenEntityTable } = props

  if (loading) return <Loader />

  const [filteredArticles, setFilteredArticles] = useState(manuscripts)
  const [selectedCuratorStatus, setSelectedCuratorStatus] = useState(null)

  const handleSelectedCuratorStatus = curatorStatus => {
    setSelectedCuratorStatus(curatorStatus)

    if (curatorStatus) {
      const filteredCuratorArticles = manuscripts.filter(
        a => a.curatorStatus === curatorStatus.value,
      )

      setFilteredArticles(filteredCuratorArticles)
    } else {
      setFilteredArticles(manuscripts)
    }
  }

  const headerActions = [
    <Select
      key="action-filter-by-curator-status"
      onChange={handleSelectedCuratorStatus}
      options={curatorOptions}
      placeholder="Filter By Status"
      value={selectedCuratorStatus}
    />,
  ]

  return (
    <Wrapper>
      <Section
        actions={headerActions}
        component={ManuscriptItem}
        items={filteredArticles}
        label="Published Manuscripts"
        onClickOpenEntityTable={onClickOpenEntityTable}
      />
    </Wrapper>
  )
}

PublishedManuscripts.propTypes = {
  /** Whether the data is still being fetched */
  loading: PropTypes.bool,
  /** The data to display */
  manuscripts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      versions: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string })),
    }),
  ).isRequired,
  onClickOpenEntityTable: PropTypes.func,
}

PublishedManuscripts.defaultProps = {
  loading: false,
  onClickOpenEntityTable: null,
}

export default PublishedManuscripts
