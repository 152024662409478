import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'

import { th } from '@pubsweet/ui-toolkit'

import Checkbox from '../common/Checkbox'
import Label from './Label'

import AdditionalData from './AdditionalData'
import Affiliation from './Affiliation'
import Author from './Author'
import Contribution from './Contribution'
import CorrespondingAuthors from './CorrespondingAuthors'
import DiffArray from './DiffArray'
import ExtendedData from './ExtendedData'
import Editor from './Editor'
import EditorList from './EditorList'
import Image from './Image'
import Separator from './Separator'
import CsvPreview from '../submissionForm/CsvPreview'
// import TextBlock from './TextBlock'
import Funding from './Funding'

import { transformAuthorData } from './_helpers'
import MetadataLabels from './MetadataLabels'

const Wrapper = styled.div`
  margin-bottom: calc(${th('gridUnit')} * 4);
  text-align: justify;

  > div:not(:last-child) {
    margin-bottom: ${th('gridUnit')};
  }
`

const Manuscript = props => {
  const {
    handleSelect,
    isLinker,
    isModal,
    showAdditionalData,
    showDiff: showDiffFromProps,
    version,
    previousVersion,
    categories,
    submissionTypes,
  } = props

  const {
    abstract,
    acknowledgements,
    authors,
    awards,
    comments,
    dataType,
    extendedData,
    funding,
    geneExpression,
    laboratory,
    image,
    imageCaption,
    imageTitle,
    methods,
    patternDescription,
    reagents,
    references,
    suggestedReviewer,
    title,
    csvData,
    csvHeader,
  } = version

  const previousImage = get(previousVersion, 'image')

  const diffOn = showDiffFromProps && !!previousVersion

  const [showDiff, setShowDiff] = useState(false)
  const [showRemoved, setShowRemoved] = useState(true)

  const {
    affiliationData,
    authorData,
    contributionData,
    correspondingAuthorData,
    hasEqualContributionAuthor,
  } = transformAuthorData(authors)

  const hasOneAuthor = authorData.length === 1
  const hasOneAffiliation = affiliationData.length === 1

  const {
    affiliationData: previousAffiliationData,
    authorData: previousAuthorData,
    contributionData: previousContributionData,
    correspondingAuthorData: previousCorrespondingAuthorData,
    hasEqualContributionAuthor: previousHasEqualContributionAuthor,
  } = transformAuthorData(get(previousVersion, 'authors'))

  const imageSource = image && (image.preview || image.url)
  const previousImageSource = previousImage && previousImage.url

  const equalContributionValue = hasEqualContributionAuthor
    ? '<sup>*</sup>These authors contributed equally'
    : ''

  const previousEqualContributionValue = previousHasEqualContributionAuthor
    ? '<sup>*</sup>These authors contributed equally'
    : ''

  const editorVariant = isLinker ? 'linker' : 'caption'
  const linkerVariant = isLinker ? 'linker' : null

  const referencesWithId = references.map(r => {
    const { reference, pubmedId, doi } = r
    let referenceId = ''

    if (pubmedId && pubmedId.trim() !== '') {
      referenceId = `PubMed ID: <a href="https://www.ncbi.nlm.nih.gov/pubmed/${pubmedId}">${pubmedId}</a>`
    } else if (doi && doi.trim() !== '') {
      referenceId = `DOI: <a href="https://doi.org/${doi}">${doi}</a>`
    }

    if (reference.includes('</p>')) {
      return reference.replace('</p>', ` ${referenceId}</p>`)
    }

    return `${reference} ${referenceId}`
  })

  const previousReferencesWithId =
    previousVersion &&
    previousVersion.references.map(r => {
      const { reference, pubmedId, doi } = r
      let referenceId = ''

      if (pubmedId && pubmedId.trim() !== '') {
        referenceId = `PubMed ID: <a href="https://www.ncbi.nlm.nih.gov/pubmed/${pubmedId}">${pubmedId}</a>`
      } else if (doi && doi.trim() !== '') {
        referenceId = `DOI: <a href="https://doi.org/${doi}">${doi}</a>`
      }

      if (reference.includes('</p>')) {
        return reference.replace('</p>', ` ${referenceId}</p>`)
      }

      return `${reference} ${referenceId}`
    })

  return (
    <Wrapper>
      {diffOn && (
        <>
          <Checkbox
            checkBoxText="Show changes"
            checked={showDiff}
            onChange={() => setShowDiff(!showDiff)}
          />

          {showDiff && (
            <Checkbox
              checkBoxText="Show removed"
              checked={showRemoved}
              onChange={() => setShowRemoved(!showRemoved)}
            />
          )}

          <Separator />
        </>
      )}

      <Editor
        previousValue={get(previousVersion, 'title')}
        showDiff={showDiff}
        showRemoved={showRemoved}
        value={title}
        variant="title"
      />

      <div>
        <DiffArray
          component={Author}
          currentArray={authorData}
          hasOneAffiliation={hasOneAffiliation}
          previousArray={previousAuthorData}
          showDiff={showDiff}
          showRemoved={showRemoved}
        />
      </div>

      <div>
        <DiffArray
          component={Affiliation}
          currentArray={affiliationData}
          previousArray={previousAffiliationData}
          showDiff={showDiff}
          showRemoved={showRemoved}
        />
      </div>

      <CorrespondingAuthors
        data={correspondingAuthorData}
        hasOneAuthor={hasOneAuthor}
        previousData={previousCorrespondingAuthorData}
        showDiff={showDiff}
        showRemoved={showRemoved}
      />

      <Editor
        previousValue={previousEqualContributionValue}
        showDiff={showDiff}
        showRemoved={showRemoved}
        value={equalContributionValue}
        variant="equalContribution"
      />

      <MetadataLabels metadata={categories} />
      <MetadataLabels metadata={submissionTypes} status="success" />

      <Editor
        label="abstract"
        previousValue={get(previousVersion, 'abstract')}
        showDiff={showDiff}
        showRemoved={showRemoved}
        value={abstract}
        variant="abstract"
      />

      <CsvPreview csv={csvData} header={csvHeader} source={imageSource} />
      {!csvData && (
        <Image
          isModal={isModal}
          name={image.name}
          previousSource={previousImageSource}
          showDiff={showDiff}
          showRemoved={showRemoved}
          source={imageSource}
        />
      )}

      <Editor
        figureTitle={imageTitle}
        handleSelect={handleSelect}
        previousFigureTitle={get(previousVersion, 'imageTitle')}
        previousValue={get(previousVersion, 'imageCaption')}
        showDiff={showDiff}
        showRemoved={showRemoved}
        value={imageCaption}
        variant={csvData ? 'tableCaption' : editorVariant}
      />

      <Editor
        handleSelect={handleSelect}
        label="description"
        previousValue={get(previousVersion, 'patternDescription')}
        showDiff={showDiff}
        showRemoved={showRemoved}
        value={patternDescription}
        variant={linkerVariant}
      />

      <Editor
        handleSelect={handleSelect}
        label="methods"
        previousValue={get(previousVersion, 'methods')}
        showDiff={showDiff}
        showRemoved={showRemoved}
        value={methods}
        variant={linkerVariant}
      />

      <Editor
        handleSelect={handleSelect}
        label="reagents"
        previousValue={get(previousVersion, 'reagents')}
        showDiff={showDiff}
        showRemoved={showRemoved}
        value={reagents}
        variant={linkerVariant}
      />

      <ExtendedData
        previousValues={get(previousVersion, 'extendedData')}
        showDiff={showDiff}
        showRemoved={showRemoved}
        values={extendedData}
      />

      <EditorList
        label="references"
        previousValues={previousReferencesWithId}
        showDiff={showDiff}
        showRemoved={showRemoved}
        values={referencesWithId}
      />

      <Editor
        handleSelect={handleSelect}
        label="acknowledgements"
        previousValue={get(previousVersion, 'acknowledgements')}
        showDiff={showDiff}
        showRemoved={showRemoved}
        value={acknowledgements}
      />

      <Label value="Funding" />
      <Editor
        handleSelect={handleSelect}
        previousValue={get(previousVersion, 'funding')}
        showDiff={showDiff}
        showRemoved={showRemoved}
        value={funding}
      />

      <div>
        <DiffArray
          component={Funding}
          currentArray={awards}
          previousArray={get(previousVersion, 'awards')}
          showDiff={showDiff}
          showRemoved={showRemoved}
        />
      </div>

      <div>
        <Label value="Author Contributions" />

        <DiffArray
          component={Contribution}
          currentArray={contributionData}
          previousArray={previousContributionData}
          showDiff={showDiff}
          showRemoved={showRemoved}
        />
      </div>

      {showAdditionalData && (
        <AdditionalData
          comments={comments}
          dataType={dataType}
          geneExpression={geneExpression}
          laboratory={get(laboratory, 'name')}
          previousDataType={get(previousVersion, 'dataType')}
          previousGeneExpression={get(previousVersion, 'geneExpression')}
          previousLaboratory={get(previousVersion, 'laboratory.name')}
          previousSuggestedReviewer={get(
            previousVersion,
            'suggestedReviewer.name',
          )}
          showDiff={showDiff}
          showRemoved={showRemoved}
          suggestedReviewer={get(suggestedReviewer, 'name')}
        />
      )}
    </Wrapper>
  )
}

Manuscript.propTypes = {
  /** Function to run when text is selected */
  handleSelect: PropTypes.func,
  /** Indicate if the manuscript is disaplayed in the linking tool */
  isLinker: PropTypes.bool,
  /** Indicate if the manuscript is displayed in a modal */
  isModal: PropTypes.bool,
  /** Control showing of "additional data" section */
  showAdditionalData: PropTypes.bool,
  /** You can hardcode showing / not showing diffs with this */
  showDiff: PropTypes.bool,
  /** Article categories */
  categories: PropTypes.arrayOf(PropTypes.string),
  submissionTypes: PropTypes.arrayOf(PropTypes.string),
  /** Manuscript version */
  version: PropTypes.shape({
    abstract: PropTypes.string,
    acknowledgements: PropTypes.string,
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        correspondingAuthor: PropTypes.bool,
        email: PropTypes.string,
      }),
    ),
    awards: PropTypes.arrayOf(
      PropTypes.shape({
        funderName: PropTypes.string,
        awardId: PropTypes.string,
        awardRecipient: PropTypes.string,
      }),
    ),
    comments: PropTypes.string,
    dataType: PropTypes.string,
    extendedData: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
        description: PropTypes.string,
        resourceType: PropTypes.string,
      }),
    ),
    funding: PropTypes.string,
    geneExpression: PropTypes.shape({
      antibodyUsed: PropTypes.string,
      backboneVector: PropTypes.shape({
        name: PropTypes.string,
      }),
      coinjected: PropTypes.string,
      constructComments: PropTypes.string,
      detectionMethod: PropTypes.string,
      dnaSequence: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        }),
      ),
      expressionPattern: PropTypes.shape({
        name: PropTypes.string,
      }),
      fusionType: PropTypes.shape({
        name: PropTypes.string,
      }),
      genotype: PropTypes.string,
      injectionConcentration: PropTypes.string,
      inSituDetails: PropTypes.string,
      integratedBy: PropTypes.shape({
        name: PropTypes.string,
      }),
      reporter: PropTypes.shape({
        name: PropTypes.string,
      }),
      species: PropTypes.shape({
        name: PropTypes.string,
      }),
      strain: PropTypes.string,
      transgeneName: PropTypes.string,
      transgeneUsed: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        }),
      ),
      utr: PropTypes.shape({
        name: PropTypes.string,
      }),
      variation: PropTypes.shape({
        name: PropTypes.string,
      }),
      observeExpression: PropTypes.shape({
        certainly: PropTypes.arrayOf(
          PropTypes.shape({
            certainly: PropTypes.shape({
              name: PropTypes.string,
            }),
            during: PropTypes.shape({
              name: PropTypes.string,
            }),
            subcellularLocalization: PropTypes.shape({
              name: PropTypes.string,
            }),
          }),
        ),
        possibly: PropTypes.arrayOf(
          PropTypes.shape({
            possibly: PropTypes.shape({
              name: PropTypes.string,
            }),
            during: PropTypes.shape({
              name: PropTypes.string,
            }),
            subcellularLocalization: PropTypes.shape({
              name: PropTypes.string,
            }),
          }),
        ),
        probably: PropTypes.arrayOf(
          PropTypes.shape({
            probably: PropTypes.shape({
              name: PropTypes.string,
            }),
            during: PropTypes.shape({
              name: PropTypes.string,
            }),
            subcellularLocalization: PropTypes.shape({
              name: PropTypes.string,
            }),
          }),
        ),
        not: PropTypes.arrayOf(
          PropTypes.shape({
            not: PropTypes.shape({
              name: PropTypes.string,
            }),
            during: PropTypes.shape({
              name: PropTypes.string,
            }),
            subcellularLocalization: PropTypes.shape({
              name: PropTypes.string,
            }),
          }),
        ),
      }),
    }),
    image: PropTypes.shape({
      name: PropTypes.string,
      preview: PropTypes.string,
      url: PropTypes.string,
    }),
    imageTitle: PropTypes.string,
    imageCaption: PropTypes.string,
    laboratory: PropTypes.shape({
      name: PropTypes.string,
    }),
    methods: PropTypes.string,
    patternDescription: PropTypes.string,
    reagents: PropTypes.string,
    references: PropTypes.arrayOf(
      PropTypes.shape({
        reference: PropTypes.string,
      }),
    ),
    suggestedReviewer: PropTypes.shape({
      name: PropTypes.string,
    }),
    title: PropTypes.string,
    csvData: PropTypes.arrayOf(PropTypes.object),
    csvHeader: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  /** Same as version. For diffs. If existent and showDiff is true (it is by default), diffs will be triggered. */
  previousVersion: PropTypes.shape({
    abstract: PropTypes.string,
    acknowledgements: PropTypes.string,
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        correspondingAuthor: PropTypes.bool,
        email: PropTypes.string,
      }),
    ),
    awards: PropTypes.arrayOf(
      PropTypes.shape({
        funderName: PropTypes.string,
        awardId: PropTypes.string,
        awardRecipient: PropTypes.string,
      }),
    ),
    comments: PropTypes.string,
    dataType: PropTypes.string,
    funding: PropTypes.string,
    geneExpression: PropTypes.shape({
      antibodyUsed: PropTypes.string,
      backboneVector: PropTypes.shape({
        name: PropTypes.string,
      }),
      coinjected: PropTypes.string,
      constructComments: PropTypes.string,
      detectionMethod: PropTypes.string,
      dnaSequence: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        }),
      ),
      expressionPattern: PropTypes.shape({
        name: PropTypes.string,
      }),
      fusionType: PropTypes.shape({
        name: PropTypes.string,
      }),
      genotype: PropTypes.string,
      injectionConcentration: PropTypes.string,
      inSituDetails: PropTypes.string,
      integratedBy: PropTypes.shape({
        name: PropTypes.string,
      }),
      reporter: PropTypes.shape({
        name: PropTypes.string,
      }),
      species: PropTypes.shape({
        name: PropTypes.string,
      }),
      strain: PropTypes.string,
      transgeneName: PropTypes.string,
      transgeneUsed: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        }),
      ),
      utr: PropTypes.shape({
        name: PropTypes.string,
      }),
      variation: PropTypes.shape({
        name: PropTypes.string,
      }),
      observeExpression: PropTypes.shape({
        certainly: PropTypes.arrayOf(
          PropTypes.shape({
            certainly: PropTypes.shape({
              name: PropTypes.string,
            }),
            during: PropTypes.shape({
              name: PropTypes.string,
            }),
            subcellularLocalization: PropTypes.shape({
              name: PropTypes.string,
            }),
          }),
        ),
        possibly: PropTypes.arrayOf(
          PropTypes.shape({
            possibly: PropTypes.shape({
              name: PropTypes.string,
            }),
            during: PropTypes.shape({
              name: PropTypes.string,
            }),
            subcellularLocalization: PropTypes.shape({
              name: PropTypes.string,
            }),
          }),
        ),
        probably: PropTypes.arrayOf(
          PropTypes.shape({
            probably: PropTypes.shape({
              name: PropTypes.string,
            }),
            during: PropTypes.shape({
              name: PropTypes.string,
            }),
            subcellularLocalization: PropTypes.shape({
              name: PropTypes.string,
            }),
          }),
        ),
        not: PropTypes.arrayOf(
          PropTypes.shape({
            not: PropTypes.shape({
              name: PropTypes.string,
            }),
            during: PropTypes.shape({
              name: PropTypes.string,
            }),
            subcellularLocalization: PropTypes.shape({
              name: PropTypes.string,
            }),
          }),
        ),
      }),
    }),
    image: PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    }),
    imageCaption: PropTypes.string,
    imageTitle: PropTypes.string,
    laboratory: PropTypes.shape({
      name: PropTypes.string,
    }),
    methods: PropTypes.string,
    patternDescription: PropTypes.string,
    reagents: PropTypes.string,
    references: PropTypes.arrayOf(
      PropTypes.shape({
        reference: PropTypes.string,
      }),
    ),
    suggestedReviewer: PropTypes.shape({
      name: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
}

Manuscript.defaultProps = {
  handleSelect: null,
  isLinker: false,
  isModal: false,
  previousVersion: null,
  showAdditionalData: false,
  showDiff: true,
  categories: null,
  submissionTypes: null,
}

export default Manuscript
