import React from 'react'
import { useQuery } from '@apollo/client'

import { serverUrl } from '@coko/client'

import { BILLING } from '../graphql'
import { Billing } from '../../ui'

const pdfUrl = process.env.CLIENT_PDF_URL

const transformBilling = articles => {
  const { id: articleId, history, versions } = articles

  const dueDate = new Date(history.accepted)
  dueDate.setDate(dueDate.getDate() + 30)

  const version = versions[versions.length - 1]
  const { title, authors } = version
  const submittingAuthor = authors.find(a => a.submittingAuthor)

  const correspondingAuthor = authors.find(
    a => !a.submittingAuthor && a.correspondingAuthor,
  )

  const invoiceLink = `${serverUrl}/api/export/${articleId}/invoice`
  const invoice = `${pdfUrl}${invoiceLink}`

  return {
    articleId,
    dueDate: dueDate.toDateString(),
    title,
    submittingAuthor,
    correspondingAuthor,
    invoice,
  }
}

const BillingPage = () => {
  const { data, loading } = useQuery(BILLING)

  const billing = data && data.manuscripts

  const transformedBilling = []
  const transformedSeaBilling = []
  const transformedWaived = []

  if (billing)
    billing.forEach(b => {
      if (b.paymentStatus === 'unpaid') {
        transformedBilling.push(transformBilling(b))
      } else if (b.paymentStatus === 'waived') {
        transformedWaived.push(transformBilling(b))
      } else if (b.paymentStatus === 'seaUnpaid') {
        transformedSeaBilling.push(transformBilling(b))
      }
    })

  return (
    <Billing
      billing={transformedBilling}
      loading={loading}
      seaBilling={transformedSeaBilling}
      waived={transformedWaived}
    />
  )
}

export default BillingPage
