/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import * as yup from 'yup'

import { Button, Form, TextField, Select } from '../common'
import { grid, th } from '../_helpers'

const Wrapper = styled.div`
  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
    `}
`

const FormLabel = styled.div`
  color: ${th('colorPrimary')};
  margin-bottom: ${grid(2)};
  text-transform: uppercase;
`

const FormWarning = styled.div`
  color: ${th('colorError')};
  margin-bottom: ${grid(2)};
  text-transform: uppercase;
`

const Label = styled.label`
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  padding: 10px 10px 0 0;
`

const StyledSelect = styled(Select)`
  line-height: ${th('lineHeightBase')};
  margin-bottom: calc(${th('gridUnit')} * 2);
  width: calc(${th('gridUnit')} * 50);
`

const initialValues = {
  email: '',
  givenNames: '',
  surname: '',
  species: [],
  specialty: [],
  researchArea: [],
}

const validations = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email is required')
    .email('Invalid email address'),
  givenNames: yup.string().trim().required('Given names are required'),
  surname: yup.string().trim().required('Surname is required'),
})

const InviteReviewer = props => {
  const {
    className,
    disabled,
    sendInvitation,
    searchSpecies,
    researchAreas,
    specialties,
  } = props

  const handleSubmit = (formValues, formikBag) => {
    const {
      email,
      givenNames,
      surname,
      species,
      researchArea,
      specialty,
    } = formValues

    const input = {
      email: email.trim(),
      givenNames: givenNames.trim(),
      surname: surname.trim(),
      species,
      researchArea,
      specialty,
    }

    sendInvitation(input).then(() => formikBag.resetForm())
  }

  return (
    <Wrapper className={className} disabled={disabled}>
      <FormLabel>Invite a reviewer that is not a user yet</FormLabel>

      {disabled && <FormWarning>No available invitation slots</FormWarning>}

      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validations}
      >
        {formProps => {
          const { errors, values, setFieldValue, ...rest } = formProps

          const handleSelectResearchArea = value => {
            setFieldValue('researchArea', value && value.map(v => v.value))
          }

          const handleSelectSpecialty = value => {
            setFieldValue('specialty', value && value.map(v => v.value))
          }

          const handleSelectSpecies = value => {
            setFieldValue('species', value && value.map(v => v.value))
          }

          return (
            <>
              <TextField
                error={errors.givenNames}
                label="Given names"
                name="givenNames"
                required
                value={values.givenNames}
                {...rest}
              />
              <TextField
                error={errors.surname}
                label="Surname"
                name="surname"
                required
                value={values.surname}
                {...rest}
              />
              <TextField
                error={errors.email}
                label="Email"
                name="email"
                required
                value={values.email}
                {...rest}
              />
              <Label>Species</Label>
              <StyledSelect
                async
                isClearable
                isMulti
                loadOptions={searchSpecies}
                name="species"
                onChange={handleSelectSpecies}
                placeholder="Species"
                value={values.species.map(s => ({ value: s, label: s }))}
              />
              <Label>Research Area</Label>
              <StyledSelect
                isClearable
                isMulti
                onChange={handleSelectResearchArea}
                options={researchAreas}
                placeholder="Research Area"
                value={researchAreas.filter(
                  option =>
                    values.researchArea &&
                    values.researchArea.includes(option.value),
                )}
              />
              <Label>Specialty</Label>
              <StyledSelect
                isClearable
                isMulti
                onChange={handleSelectSpecialty}
                options={specialties}
                placeholder="Specialty"
                value={specialties.filter(
                  option =>
                    values.specialty && values.specialty.includes(option.value),
                )}
              />
              <Button primary type="submit">
                Add to List
              </Button>
            </>
          )
        }}
      </Form>
    </Wrapper>
  )
}

InviteReviewer.propTypes = {
  /** Controls whether form is disabled */
  disabled: PropTypes.bool,
  /** Function to run on submit */
  sendInvitation: PropTypes.func.isRequired,
  searchSpecies: PropTypes.func,
  researchAreas: PropTypes.arrayOf(PropTypes.string),
  specialties: PropTypes.arrayOf(PropTypes.string),
}

InviteReviewer.defaultProps = {
  disabled: false,
  searchSpecies: null,
  researchAreas: [],
  specialties: [],
}

export default InviteReviewer
