/* eslint-disable react/jsx-props-no-spreading */

import React from 'react'

import InfoModal from '../modals/InfoModal'

const BarModal = props => (
  <InfoModal
    headerText="Bar of a microPublication"
    justifyText
    size="medium"
    {...props}
  >
    <div>
      <p>microPublication Biology has these article types:</p>
      <p>
        New finding/Novel Discoveries (observational or
        interventional/mechanistic)
        <br />
        Null/negative result
        <br />
        Replication - successful
        <br />
        Replication - unsuccessful
        <br />
        Materials and Reagents
        <br />
        Methodology
        <br />
        Data updates
      </p>
      <p>
        Acceptance of a manuscript is independent of the perceived impact.
        <br />
        Submissions to microPublication Biology should have the equivalent
        scientific rigor and the clear and scholarly written text as that of
        articles in other journals (Current Biology, PLoS Biology, Genetics,
        Development, Evolution, MBoC, a few more) but within the context of our
        publishing model - a single figure and brief text.
        <br />
        Submissions that do not meet this bar may be returned to the authors
        without peer review or rejected following peer review.
      </p>
      <p>
        During the submission process, authors will be asked to indicate one or
        more of the categories above. The submission text should be consistent
        with and refer to the category of article.
      </p>
      <p>
        Articles with undergraduate authors must meet the same peer review
        standards as any other article in microPublication Biology. The faculty
        mentor has an essential role in the process, including design and
        execution of the study, adherence to scientific rigor, appropriate
        statistical analysis (if relevant), presentation of the data, and clear
        scholarly written text. The faculty mentor must either be the senior
        author on the manuscript or provide a written letter attesting that the
        manuscript meets the standard described here.
      </p>
    </div>
  </InfoModal>
)

export default BarModal
