import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Loader } from '../common'
import { Section } from '../../../app/components/ui'
import BillingItem from './BillingItem'

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1024px;
`

const Billing = props => {
  const { loading, billing, seaBilling, waived, before350 } = props

  if (loading) return <Loader />

  return (
    <Wrapper>
      <Section component={BillingItem} items={billing} label="Payment Due" />
      <Section
        component={BillingItem}
        items={before350}
        label="Payment Due ($250)"
      />
      <Section
        component={BillingItem}
        items={seaBilling}
        label="SEA Payment Due"
      />
      <Section component={BillingItem} items={waived} label="Payment Waived" />
    </Wrapper>
  )
}

Billing.propTypes = {
  /** Whether the data is still being fetched */
  loading: PropTypes.bool,
  /** The data to display */
  billing: PropTypes.arrayOf(
    PropTypes.shape({
      publishedDate: PropTypes.string,
      title: PropTypes.string,
      submittingAuthor: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
      }),
      correspondingAuthor: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
      }),
    }),
  ).isRequired,
  seaBilling: PropTypes.arrayOf(
    PropTypes.shape({
      publishedDate: PropTypes.string,
      title: PropTypes.string,
      submittingAuthor: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
      }),
      correspondingAuthor: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
      }),
    }),
  ).isRequired,
  waived: PropTypes.arrayOf(
    PropTypes.shape({
      publishedDate: PropTypes.string,
      title: PropTypes.string,
      submittingAuthor: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
      }),
      correspondingAuthor: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
      }),
    }),
  ).isRequired,
  before350: PropTypes.arrayOf(
    PropTypes.shape({
      publishedDate: PropTypes.string,
      title: PropTypes.string,
      submittingAuthor: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
      }),
      correspondingAuthor: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
      }),
    }),
  ).isRequired,
}

Billing.defaultProps = {
  loading: false,
}

export default Billing
