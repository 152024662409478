/* eslint-disable react/jsx-props-no-spreading */

import React from 'react'

import InfoModal from '../modals/InfoModal'

const BarModal = props => (
  <InfoModal
    headerText="Bar of a microPublication"
    justifyText
    size="medium"
    {...props}
  >
    <div>
      <em>microPublication Biology</em> publishes these Article types:
      <br />
      <ul>
        <li>New finding – novel discoveries, new reagents and/or methods</li>
        <li>Null/negative result</li>
        <li>Replication - successful (including being scooped)</li>
        <li>Replication - unsuccessful</li>
        <li>Materials and Reagents</li>
        <li>Methodology</li>
        <li>Data updates</li>
      </ul>
      <p>
        Acceptance of a manuscript is independent of the perceived impact.
        <br />
        Submissions to <em>microPublication Biology</em> will have the
        equivalent scientific rigor and clear, scholarly written text as that of
        articles in other well-respected, peer-reviewed journals (Current
        Biology, Development, Evolution, Genetics, MBoC, Molecular Ecology, PLoS
        Biology, and others) but within the context of our publishing model - a
        single figure and short format text.
        <br />
        Submissions that do not meet this standard will be returned to the
        authors without peer review or rejected following review.
      </p>
      <p>
        During the submission process, authors will be asked to indicate one or
        more of the seven Article types above. The submission text will be
        consistent with the Article type.
      </p>
      <p>
        Articles with undergraduate authors must meet the same peer review
        standards as any other article in <em>microPublication Biology</em>. The
        faculty mentor has an essential role in the process, including design
        and execution of the study, adherence to scientific rigor, appropriate
        statistical analysis (if relevant), presentation of the data, and clear
        scholarly written text. The faculty mentor must either be the senior
        author on the manuscript or provide a written letter attesting that the
        manuscript meets the standard described here.
      </p>
    </div>
  </InfoModal>
)

export default BarModal
