/* eslint-disable react/prop-types */

import React, { useState } from 'react'
import styled from 'styled-components'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import mergeWith from 'lodash/mergeWith'
import omit from 'lodash/omit'
import lodashValues from 'lodash/values'
import * as yup from 'yup'
import { useHistory } from 'react-router-dom'

import { th } from '@pubsweet/ui-toolkit'
import { Button, Icon } from '@pubsweet/ui'
import { uuid } from '@coko/client'

import { stripHTML } from './formElements/helpers'
import { ArticlePreviewModal, PageHeader as Header } from './ui'
import SubmissionConfirmationModal from '../../ui/src/modals/SubmissionConfirmationModal'
import EditLockModal from '../../ui/src/modals/EditLockModal'
import MetadataDescriptions from '../../ui/src/modals/MetadataDescriptions'
import InitialSubmission from './formElements/InitialSubmission'
import Form from './form/Form'
import FundingModal from '../../ui/src/modals/FundingModal'
import { exportManuscriptToPDF } from '../fetch/exportManuscript'

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1024px;
`

const PageHeader = styled(Header)`
  margin-top: 0;
`

const InfoError = styled.div`
  color: ${th('colorError')};
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  margin-top: ${th('gridUnit')};
`

const Info = styled.div`
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  margin-top: ${th('gridUnit')};
`

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ButtonColumns = styled(Columns)`
  align-items: center;
`

const Rows = styled.div`
  display: flex;
  flex-direction: column;
  width: 10%;
`

const StyledButton = styled(Button)`
  margin: ${th('gridUnit')};
  padding: 0;
`

const BioentitiesButton = styled(StyledButton)`
  background-color: ${th('colorWarning')};
`

const HelpButton = styled.div`
  cursor: pointer;

  svg {
    height: calc(${th('gridUnit')} * 2);
    stroke: ${th('colorFurniture')};
  }
`

// const options = {
//   dataType: [
//     {
//       label: 'Gene expression results',
//       value: 'geneExpression',
//     },
//     {
//       label: 'No Datatype',
//       value: 'noDatatype',
//     },
//   ],
// }

// const SubmissionFormOne = props => {
//   const {
//     datatypeSelected,
//     errors,
//     full,
//     handleSubmit,
//     initial,
//     isAuthor,
//     isGlobal,
//     isValid,
//     readOnly,
//     revisionState,
//     submitCount,
//     values,
//   } = props

//   return (
//     <>
//       {(fullSubmissionState || revisionState) &&
//         values.dataType === 'geneExpression' &&
//         isAuthor && <GeneExpressionForm readOnly={readOnly} {...props} />}

//       {(dataTypeSelectionState || !isValid) && (
//         <Button data-test-id="submit-button" primary type="submit">
//           Submit
//         </Button>
//       )}
//     </>
//   )
// }

class AutoSave extends React.Component {
  // state = {
  //   isSaving: false,
  //   lastSaved: null,
  //   saveError: null,
  // }

  save = debounce(() => {
    const { onSave, values } = this.props

    const toOmit = ['dataType', 'decisionLetter', 'status']

    if (!(values.image && values.image.name && values.image.url)) {
      toOmit.push('image')
    }

    // const vals = omit(formValuesToData(values), toOmit)
    const vals = omit(values, toOmit)

    Promise.resolve(onSave(vals))
      .then(a => {
        // this.setState({
        //   isSaving: false,
        //   lastSaved: new Date(),
        // })
      })
      .catch(e => {
        // this.setState({
        //   isSaving: false,
        //   saveError: e,
        // })
      })
  }, 500)

  /* eslint-disable-next-line camelcase */
  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const { values } = this.props

    if (!isEqual(nextProps.values, values)) {
      // Make sure updates are not sent when the datatype is changed by the editor
      if (isEqual(nextProps.values.dataType, values.dataType)) {
        this.save()
      }
    }
  }

  render() {
    // return this.props.render(this.state)
    return null
  }
}

const defaultValues = {
  abstract: '',
  acknowledgements: '',
  authors: [],
  awards: [],
  comments: '',
  disclaimer: false,
  apcDisclaimer: false,
  proteopediaOptIn: false,
  extendedData: [],
  funding: '',
  /*
  geneExpression: {
    antibodyUsed: '',
    backboneVector: {
      name: '',
      WBId: '',
    },
    coinjected: '',
    constructComments: '',
    constructionDetails: '',
    detectionMethod: '',
    dnaSequence: [
      {
        id: uuid(),
        name: '',
        WBId: '',
      },
    ],
    expressionPattern: {
      name: '',
      WBId: '',
    },
    fusionType: {
      name: '',
      WBId: '',
    },
    genotype: '',
    injectionConcentration: '',
    inSituDetails: '',
    integratedBy: {
      name: '',
      WBId: '',
    },
    observeExpression: {
      certainly: [
        {
          certainly: {
            id: uuid(),
            name: '',
            WBId: '',
          },
          during: {
            id: uuid(),
            name: '',
            WBId: '',
          },
          id: uuid(),
          subcellularLocalization: {
            id: uuid(),
            name: '',
            WBId: '',
          },
        },
      ],
      not: [
        {
          during: {
            id: uuid(),
            name: '',
            WBId: '',
          },
          id: uuid(),
          not: {
            id: uuid(),
            name: '',
            WBId: '',
          },
          subcellularLocalization: {
            id: uuid(),
            name: '',
            WBId: '',
          },
        },
      ],
      partially: [
        {
          during: {
            id: uuid(),
            name: '',
            WBId: '',
          },
          id: uuid(),
          partially: {
            id: uuid(),
            name: '',
            WBId: '',
          },
          subcellularLocalization: {
            id: uuid(),
            name: '',
            WBId: '',
          },
        },
      ],
      possibly: [
        {
          during: {
            id: uuid(),
            name: '',
            WBId: '',
          },
          id: uuid(),
          possibly: {
            id: uuid(),
            name: '',
            WBId: '',
          },
          subcellularLocalization: {
            id: uuid(),
            name: '',
            WBId: '',
          },
        },
      ],
    },
    reporter: {
      name: '',
      WBId: '',
    },
    species: {
      name: '',
      WBId: '',
    },
    strain: '',
    transgeneName: '',
    transgeneUsed: [
      {
        id: uuid(),
        name: '',
        WBId: '',
      },
    ],
    utr: {
      name: '',
      WBId: '',
    },
    variation: {
      name: '',
      WBId: '',
    },
  },
  */
  image: {},
  imageCaption: '<p></p>',
  imageTitle: '',
  laboratory: {
    name: '',
    WBId: '',
  },
  methods: '<p></p>',
  patternDescription: '<p></p>',
  reagents: '<p></p>',
  references: [],
  suggestedReviewer: {
    name: '',
    WBId: '',
  },
  title: '',
  categories: [],
  species: [],
  submissionTypes: [],
}

const buildInitialValues = data => {
  const defaultVals = cloneDeep(defaultValues)
  const incomingVals = cloneDeep(data)

  return mergeWith(defaultVals, incomingVals, (defaultValue, incomingValue) => {
    if (Array.isArray(defaultValue)) {
      return incomingValue === null ? defaultValue : lodashValues(incomingValue)
    }

    return incomingValue === null ? defaultValue : incomingValue
  })
}

const isArrayEmpty = arr =>
  !arr || arr.length === 0 || (arr.length === 1 && arr[0] === '')

const isStringFieldEmpty = str => !str

// eslint-disable-next-line func-names, prefer-arrow-callback, object-shorthand
/*
const validateWBExists = function (val) {
  if (!val) return true

  const { WBId } = this.parent
  if (!WBId || !WBId.length) return false

  return true
}
*/

const initialValidations = {
  abstract: yup.string().required('Abstract is required'),
  acknowledgements: yup.string(),
  authors: yup
    .array(
      yup.object().shape({
        affiliations: yup
          .array()
          .compact()
          .of(yup.string())
          .test(
            'author affiliations pass if all fields are empty',
            'Affiliations are required for all authors',
            // eslint-disable-next-line func-names, prefer-arrow-callback
            function (val) {
              const { credit, lastName, email } = this.parent
              if (
                isArrayEmpty(credit) &&
                isStringFieldEmpty(lastName) &&
                isStringFieldEmpty(email)
              )
                return true
              if (isArrayEmpty(val)) return false
              return true
            },
          ),
        credit: yup
          .array()
          .of(yup.string())
          .test(
            'author has credit',
            'Credit is required for all authors',
            // eslint-disable-next-line func-names, prefer-arrow-callback
            function (val) {
              const { affiliations, lastName, email } = this.parent

              if (
                isArrayEmpty(affiliations) &&
                isStringFieldEmpty(lastName) &&
                isStringFieldEmpty(email)
              ) {
                return true
              }

              if (isArrayEmpty(val)) return false

              return true
            },
          )
          .nullable(),
        departments: yup.array().of(yup.string().nullable()),
        email: yup
          .string()
          .trim()
          .email('Invalid email format')
          .test(
            'email pass if all fields empty',
            'Email is required for all authors',
            // eslint-disable-next-line func-names, prefer-arrow-callback
            function (val) {
              if (val && val.length > 0) return true

              const { affiliations, credit, lastName } = this.parent

              if (
                isArrayEmpty(affiliations) &&
                isArrayEmpty(credit) &&
                isStringFieldEmpty(lastName)
              ) {
                return true
              }

              return false
            },
          )
          .nullable(),
        firstName: yup.string().test(
          'author pass if all fields empty',
          'First name is required for all authors',
          // eslint-disable-next-line func-names, prefer-arrow-callback
          function (val) {
            if (val && val.length > 0) return true

            const { affiliations, credit, email } = this.parent

            if (
              isArrayEmpty(affiliations) &&
              isArrayEmpty(credit) &&
              isStringFieldEmpty(email)
            ) {
              return true
            }

            return false
          },
        ),
        lastName: yup.string().test(
          'author pass if all fields empty',
          'Last name is required for all authors',
          // eslint-disable-next-line func-names, prefer-arrow-callback
          function (val) {
            if (val && val.length > 0) return true

            const { affiliations, credit, email } = this.parent

            if (
              isArrayEmpty(affiliations) &&
              isArrayEmpty(credit) &&
              isStringFieldEmpty(email)
            ) {
              return true
            }

            return false
          },
        ),
        orcid: yup.string().nullable(),
        // .test(
        //   'is co-author valid',
        //   'Must be a registered WormBase Person',
        //   validateWBExists,
        // )
        academicStatus: yup
          .string()
          .test(
            'author pass if all fields empty',
            'Academic status is required for all authors',
            // eslint-disable-next-line func-names, prefer-arrow-callback
            function (val) {
              //  if (val && val.length > 0) return true

              const { affiliations, credit, email } = this.parent

              if (
                isArrayEmpty(affiliations) &&
                isArrayEmpty(credit) &&
                isStringFieldEmpty(email)
              ) {
                return true
              }

              return val
            },
          )
          .nullable(),
      }),
    )
    .test(
      'has one corresponding author',
      'At least one author must be set as corresponding',
      val => {
        const corresponding = []
        val.forEach(author => {
          if (author.correspondingAuthor) {
            corresponding.push(author)
          }
        })
        return corresponding.length > 0
      },
    )
    .test(
      'has one submitting author',
      'One author must be set as submitting',
      val => {
        const submitting = []
        val.forEach(author => {
          if (author.submittingAuthor) {
            submitting.push(author)
          }
        })
        return submitting.length === 1
      },
    ),
  comments: yup.string(),
  disclaimer: yup.boolean().test('disclaimer', 'Required', val => val),
  apcDisclaimer: yup.boolean().test('apcDisclaimer', 'Required', val => val),
  proteopediaOptIn: yup.boolean(),
  extendedData: yup.array().of(
    yup.object().shape({
      description: yup
        .string()
        .test(
          'data file has description',
          'File description is required',
          val => val && val !== '',
        ),
      name: yup.string(),
      resourceType: yup
        .string()
        .test(
          'data file has resource type',
          'Resource type is required',
          val => val && val !== '',
        ),
      url: yup.string(),
    }),
  ),
  awards: yup.array().of(
    yup.object().shape({
      funderId: yup.string(),
      funderName: yup.string(),
      awardId: yup.string(),
      awardRecipient: yup.string(),
    }),
  ),
  funding: yup
    .string() // .required('Funding is required'),
    .test(
      'funding statement',
      'Funding statement or award is required',
      // eslint-disable-next-line func-names
      function (val) {
        if (val && val !== '' && val !== '<p></p>') return true

        const { awards } = this.parent
        if (awards && awards.length > 0 && awards[0].funderName !== '')
          return true
        return false
      },
    ),
  image: yup.object().shape({
    url: yup.string().required('Image is required'),
  }),
  imageCaption: yup
    .string()
    .test('image-caption-not-empty', 'Image caption is required', val => {
      if (!val) return false
      return stripHTML(val).length > 0
    })
    .test(
      'caption-data-not-shown',
      "Figure caption cannot contain 'data not shown' or 'unpublished observations'",
      val =>
        !val.toLowerCase().includes('not shown') &&
        !val.toLowerCase().includes('unpublished observation'),
    )
    .test(
      'caption-supplemental-material',
      "microPublication discourages the inclusion of supplemental material. We allow authors to include 'Extended data' files such as software, movies, GFF, FASTA, among others. If you have such files please refer to those as 'Extended Data' files.",
      val => !val.toLowerCase().includes('supplemental material'),
    ),
  imageTitle: yup
    .string()
    .test('image-title-figure1', 'Image title cannot contain Figure 1', val => {
      if (!val) return true
      return !val.includes('Figure 1')
    }),
  laboratory: yup.object().shape({
    name: yup.string(),
    // .test(
    //   'is-lab-valid',
    //   'Must a registered WormBase Laboratory',
    //   validateWBExists,
    // )
    WBId: yup.string(),
  }),
  methods: yup
    .string()
    .test(
      'methods-data-not-shown',
      "Methods cannot contain 'data not shown' or 'unpublished observations'",
      val =>
        !val.toLowerCase().includes('not shown') &&
        !val.toLowerCase().includes('unpublished observation'),
    )
    .test(
      'methods-supplemental-material',
      "microPublication discourages the inclusion of supplemental material. We allow authors to include 'Extended data' files such as software, movies, GFF, FASTA, among others. If you have such files please refer to those as 'Extended Data' files.",
      val => !val.toLowerCase().includes('supplemental material'),
    )
    .test(
      'et-al-italics',
      "'et al.' should not be italicized",
      val =>
        !val.toLowerCase().includes('<i>et al') &&
        !val.toLowerCase().includes('<em>et al'),
    ),
  // .test(
  // 'methods-not-empty',
  //  'Methods & Reagents is required',
  //  val => stripHTML(val).length > 0,
  // ),
  patternDescription: yup
    .string()
    .test('pattern-description-not-empty', 'Text is required', val => {
      if (!val) return false
      return stripHTML(val).length > 0
    })
    .test(
      'description-data-not-shown',
      "Description cannot contain 'data not shown' or 'unpublished observations'",
      val =>
        !val.toLowerCase().includes('not shown') &&
        !val.toLowerCase().includes('unpublished observation'),
    )
    .test(
      'description-supplemental-material',
      "microPublication discourages the inclusion of supplemental material. We allow authors to include 'Extended data' files such as software, movies, GFF, FASTA, among others. If you have such files please refer to those as 'Extended Data' files.",
      val => !val.toLowerCase().includes('supplemental material'),
    )
    .test(
      'et-al-italics',
      "'et al.' should not be italicized",
      val =>
        !val.toLowerCase().includes('<i>et') &&
        !val.toLowerCase().includes('<em>et'),
    ),
  reagents: yup
    .string()
    .test(
      'reagents-data-not-shown',
      "Reagents cannot contain 'data not shown' or 'unpublished observations'",
      val =>
        !val.toLowerCase().includes('not shown') &&
        !val.toLowerCase().includes('unpublished observation'),
    )
    .test(
      'reagents-supplemental-material',
      "microPublication discourages the inclusion of supplemental material. We allow authors to include 'Extended data' files such as software, movies, GFF, FASTA, among others. If you have such files please refer to those as 'Extended Data' files.",
      val => !val.toLowerCase().includes('supplemental material'),
    )
    .test(
      'et-al-italics',
      "'et al.' should not be italicized",
      val =>
        !val.toLowerCase().includes('<i>et') &&
        !val.toLowerCase().includes('<em>et'),
    ),
  references: yup.array(
    yup
      .object()
      .shape({
        doi: yup.string().nullable(),
        pubmedId: yup.string().nullable(), // 1-8 digit number
        reference: yup.string(),
      })
      .test(
        'reference and either pubmedid or doi',
        'Reference text required',
        val => {
          const { doi, pubmedId, reference } = val

          if (
            isStringFieldEmpty(stripHTML(reference)) &&
            isStringFieldEmpty(doi) &&
            isStringFieldEmpty(pubmedId)
          ) {
            return true
          }

          if (isStringFieldEmpty(stripHTML(reference))) {
            return false
          }

          return true
        },
      )
      .test('pubmedid is number', 'Enter number only', val => {
        if (!val.pubmedId) return true
        return /^\d+$/.test(val.pubmedId)
      })
      .test(
        'multiple references',
        'Enter only one reference per field',
        val => {
          const p = val.reference.split('</p><p>')

          return (
            p.filter(
              ref =>
                ref
                  .replace('<br>', '')
                  .replace('<p>', '')
                  .replace('</p>', '')
                  .trim() !== '',
            ).length === 1
          )
        },
      ),
  ),
  // .test(
  //  'references-not-empty',
  //  'References are required',
  //  val => stripHTML(val).length > 0,
  // ),
  suggestedReviewer: yup.object().shape({
    name: yup.string().required('Please suggest a reviewer'),
    // .test(
    //   'is suggested reviewer valid',
    //   'Must be a registered WormBase Person',
    //   validateWBExists,
    // )
  }),
  title: yup.string().test('title-not-empty', 'Title is required', val => {
    if (!val) return false
    return stripHTML(val).length > 0
  }),
  categories: yup
    .array()
    .of(yup.string())
    .test(
      'categories set',
      'Please select at least one category',
      val => !isArrayEmpty(val),
    ),
  species: yup
    .array()
    .of(yup.string())
    .test(
      'species set',
      'Please select at least one species',
      val => !isArrayEmpty(val),
    ),
  submissionTypes: yup
    .array()
    .of(yup.string())
    .test(
      'submission types set',
      'Please select at least one submission type',
      val => !isArrayEmpty(val),
    ),
  topics: yup.array().of(yup.string()).nullable(),
}

const makeSchema = vals => {
  const schema = cloneDeep(initialValidations)

  return yup.object().shape(schema)
}

const SubmissionForm = props => {
  const {
    categoryOptions,
    cancelEdit,
    coAuthors,
    data,
    manuscriptId,
    saveForm,
    speciesOptions,
    submissionType,
    submissionTypeOptions,
    submitManuscript,
    upload,
    fileProgress,
  } = props

  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const [showCategoryDescriptions, setShowCategoryDescriptions] = useState(
    false,
  )

  const [
    showSubmissionTypeDescriptions,
    setShowSubmissionTypeDescriptions,
  ] = useState(false)

  const [showFundingModal, setShowFundingModal] = useState(false)
  const [currentAward, setCurrentAward] = useState(0)

  const [showEditLockModal, setShowEditLockModal] = useState(
    data.editorEdit && localStorage.getItem('edit') !== manuscriptId,
  )

  const history = useHistory()
  // const [showSuccessModal, setShowSuccessModal] = useState(false)

  //   const initialSubmissionState = !initial
  //   const dataTypeSelectionState = initial && !datatypeSelected
  //   const fullSubmissionState = initial && datatypeSelected && !full

  // const { datatypeSelected, initial } = otherProps
  // const dataTypeSelectionState = initial && !datatypeSelected

  const handleSubmit = (formValues, formikBag) => {
    submitManuscript(formValues).then(() => {
      setShowConfirmationModal(false)
      // setShowSuccessModal(true)
    })
  }

  const initialValues = buildInitialValues(data)
  const validations = makeSchema(initialValues)
  const isInitialValid = validations.isValidSync(initialValues)
  // let addFunder

  const exportManuscript = () => exportManuscriptToPDF(data.id)

  return (
    <Wrapper>
      <Form
        // enableReinitialize // breaks autosave
        initialValues={initialValues}
        isInitialValid={isInitialValid}
        onSubmit={handleSubmit}
        validationSchema={validations}
      >
        {formProps => {
          const {
            errors,
            handleBlur,
            handleChange,
            isValid,
            setErrors,
            setFieldTouched,
            setFieldValue,
            touched,
            submitCount,
            submitForm,
            values,
          } = formProps

          // If not valid, this will increment submitcount and show InfoError
          const handleClickSubmit = () =>
            isValid ? setShowConfirmationModal(true) : submitForm()

          const handleClickLink = () =>
            isValid ? history.push(`/linker/${manuscriptId}`) : submitForm()

          const addFunder = funder => {
            const awards = values.awards || []

            if (typeof funder === 'string') {
              awards.push({
                id: uuid(),
                funderName: funder,
                funderId: '',
                awardId: '',
                awardRecipient: '',
              })
            } else {
              const transformedFunder = {
                id: uuid(),
                funderName: funder.label,
                funderId: funder.value,
                awardId: '',
                awardRecipient: '',
              }

              awards.push(transformedFunder)
            }

            setFieldValue('awards', awards)
            setCurrentAward(awards.length - 1)
          }

          const onClickAddAward = () => {
            setCurrentAward(values.awards.length)
            setShowFundingModal(true)
          }

          const onCloseFundingModal = () => {
            handleChange('awards')
            setShowFundingModal(false)
          }

          return (
            <>
              <PageHeader>Submission form</PageHeader>
              <Columns>
                <InitialSubmission
                  categoryOptions={categoryOptions}
                  coAuthors={coAuthors}
                  errors={errors}
                  fileProgress={fileProgress}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  isInitialSubmission={submissionType === 'initial'}
                  onClickAddAward={onClickAddAward}
                  onClickFunder={addFunder}
                  setCurrentAward={setCurrentAward}
                  setErrors={setErrors}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  setShowFundingModal={setShowFundingModal}
                  showCategoryDescriptions={() =>
                    setShowCategoryDescriptions(true)
                  }
                  showSubmissionTypeDescriptions={() =>
                    setShowSubmissionTypeDescriptions(true)
                  }
                  speciesOptions={speciesOptions}
                  submissionTypeOptions={submissionTypeOptions}
                  touched={touched}
                  upload={upload}
                  values={values}
                />
                <Rows>
                  <StyledButton
                    onClick={() => setShowPreviewModal(true)}
                    outline
                  >
                    Preview
                  </StyledButton>

                  {data.species && data.species.includes('c. elegans') && (
                    <ButtonColumns>
                      <BioentitiesButton
                        disabled={!isValid}
                        onClick={handleClickLink}
                        outline
                      >
                        spell-check your bioentities (BETA)
                      </BioentitiesButton>
                      <HelpButton>
                        <Icon title="Use this tool to validate genes, strains, and other bioentities mentioned in your paper. This functionality is available when the article is ready to submit, after you fill out all required fields.">
                          help_circle
                        </Icon>
                      </HelpButton>
                    </ButtonColumns>
                  )}

                  <StyledButton onClick={handleClickSubmit} primary>
                    Submit
                  </StyledButton>

                  {data.editorEdit && (
                    <Button onClick={cancelEdit}>Cancel Edit</Button>
                  )}
                  <Info>* Required Field</Info>

                  {submitCount > 0 && !isValid && (
                    <InfoError>
                      Please review submission and fill out all required fields.
                    </InfoError>
                  )}
                </Rows>
              </Columns>

              <ArticlePreviewModal
                exportManuscript={exportManuscript}
                isOpen={showPreviewModal}
                onRequestClose={() => setShowPreviewModal(false)}
                values={values}
              />

              <SubmissionConfirmationModal
                // isFullSubmission={fullSubmissionState && !revisionState}
                isInitialSubmission={submissionType === 'initial'}
                isOpen={showConfirmationModal}
                isProofSubmission={submissionType === 'proofs'}
                isRevisionSubmission={submissionType === 'revision'}
                onConfirm={submitForm}
                onRequestClose={() => setShowConfirmationModal(false)}
              />

              <MetadataDescriptions
                headerText="category descriptions"
                isOpen={showCategoryDescriptions}
                metadata={categoryOptions}
                onRequestClose={() => setShowCategoryDescriptions(false)}
              />

              <MetadataDescriptions
                headerText="submission type descriptions"
                isOpen={showSubmissionTypeDescriptions}
                metadata={submissionTypeOptions}
                onRequestClose={() => setShowSubmissionTypeDescriptions(false)}
              />

              <FundingModal
                currentAward={currentAward}
                handleChange={handleChange}
                isOpen={showFundingModal}
                onClickOption={addFunder}
                onConfirm={onCloseFundingModal}
                onRequestClose={onCloseFundingModal}
                placeholder="Search for Funder..."
                setFieldValue={setFieldValue}
                value={values.awards}
              />

              <EditLockModal
                isOpen={showEditLockModal}
                onConfirm={() => setShowEditLockModal(false)}
                onRequestClose={() => history.push('/')}
              />
              {/* <SubmissionSuccessModal
              isOpen={showSuccessModal}
              onRequestClose={() => setShowSuccessModal(false)}
            /> */}

              <AutoSave onSave={saveForm} values={values} />
            </>
          )
        }}
      </Form>
    </Wrapper>
  )
}

export default SubmissionForm
