/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react'

import ArticlePreview from './ArticlePreview'
import Modal from '../../../ui/src/modals/Modal'
import ModalHeader from '../../../ui/src/modals/ModalHeader'

import { formValuesToData } from '../formElements/helpers'

const Header = <ModalHeader text="article preview" />

const ArticlePreviewModal = props => {
  const { article, exportManuscript, values, ...rest } = props
  if (!article && !values) return null

  return (
    <Modal headerComponent={Header} size="large" {...rest}>
      <ArticlePreview
        article={article || formValuesToData(values)}
        // livePreview
        categories={values && values.categories}
        exportManuscript={exportManuscript}
        isModal
        showHeader={false}
        submissioTypes={values && values.submissioTypes}
      />
    </Modal>
  )
}

export default ArticlePreviewModal
