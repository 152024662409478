import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import styled from 'styled-components'

import { Button, Form, TextField, Checkbox, Select, Loader } from '../common'
import ValueList from '../editors/ValueList'
import { th } from '../_helpers'

const StyledSelect = styled(Select)`
  line-height: ${th('lineHeightBase')};
  margin-bottom: calc(${th('gridUnit')} * 2);
  width: calc(${th('gridUnit')} * 50);
`

const Label = styled.label`
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  padding: 10px 10px 0 0;
`

const Error = styled(Label)`
  color: ${th('colorError')};
`

const validations = yup.object().shape({
  username: yup.string(),
  givenNames: yup.string(),
  surname: yup.string().required('Surname cannot be empty'),
  orcid: yup.string(),
})

const Profile = props => {
  const {
    user,
    updateUserData,
    onConfirm,
    searchSpecies,
    specialties,
    researchAreas,
  } = props

  if (!user) return <Loader />

  const {
    admin,
    email,
    givenNames,
    isActive,
    orcid,
    surname,
    username,
    id: userId,
    reviewer,
    notReviewer,
    species,
    researchArea,
    specialty,
    isConfirmed,
  } = user

  const isAdmin = admin ? 'Admin' : 'Not Admin'

  const data = [
    {
      label: 'Username',
      status: 'primary',
      value: username,
    },
    {
      label: 'Given Names',
      status: 'primary',
      value: givenNames,
    },
    {
      label: 'Surname',
      status: 'primary',
      value: surname,
    },
    {
      label: 'Admin',
      status: 'primary',
      value: isAdmin,
    },
    {
      label: 'E-Mail',
      status: 'primary',
      value: email,
    },
    {
      label: 'ORCiD',
      status: 'primary',
      value: orcid,
    },
    {
      label: 'Agreed to be a reviewer?',
      status: 'primary',
      value: reviewer ? 'Yes' : 'No',
    },
    {
      label: 'Do not ask to be a reviewer?',
      status: 'primary',
      value: notReviewer ? 'Yes' : 'No',
    },
    { label: 'Species', status: 'primary', value: species },
    { label: 'Research Area', status: 'primary', value: researchArea },
    { label: 'Specialty', status: 'primary', value: specialty },
  ]

  const [showForm, setShowForm] = useState(false)

  const [currentResearchArea, setCurrentResearchArea] = useState(
    researchAreas.filter(
      option => researchArea && researchArea.includes(option.value),
    ),
  )

  const [currentSpecialty, setCurrentSpecialty] = useState(
    specialties.filter(option => specialty && specialty.includes(option.value)),
  )

  const initialValues = {
    username: username || '',
    givenNames: givenNames || '',
    surname: surname || '',
    orcid: orcid || '',
    isActive: isActive || false,
    reviewer: reviewer || false,
    notReviewer: notReviewer || false,
    species: species || [],
    researchArea: researchArea || [],
    specialty: specialty || [],
    isConfirmed: isConfirmed || false,
  }

  const handleSubmit = (values, formikBag) => {
    updateUserData({
      userId,
      ...values,
      researchArea: currentResearchArea && currentResearchArea.value,
      specialty: currentSpecialty && currentSpecialty.value,
    }).then(() => {
      setShowForm(false)
      onConfirm()
    })
  }

  return (
    <>
      {!showForm && (
        <div>
          <ValueList data={data} />
          <Button onClick={() => setShowForm(true)} primary>
            Edit
          </Button>
        </div>
      )}

      {showForm && (
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validations}
        >
          {formProps => {
            const {
              errors,
              handleBlur,
              handleChange,
              setFieldValue,
              touched,
              values,
            } = formProps

            const handleSelectSpecies = value =>
              setFieldValue('species', value && value.map(v => v.value))

            return (
              <div key={`edit-user-${userId}`}>
                <TextField
                  error={errors.username}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  label="Username"
                  name="username"
                  touched={touched}
                  value={values.username}
                />
                <TextField
                  error={errors.givenNames}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  label="Given Names"
                  name="givenNames"
                  touched={touched}
                  value={values.givenNames}
                />
                <TextField
                  error={errors.surname}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  label="Surname"
                  name="surname"
                  touched={touched}
                  value={values.surname}
                />
                <TextField
                  error={errors.orcid}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  label="ORCiD"
                  name="orcid"
                  touched={touched}
                  value={values.orcid}
                />
                <Checkbox
                  checked={values.isConfirmed}
                  label="Has email been verified?"
                  name="isConfirmed"
                  onChange={handleChange}
                  readOnly={isConfirmed}
                  touched={touched}
                  value={values.isConfirmed}
                />
                <Checkbox
                  checked={values.isActive}
                  label="Is Active?"
                  name="isActive"
                  onChange={handleChange}
                  touched={touched}
                  value={values.isActive}
                />
                <Checkbox
                  checked={values.reviewer}
                  label="Agreed to be a reviewer?"
                  name="reviewer"
                  onChange={handleChange}
                  touched={touched}
                  value={values.reviewer}
                />
                <Checkbox
                  checked={values.notReviewer}
                  label="Do not ask to be a reviewer?"
                  name="notReviewer"
                  onChange={handleChange}
                  touched={touched}
                  value={values.notReviewer}
                />
                <Label>Species</Label>
                <StyledSelect
                  async
                  isClearable
                  isMulti
                  loadOptions={searchSpecies}
                  name="species"
                  onChange={handleSelectSpecies}
                  placeholder="Species"
                  value={values.species.map(s => ({
                    value: s,
                    label: s,
                  }))}
                />
                <Label>Research Area</Label>
                <StyledSelect
                  isClearable
                  isMulti
                  onChange={setCurrentResearchArea}
                  options={researchAreas}
                  placeholder="Research Area"
                  value={currentResearchArea}
                />
                <Label>Specialty</Label>
                <StyledSelect
                  isClearable
                  isMulti
                  onChange={setCurrentSpecialty}
                  options={specialties}
                  placeholder="Specialty"
                  value={currentSpecialty}
                />
                <Button onClick={() => setShowForm(false)}>Cancel</Button>
                <Button primary type="submit">
                  Save
                </Button>
                {errors && Object.keys(errors).length > 0 && (
                  <Error>Error: Please check all required fields</Error>
                )}
              </div>
            )
          }}
        </Form>
      )}
    </>
  )
}

Profile.propTypes = {
  user: PropTypes.objectOf({
    admin: PropTypes.bool,
    email: PropTypes.string,
    givenNames: PropTypes.string,
    isActive: PropTypes.bool,
    orcid: PropTypes.string,
    surname: PropTypes.string,
    username: PropTypes.string,
    id: PropTypes.string,
    reviewer: PropTypes.bool,
    notReviewer: PropTypes.bool,
    species: PropTypes.arrayOf(PropTypes.string),
    researchArea: PropTypes.arrayOf(PropTypes.string),
    specialty: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  updateUserData: PropTypes.func,
  onConfirm: PropTypes.func,
  searchSpecies: PropTypes.func,
  researchAreas: PropTypes.arrayOf(PropTypes.object.isRequired),
  specialties: PropTypes.arrayOf(PropTypes.object.isRequired),
}

Profile.defaultProps = {
  updateUserData: null,
  onConfirm: null,
  searchSpecies: null,
  researchAreas: [],
  specialties: [],
}

export default Profile
