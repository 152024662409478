/* eslint-disable react/prop-types */

import React from 'react'
import { Adopt } from 'react-adopt'
import { withApollo } from '@apollo/client/react/hoc'
import { withRouter } from 'react-router-dom'
import last from 'lodash/last'
import maxBy from 'lodash/maxBy'
import uniq from 'lodash/uniq'
import { useMutation } from '@apollo/client'

import { withCurrentUser } from '../../userContext'
import { UPLOAD_FILE } from '../../graphql'

import { makePayment } from '../../fetch/AuthorizeNetApi'

import {
  // queries
  dashboardManuscripts as dashboardManuscriptsQuery,
  getGlobalTeams as getGlobalTeamsQuery,

  // mutations
  createManuscript,
  deleteArticle,
  handleInvitation,
  updateAssignedEditor,
  updateAssignedScienceOfficer,
  withdrawArticle,
} from './pieces'

/* eslint-disable sort-keys */
const mapper = {
  createManuscript,
  deleteArticle,
  dashboardManuscriptsQuery,
  getGlobalTeamsQuery,
  handleInvitation,
  updateAssignedEditor,
  updateAssignedScienceOfficer,
  withdrawArticle,
}
/* eslint-enable sort-keys */

const getTeamByType = (teams, type) => teams && teams.find(t => t.role === type)

const getCurrentStatus = manuscript => {
  const { versions } = manuscript
  const versionCount = versions.length
  const lastVersion = last(versions)

  if (lastVersion.decision === 'reject') return 'rejected'
  if (lastVersion.decision === 'publish') return 'published'
  if (lastVersion.decision === 'decline') return 'declined'
  if (lastVersion.decision === 'withdraw') return 'withdrawn'
  if (lastVersion.decision === 'curation') return 'curation'
  if (lastVersion.decision === 'retract') return 'retraction'

  if (lastVersion.reviews) {
    const hasSubmittedReview = !!lastVersion.reviews.find(
      r => r.status.submitted,
    )

    if (hasSubmittedReview) return 'review submitted'
  }

  if (lastVersion.teams) {
    const reviewerTeam = lastVersion.teams.find(t => t.role === 'reviewer')

    const hasAcceptedReviewers = !!reviewerTeam.members.find(
      m => m.status === 'acceptedInvitation',
    )

    if (hasAcceptedReviewers) return 'reviewer accepted'

    const hasInvitedReviewers = !!reviewerTeam.members.find(
      m => m.status === 'invited',
    )

    if (hasInvitedReviewers) return 'reviewer invited'
  }

  if (versionCount > 1) {
    const beforeLastVersion = versions[versions.length - 2]

    if (!lastVersion.submitted && beforeLastVersion.decision === 'revise') {
      const hasSubmittedReview = !!beforeLastVersion.reviews.find(
        r => r.status.submitted,
      )

      if (!hasSubmittedReview) return 'editorial re-submission'
      return 'under revision'
    }

    if (lastVersion.submitted && beforeLastVersion.decision === 'revise') {
      if (lastVersion.teams) {
        const reviewerTeam = lastVersion.teams.find(t => t.role === 'reviewer')

        const hasInvitedReviewers = !!reviewerTeam.members.find(
          m => m.status === 'invited',
        )

        if (hasInvitedReviewers) return 'reviewer invited'
      }

      return 'revision submitted'
    }

    if (!lastVersion.submitted && beforeLastVersion.decision === 'accept') {
      return 'accepted to proofs'
    }

    if (lastVersion.submitted && beforeLastVersion.decision === 'accept') {
      return 'proofs submitted'
    }
  }

  if (lastVersion.teams) {
    const reviewerTeam = lastVersion.teams.find(t => t.role === 'reviewer')

    const hasAcceptedReviewers = !!reviewerTeam.members.find(
      m => m.status === 'acceptedInvitation',
    )

    if (hasAcceptedReviewers) return 'reviewer accepted'

    const hasInvitedReviewers = !!reviewerTeam.members.find(
      m => m.status === 'invited',
    )

    if (hasInvitedReviewers) return 'reviewer invited'
  }

  if (lastVersion.isApprovedByScienceOfficer)
    return 'approved by science officer'
  if (lastVersion.isApprovedByScienceOfficer === false)
    return 'not approved by science officer'

  if (lastVersion.submitted) return 'submitted'
  if (manuscript.isDataTypeSelected) return 'datatype selected'
  if (manuscript.isInitiallySubmitted) return 'submitted - needs triage'

  return 'not submitted'
}

const getCuratorStatus = manuscript => {
  const { versions } = manuscript
  let curatorStatus = ''

  const curatorTeam =
    manuscript.teams && manuscript.teams.find(t => t.role === 'curator')

  if (curatorTeam && curatorTeam.members.length > 0) {
    curatorStatus = 'assessment requested'

    const hasCuration = versions.some(
      v =>
        v.curatorReviews &&
        v.curatorReviews.some(r => r.submitted || r.curated),
    )

    if (hasCuration) {
      curatorStatus = 'assessment received'

      versions.forEach(v =>
        v.curatorReviews.forEach(c => {
          if (c.curated) curatorStatus = c.curated
        }),
      )
    }
  }

  return curatorStatus
}

const getCorrectionStatus = manuscript => {
  const { corrections } = manuscript

  const correctionStatuses =
    corrections &&
    uniq(corrections.map(correction => correction.correctionType))

  return correctionStatuses
}

/* eslint-disable-next-line arrow-body-style */
const mapProps = args => {
  const { data, variables } = args.dashboardManuscriptsQuery

  const { editorAssigned } = variables

  const { data: globalTeamsData } = args.getGlobalTeamsQuery

  const globalTeams = globalTeamsData && globalTeamsData.getGlobalTeams

  const makeArticleData = type =>
    data &&
    data[type] &&
    data[type].map(manuscript => {
      let version

      if (type === 'author') {
        version = last(manuscript.versions)
      } else if (
        manuscript.versions.length === 1 &&
        manuscript.isInitiallySubmitted &&
        !last(manuscript.versions).submitted
      ) {
        /* eslint-disable-next-line prefer-destructuring */
        version = manuscript.versions[0]
      } else {
        version = last(manuscript.versions.filter(v => v.submitted))
      }

      let formattedData = {
        displayStatus: getCurrentStatus(manuscript),
        correctionStatus: getCorrectionStatus(manuscript),
        id: manuscript.id,
        status: {},
        title: version.title,
        updated: version.updated,
        paymentStatus: manuscript.paymentStatus,
        versionId: version.id,
      }

      formattedData.status.decision = version.decision
      formattedData.status.submission = {
        datatypeSelected: manuscript.isDataTypeSelected,
        full: version.submitted,
        initial: manuscript.isInitiallySubmitted,
      }
      formattedData.status.scienceOfficer = {
        approved: version.isApprovedByScienceOfficer,
        pending: version.isApprovedByScienceOfficer === null,
      }

      if (type === 'review')
        formattedData.reviewerStatus = manuscript.reviewerStatus

      if (type === 'editor' || type === 'sectionEditor') {
        formattedData.curatorStatus = getCuratorStatus(manuscript)

        const authorTeam = version.teams.find(t => t.role === 'author')
        const authorIds = authorTeam.members.map(m => m.user.id)
        formattedData.authorIds = authorIds

        const submittingAuthor = version.authors.find(a => a.submittingAuthor)
        formattedData.author = submittingAuthor

        const curatorTeam = manuscript.teams.find(t => t.role === 'curator')

        const curators =
          curatorTeam.members.length > 0 &&
          curatorTeam.members.map(member => member.user)

        formattedData.curator = curators

        const editorTeam = manuscript.teams.find(t => t.role === 'editor')
        const editor = editorTeam.members[0] && editorTeam.members[0].user
        formattedData.editor = editor

        const scienceOfficerTeam = manuscript.teams.find(
          t => t.role === 'scienceOfficer',
        )

        const scienceOfficer =
          scienceOfficerTeam.members[0] && scienceOfficerTeam.members[0].user

        formattedData.scienceOfficer = scienceOfficer

        const sectionEditorTeam = manuscript.teams.find(
          t => t.role === 'sectionEditor',
        )

        const sectionEditor =
          sectionEditorTeam.members[0] && sectionEditorTeam.members[0].user

        formattedData.sectionEditor = sectionEditor

        formattedData.entities = manuscript.entities
      }

      if (type === 'reviewer') {
        const latestReview = last(version.reviews)
        const reviewSubmitted = latestReview && latestReview.status.submitted

        formattedData.reviewerStatus = manuscript.reviewerStatus
        formattedData.reviewSubmitted = reviewSubmitted

        if (manuscript.reviewerStatus === 'invited') {
          formattedData.dataType = manuscript.dataType

          const {
            acknowledgements,
            authors,
            comments,
            funding,
            geneExpression,
            image,
            imageCaption,
            imageTitle,
            laboratory,
            methods,
            reagents,
            patternDescription,
            references,
            suggestedReviewer,
          } = version

          formattedData = {
            ...formattedData,
            acknowledgements,
            authors,
            comments,
            funding,
            geneExpression,
            image,
            imageCaption,
            imageTitle,
            laboratory,
            methods,
            patternDescription,
            reagents,
            references,
            suggestedReviewer,
          }
        }
      }

      if (type === 'curator') {
        const curatorReviews = []
        let isAccepted = false
        manuscript.versions.forEach(v => {
          if (manuscript.dbReferenceId && v.decision === 'accept') {
            isAccepted = true
          }

          v.curatorReviews &&
            v.curatorReviews.forEach(r => {
              if (r.submitted) {
                curatorReviews.push(r)
              }
            })
        })

        const submittingAuthor = version.authors.find(a => a.submittingAuthor)
        formattedData.author = submittingAuthor

        formattedData.isAccepted = isAccepted
        formattedData.curatorReviews = curatorReviews
      }

      return formattedData
    })

  const authorArticles = makeArticleData('author')
  const curatorArticles = makeArticleData('curator')
  const reviewerArticles = makeArticleData('reviewer')
  const editorArticles = makeArticleData('editor')
  const scienceOfficerArticles = makeArticleData('scienceOfficer')
  const sectionEditorArticles = makeArticleData('sectionEditor')

  const handleInvitationFn = (action, manuscriptId) => {
    const manuscript =
      data && data.reviewer && data.reviewer.find(m => manuscriptId === m.id)

    if (!manuscript) throw new Error('Handle Invitation: Invalid manuscript id')
    const latestVersion = maxBy(manuscript.versions, 'created')

    return args.handleInvitation.handleInvitation({
      variables: {
        action,
        articleVersionId: latestVersion.id,
      },
    })
  }

  const createManuscriptFn = file =>
    args.createManuscript.createManuscript({ variables: { file } })

  const loading =
    args.getGlobalTeamsQuery.loading || args.dashboardManuscriptsQuery.loading

  const allEditors =
    globalTeams &&
    getTeamByType(globalTeams, 'editors').members.map(m => m.user)

  const allScienceOfficers =
    globalTeams &&
    getTeamByType(globalTeams, 'scienceOfficers').members.map(m => m.user)

  const allCurators =
    globalTeams &&
    getTeamByType(globalTeams, 'globalCurator').members.map(m => m.user)

  const allSectionEditors =
    globalTeams &&
    getTeamByType(globalTeams, 'globalSectionEditor').members.map(m => m.user)

  const [uploadFile] = useMutation(UPLOAD_FILE)
  const upload = file => uploadFile({ variables: { file } })

  return {
    allCurators,
    allEditors,
    allScienceOfficers,
    allSectionEditors,
    authorArticles,
    curatorArticles,
    createManuscript: createManuscriptFn,
    deleteArticle: args.deleteArticle.deleteArticle,
    editorAssigned,
    editorArticles,
    handleInvitation: handleInvitationFn,
    loading,
    makePayment,
    reviewerArticles,
    scienceOfficerArticles,
    sectionEditorArticles,
    updateAssignedEditor: args.updateAssignedEditor.updateAssignedEditor,
    updateAssignedScienceOfficer:
      args.updateAssignedScienceOfficer.updateAssignedScienceOfficer,
    upload,
    withdrawArticle: args.withdrawArticle.withdrawArticle,
  }
}

const Composed = ({ currentUser, render, ...props }) => (
  <Adopt mapper={mapper} mapProps={mapProps}>
    {mappedProps => render({ ...props, ...mappedProps, currentUser })}
  </Adopt>
)

export default withApollo(withRouter(withCurrentUser(Composed)))
