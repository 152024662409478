/* eslint-disable react/prop-types */

import React, { useContext } from 'react'
// import PropTypes from 'prop-types'
import { gql } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import { matchPath, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Action, AppBar, Icon, Dropdown } from '@pubsweet/ui'
import { th } from '@pubsweet/ui-toolkit'

import CurrentUserContext from '../userContext'
import { clearLocalStorage } from '../auth'
import { shouldShowAssignReviewersLink } from '../helpers/status'

const GET_MANUSCRIPT_STATUS_FOR_NAVIGATION = gql`
  query GetManuscriptStatusForNavigation($id: ID!) {
    manuscript(id: $id) {
      versions {
        id
        decision
        submitted
      }
    }
  }
`

// const Beta = styled.span`
//   background: ${th('colorPrimary')};
//   border-radius: 3px;
//   color: ${th('colorTextReverse')};
//   font-size: 12px;
//   padding: 2px 4px;
//   text-transform: uppercase;
// `

const Section = styled.div`
  align-items: center;
  display: flex;
`

const Item = styled.span`
  align-items: center;
  display: inline-flex;
  margin: calc(${th('gridUnit')} * 3) 1rem calc(${th('gridUnit')} * 3) 0;
`

const StyledBar = styled(AppBar)`
  flex: initial;
  height: calc(${th('gridUnit')} * 9);
  min-height: unset;

  > div:first-child > span:first-child {
    background: ${th('colorPrimary')};
    height: calc(${th('gridUnit')} * 9);
    margin: 0 calc(${th('gridUnit')} * 3) 0 0;
    padding: calc(${th('gridUnit')} * 3) 1rem;

    a {
      color: ${th('colorTextReverse')};
      font-weight: 500;
    }
  }
`

const navLinks = (location, currentUser) => {
  const isDashboard = location.pathname.match(/dashboard/g)
  const isArticle = location.pathname.match(/article/g)
  const isReviewers = location.pathname.match(/assign-reviewers\//g)
  // const isTeamManager = location.pathname.match(/teams/g)
  // const isUserManager = location.pathname.match(/users/g)
  const isCurationTool = location.pathname.match(/curation/g)
  // const isTaxonomiesManager = location.pathname.match(/taxonomies/g)
  // const isBilling = location.pathname.match(/billing/g)
  const isLinker = location.pathname.match(/linker/g)
  const isFindReviewers = location.pathname.match(/users/g)

  const path = `/(article|assign-reviewers|assign-reviewers-new|curation|linker)/:id`
  const match = matchPath(location.pathname, { path })
  let id
  if (match) ({ id } = match.params)

  const isAdmin = currentUser && currentUser.admin

  const isEditor =
    currentUser &&
    (currentUser.auth.isGlobalEditor ||
      currentUser.auth.isAssignedSectionEditor.includes(id))

  const isScienceOfficer = currentUser.auth.isGlobalScienceOfficer
  const isSectionEditor = currentUser.auth.isGlobalSectionEditor
  const isCurator = currentUser && currentUser.auth.isAssignedCurator

  const dashboardLink = (
    <Action active={isDashboard} to="/dashboard">
      Dashboard
    </Action>
  )

  const submitLink = (
    <Action active={isArticle} to={`/article/${id}`}>
      Article
    </Action>
  )

  const reviewersLink =
    isEditor || isAdmin ? (
      <Query query={GET_MANUSCRIPT_STATUS_FOR_NAVIGATION} variables={{ id }}>
        {({ data, loading }) => {
          if (loading) return null
          const manuscript = data && data.manuscript

          const showAssignReviewersLink = shouldShowAssignReviewersLink(
            manuscript,
          )

          if (!showAssignReviewersLink) return null

          return (
            <Action active={isReviewers} to={`/assign-reviewers/${id}`}>
              Assign Reviewers
            </Action>
          )
        }}
      </Query>
    ) : null

  const curationLink =
    isEditor || isAdmin || isCurator ? (
      <Action active={isCurationTool} to={`/curation/${id}`}>
        Curation Tool
      </Action>
    ) : null

  const findReviewersLink =
    isEditor || isScienceOfficer || isSectionEditor ? (
      <Action active={isFindReviewers} to="/users">
        Find Reviewers
      </Action>
    ) : null

  const links = [dashboardLink]

  if (isArticle || isReviewers || isCurationTool || isLinker) {
    links.push(submitLink)
    if (reviewersLink !== null) links.push(reviewersLink)
    if (curationLink !== null) links.push(curationLink)
  }

  if (findReviewersLink !== null) links.push(findReviewersLink)

  return links
}

const RightComponent = ({ user, onLogoutClick, loginLink }) => {
  const navigate = useHistory()

  const adminMenuList = [
    { id: '1', onClick: () => navigate.push('/teams'), title: 'Teams' },
    { id: '2', onClick: () => navigate.push('/users'), title: 'Users' },
    {
      id: '3',
      onClick: () => navigate.push('/taxonomies'),
      title: 'Taxonomies',
    },
    { id: '4', onClick: () => navigate.push('/billing'), title: 'Billing' },
    {
      id: '5',
      onClick: () => navigate.push('/statistics'),
      title: 'Statistics',
    },
    {
      id: '6',
      onClick: () => navigate.push('/releases'),
      title: 'Data Releases',
    },
    { id: '7', onClick: () => navigate.push('/news'), title: 'News' },
  ]

  return (
    <>
      <Section>
        {user && user.admin && (
          <Dropdown icon="menu" itemsList={adminMenuList}>
            Admin Menu
          </Dropdown>
        )}
        {user && (
          <Item>
            <Action to="/profile">
              <Icon size={2}>user</Icon>
              {user.displayName}
              {user.admin ? ' (admin)' : ''}
            </Action>
          </Item>
        )}

        {user && (
          <Item>
            <Icon size={2}>power</Icon>
            <Action onClick={onLogoutClick}>Logout</Action>
          </Item>
        )}
      </Section>
    </>
  )
}

const NavigationBar = props => {
  const { client, history, location } = props
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext)

  let links = null

  let onLogoutClick = () => {}

  if (currentUser) {
    links = navLinks(location, currentUser)

    onLogoutClick = () => {
      setCurrentUser(null) // clear user context
      client.cache.reset() // clear apollo
      clearLocalStorage()
      history.push('/login')
    }
  }

  const guidelinesMenuList = [
    {
      id: 1,
      onClick: () =>
        window.open('https://www.micropublication.org/about/for-authors/'),
      title: 'For Authors',
    },
    {
      id: 2,
      onClick: () =>
        window.open('https://www.micropublication.org/about/for-reviewers/'),
      title: 'For Reviewers',
    },
  ]

  const contactLink = (
    <Action target="_blank" to="https://www.micropublication.org/contact-us/">
      Contact
    </Action>
  )

  const guidelinesMenu = (
    <Dropdown itemsList={guidelinesMenuList}>Guidelines</Dropdown>
  )

  links = links || []

  links.push(contactLink)
  links.push(guidelinesMenu)

  return (
    <StyledBar
      brand="microPublication"
      navLinkComponents={links}
      onLogoutClick={onLogoutClick}
      rightComponent={RightComponent}
      user={currentUser}
    />
  )
}

// NavigationBar.propTypes = {
//   data: PropTypes.shape({
//     currentUser: PropTypes.shape({
//       admin: PropTypes.bool,
//       username: PropTypes.string.isRequired,
//     }),
//   }).isRequired,
//   history: PropTypes.shape({
//     push: PropTypes.func.isRequired,
//   }).isRequired,
//   location: PropTypes.shape({
//     pathname: PropTypes.string.isRequired,
//   }).isRequired,
// }

export default withApollo(NavigationBar)
