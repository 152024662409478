/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React from 'react'
import styled from 'styled-components'
import { Toggle } from 'react-powerplug'

import { Action, ActionGroup } from '@pubsweet/ui'
import { th } from '@pubsweet/ui-toolkit'

import {
  isEditableByAuthor,
  isInitialSubmissionReady,
} from '../../helpers/status'
import SectionItemWithStatus from './SectionItemWithStatus'
import ConfirmManuscriptDelete from '../../../ui/src/modals/ConfirmManuscriptDelete'
import PaymentModal from '../../../ui/src/modals/PaymentModal'
import ConfirmManuscriptWithdraw from '../../../ui/src/modals/ConfirmManuscriptWithdraw'

const Wrapper = styled.div`
  border-bottom: ${th('borderWidth')} ${th('borderStyle')}
    ${th('colorFurniture')};
  /* margin-bottom: ${th('gridUnit')}; */
  padding: 4px 0;
`

const ActionsWrapper = styled.div`
  flex-shrink: 0;
`

const Actions = props => {
  const {
    articleId,
    deleteArticle,
    displayStatus,
    status,
    title,
    paymentStatus,
    makePayment,
    versionId,
    withdrawArticle,
  } = props

  const isWithdrawn = status.decision === 'withdraw'
  const isEditable = isEditableByAuthor(status) && !isWithdrawn

  const canWithdraw =
    !['published', 'rejected', 'declined', 'withdrawn'].includes(
      displayStatus,
    ) && isInitialSubmissionReady(status)

  const confirmDelete = () => {
    deleteArticle({ variables: { id: articleId } })
  }

  const confirmWithdraw = () =>
    withdrawArticle({
      variables: {
        manuscriptVersionId: versionId,
        input: {
          decision: 'withdraw',
          decisionLetter: 'Article withdrawn by author',
        },
      },
    })

  const showPayButton =
    ['unpaid', 'seaUnpaid', 'before350'].includes(paymentStatus) &&
    ['accepted to proofs', 'published'].includes(displayStatus) &&
    !isWithdrawn

  let amount = '350'
  if (paymentStatus === 'seaUnpaid') amount = '150'
  if (paymentStatus === 'before350') amount = '250'

  return (
    <ActionsWrapper>
      <ActionGroup>
        <Action
          data-test-id={`author-action-edit-${articleId}`}
          to={`/article/${articleId}`}
        >
          {isEditable ? 'Edit' : 'View Article'}
        </Action>
        {isEditable && (
          <Toggle intial={false}>
            {({ on, toggle }) => (
              <>
                <Action onClick={toggle} primary>
                  Delete
                </Action>

                <ConfirmManuscriptDelete
                  isOpen={on}
                  onConfirm={confirmDelete}
                  onRequestClose={toggle}
                  title={title}
                />
              </>
            )}
          </Toggle>
        )}
        {showPayButton && (
          <Toggle intial={false}>
            {({ on, toggle }) => (
              <>
                <Action onClick={toggle} primary>
                  Pay
                </Action>

                <PaymentModal
                  amount={amount}
                  handlePayment={makePayment}
                  isOpen={on}
                  manuscriptId={articleId}
                  onConfirm={confirmDelete}
                  onRequestClose={toggle}
                />
              </>
            )}
          </Toggle>
        )}
        {canWithdraw && (
          <Toggle initial={false}>
            {({ on, toggle }) => (
              <>
                <Action onClick={toggle} primary>
                  Withdraw
                </Action>
                <ConfirmManuscriptWithdraw
                  isOpen={on}
                  onConfirm={confirmWithdraw}
                  onRequestClose={toggle}
                  title={title}
                />
              </>
            )}
          </Toggle>
        )}
      </ActionGroup>
    </ActionsWrapper>
  )
}

const AuthorSectionItem = props => {
  const {
    deleteArticle,
    displayStatus,
    id,
    status,
    title,
    paymentStatus,
    makePayment,
    versionId,
    withdrawArticle,
  } = props

  const ActionsComponent = (
    <Actions
      articleId={id}
      deleteArticle={deleteArticle}
      displayStatus={displayStatus}
      makePayment={makePayment}
      paymentStatus={paymentStatus}
      status={status}
      title={title}
      versionId={versionId}
      withdrawArticle={withdrawArticle}
    />
  )

  return (
    <Wrapper data-test-id="dashboard-section-item-author">
      <SectionItemWithStatus actionsComponent={ActionsComponent} {...props} />
    </Wrapper>
  )
}

export default AuthorSectionItem
