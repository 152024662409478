/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import styled from 'styled-components'
import { titleCase } from 'lodash'

import { uuid } from '@coko/client'
import { th } from '@pubsweet/ui-toolkit'

import { FileUpload, Icon } from '../common'

const Error = styled.span`
  color: ${th('colorError')};
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  padding-left: ${th('gridUnit')};
`

const StyledIcon = styled(Icon)`
  svg {
    height: calc(${th('gridUnit')} * 2);
    stroke: ${th('colorFurniture')};
  }
`

const initials = name => {
  const matches = name.match(/\b([A-Z])/g)
  return matches && matches.join('')
}

const ReferenceUpload = props => {
  const {
    name,
    setFieldValue,
    setFieldTouched,
    touched,
    readOnly,
    references,
  } = props

  const [internalError, setInternalError] = useState(null)

  const upload = async file => {
    const reader = new FileReader()

    reader.onload = () => {
      const lines = reader.result.split('\n').filter(l => l.trim())

      const newReferences = [] // references.map(reference => {

      let newReference, title, so, authors, year, journal, pages, volume, id

      // const lines = reference.split('\n')
      lines.forEach((line, i) => {
        if (line.startsWith('PMID')) {
          newReference = {
            doi: '',
            pubmedId: '',
            reference: '',
            id: uuid(),
          }
          authors = []
          newReference.pubmedId = line.substring(6).trim()
        }

        if (line.startsWith('TY ')) {
          newReference = {
            doi: '',
            pubmedId: '',
            reference: '',
            id: uuid(),
          }
          authors = []
          year = ''
          journal = ''
          pages = ''
          volume = ''
          id = ''
        }

        if (line.startsWith('AN ')) {
          newReference.pubmedId = line.substring(6).trim()
        }

        if (line.startsWith('AU ')) {
          const splitName = line.substring(6).split(',')
          const surname = titleCase(splitName[0])
          const firstInitials = initials(splitName[1])
          authors.push(`${surname} ${firstInitials}`)
        }

        if (line.trim().endsWith('[doi]')) {
          newReference.doi = line.substring(6).replace(' [doi]', '').trim()
        }

        if (line.startsWith('DO ')) {
          newReference.doi = line.substring(6).trim()
        }

        if (line.startsWith('TI ')) {
          title = line.substring(6).trim()
        }

        if (line.startsWith('SO ')) {
          so = line.substring(6).trim()
          if (lines.length === i + 2) so = `${so}${lines[i + 1].trim()}`

          if (authors.length > 8) {
            const lastAuthor = authors[authors.length - 1]
            newReference.reference = `${authors
              .slice(0, 6)
              .join(', ')}, et al., ${lastAuthor}. ${title} ${so}`
          } else {
            newReference.reference = `${authors.join(', ')}. ${title} ${so}`
          }

          newReferences.push(newReference)
        }

        if (line.startsWith('PY ')) {
          year = line.substring(6).trim()
        }

        if (line.startsWith('T2 ')) {
          journal = line.substring(6).trim()
        }

        if (line.startsWith('SP ')) {
          pages = line.substring(6).trim()
        }

        if (line.startsWith('VL ')) {
          volume = line.substring(6).trim()
        }

        if (line.startsWith('ID ')) {
          id = line.substring(6).trim()
        }

        if (line.startsWith('ER ')) {
          if (authors.length > 8) {
            const lastAuthor = authors[authors.length - 1]
            newReference.reference = `${authors
              .slice(0, 6)
              .join(', ')}, et al., ${lastAuthor}. ${year}. ${title}.`
          } else {
            newReference.reference = `${authors.join(', ')}. ${year}. ${title}.`
          }

          if (journal) {
            newReference.reference = `${
              newReference.reference
            } ${journal.replace(/\.$/, '')}`
            if (volume)
              newReference.reference = `${newReference.reference}. ${volume}`
            if (pages)
              newReference.reference = `${newReference.reference}: ${pages}.`
          }

          if (id) newReference.reference = `${newReference.reference} ${id}.`

          newReferences.push(newReference)
        }
      })
      setFieldValue(name, [...references, ...newReferences])
    }

    reader.readAsText(file)

    const res = { data: { upload: { url: null } } }
    return res
  }

  const message = (
    <p>Drag NBIB or RIS file here, or click to select NBIB or RIS file</p>
  )

  return (
    <p>
      <p>
        Upload references in nbib or ris format.{' '}
        <StyledIcon title="microPublication is working on streamlining reference imports from commonly used reference management software, such as EndNote, Zenodo, Mendeley. You can create the list of references in Pubmed (export the file in NBIB) or use your citation manager (export the file in RIS format). In the citation manager you can select any journal style before exporting the file in RIS. You can then drag and drop the file in the Reference section in the microPublication submission form.">
          help_circle
        </StyledIcon>
      </p>
      {touched && internalError && <Error>{internalError}</Error>}
      <FileUpload
        message={message}
        name="referenceUpload"
        readOnly={readOnly}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        setInternalError={setInternalError}
        upload={upload}
      />
    </p>
  )
}

export default ReferenceUpload
