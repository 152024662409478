/* eslint-disable react/prop-types */

import React from 'react'
import styled from 'styled-components'
import Dropzone from 'react-dropzone'
import Papa from 'papaparse'

import { th } from '@pubsweet/ui-toolkit'

const StyledDropzone = styled(Dropzone)`
  border: 1px dashed ${th('colorFurniture')};
  border-radius: 5px;
  display: flex;
  height: calc(${th('gridUnit')} * 12);
  margin-top: ${th('gridUnit')};
  width: 400px;

  p {
    align-self: center;
    color: ${th('colorTextPlaceholder')};
    flex-grow: 1;
    text-align: center;
  }
`

class FileUpload extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      uploading: false,
      size: 0,
    }
    this.maxSize = 200 * 1024 * 1024
  }

  handleDrop = fileList => {
    const file = fileList[0]
    if (!file) return

    const { name, setFieldValue, setInternalError, upload, value } = this.props

    this.setState({
      uploading: true,
      size: file.size,
    })

    upload(file)
      .then(res => {
        this.setState({
          uploading: false,
        })

        if (Array.isArray(value)) {
          const newFile = {
            name: file.name,
            url: res.data.upload.url,
            preview: file.preview,
          }

          value.push(newFile)
          setFieldValue(name, value)
        } else {
          const newFile = {
            name: file.name,
            url: res.data.upload.url,
            preview: file.preview,
          }

          if (file.name.toLowerCase().endsWith('csv')) {
            newFile.header = []
            newFile.data = []
            Papa.parse(file.preview, {
              download: true,
              header: true,
              complete: results => {
                newFile.header = results.meta.fields.map(field => ({
                  accessor: field,
                  Header: field,
                }))
                newFile.data = results.data
              },
              skipEmptyLines: true,
            })
          }

          setFieldValue(name, newFile)
        }
      })
      .catch(e => {
        this.setState({
          uploading: false,
        })

        setInternalError('An error occured during upload')
        setTimeout(() => {
          setInternalError(null)
        }, 5000)
      })
  }

  handleDropRejected = rejectedFiles => {
    const file = rejectedFiles[0]
    const { name, setFieldTouched, setInternalError, accept } = this.props

    let error = 'Something went wrong, please re-upload the file'

    if (file.size > this.maxSize) {
      error = 'File size must be no greater than 200 MB'
    }

    if (!accept.includes(file.type)) error = 'Incorrect file type'

    setInternalError(error)
    setFieldTouched(name)

    setTimeout(() => {
      setInternalError(null)
    }, 5000)
  }

  render() {
    const {
      readOnly,
      'data-test-id': dataTestId,
      accept,
      message,
      fileProgress,
    } = this.props

    const { uploading, size } = this.state

    return (
      <div data-test-id={dataTestId}>
        {uploading && <div>Uploading...</div>}
        {uploading &&
          fileProgress &&
          `${`${(fileProgress / size) * 100}`.substring(0, 3)}%`}
        {!readOnly && (
          <StyledDropzone
            accept={accept}
            data-test-id={`${dataTestId}-dropzone`}
            maxSize={this.maxSize}
            onDrop={this.handleDrop}
            onDropRejected={this.handleDropRejected}
          >
            {message}
          </StyledDropzone>
        )}
      </div>
    )
  }
}

export default FileUpload
