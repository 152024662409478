import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import * as yup from 'yup'

import { Button, Form, TextField, Select, Checkbox } from '../common'
import { th } from '../_helpers'

const Wrapper = styled.div``

const StyledSelect = styled(Select)`
  line-height: ${th('lineHeightBase')};
  margin-bottom: calc(${th('gridUnit')} * 2);
  width: calc(${th('gridUnit')} * 50);
`

const Label = styled.label`
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  padding: 10px 10px 0 0;
`

const PersonalInfo = props => {
  const {
    className,
    givenNames,
    surname,
    update,
    orcid,
    reviewer,
    species,
    specialty,
    specialties,
    researchArea,
    researchAreas,
    searchSpecies,
  } = props

  const initialValues = {
    givenNames: givenNames || '',
    orcid: orcid || '',
    surname: surname || '',
    reviewer: reviewer || false,
    species: species || [],
    specialty: specialty || [],
    researchArea: researchArea || [],
  }

  const validations = yup.object().shape({
    givenNames: yup.string().required('Given names are required'),
    orcid: yup.string().nullable(),
    surname: yup.string().required('Surname is required'),
    reviewer: yup.bool().nullable(),
    researchArea: yup.array().of(yup.string()),
    species: yup.array().of(yup.string()),
    specialty: yup.array().of(yup.string()),
  })

  return (
    <Wrapper className={className}>
      <Form
        initialValues={initialValues}
        onSubmit={update}
        validationSchema={validations}
      >
        {formProps => {
          const {
            errors,
            handleBlur,
            handleChange,
            setFieldValue,
            touched,
            values,
          } = formProps

          const isValid = isEmpty(errors)

          const disabled =
            (values.givenNames === initialValues.givenNames &&
              values.surname === initialValues.surname &&
              values.orcid === initialValues.orcid &&
              values.reviewer === initialValues.reviewer &&
              values.species === initialValues.species &&
              values.researchArea === initialValues.researchArea &&
              values.specialty === initialValues.specialty) ||
            !isValid

          const handleSelectResearchArea = value => {
            setFieldValue('researchArea', value && value.map(v => v.value))
          }

          const handleSelectSpecialty = value => {
            setFieldValue('specialty', value && value.map(v => v.value))
          }

          const handleSelectSpecies = value => {
            setFieldValue('species', value && value.map(v => v.value))
          }

          return (
            <>
              <TextField
                error={errors.givenNames}
                handleBlur={handleBlur}
                handleChange={handleChange}
                label="Given Names"
                name="givenNames"
                touched={touched}
                value={values.givenNames}
              />
              <TextField
                error={errors.surname}
                handleBlur={handleBlur}
                handleChange={handleChange}
                label="Surname"
                name="surname"
                touched={touched}
                value={values.surname}
              />
              <TextField
                handleBlur={handleBlur}
                handleChange={handleChange}
                label="ORCID iD"
                name="orcid"
                touched={touched}
                value={values.orcid}
              />
              <Checkbox
                checked={values.reviewer}
                label="Can be a Reviewer?"
                name="reviewer"
                onChange={handleChange}
                touched={touched}
                value={values.reviewer}
              />
              <Label>Species</Label>
              <StyledSelect
                async
                isClearable
                isMulti
                loadOptions={searchSpecies}
                name="species"
                onChange={handleSelectSpecies}
                placeholder="Species"
                value={values.species.map(s => ({ value: s, label: s }))}
              />
              <Label>Research Area</Label>
              <StyledSelect
                isClearable
                isMulti
                onChange={handleSelectResearchArea}
                options={researchAreas}
                placeholder="Research Area"
                value={researchAreas.filter(
                  option =>
                    values.researchArea &&
                    values.researchArea.includes(option.value),
                )}
              />
              <Label>Specialty</Label>
              <StyledSelect
                isClearable
                isMulti
                onChange={handleSelectSpecialty}
                options={specialties}
                placeholder="Specialty"
                value={specialties.filter(
                  option =>
                    values.specialty && values.specialty.includes(option.value),
                )}
              />
              <Button disabled={disabled} primary type="submit">
                Update
              </Button>
            </>
          )
        }}
      </Form>
    </Wrapper>
  )
}

PersonalInfo.propTypes = {
  givenNames: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
  orcid: PropTypes.string,
  update: PropTypes.func.isRequired,
  reviewer: PropTypes.bool,
  species: PropTypes.arrayOf(PropTypes.string),
  specialty: PropTypes.arrayOf(PropTypes.string),
  researchArea: PropTypes.arrayOf(PropTypes.string),
  searchSpecies: PropTypes.func,
  researchAreas: PropTypes.arrayOf(PropTypes.object.isRequired),
  specialties: PropTypes.arrayOf(PropTypes.object.isRequired),
}

PersonalInfo.defaultProps = {
  orcid: null,
  reviewer: false,
  species: null,
  specialty: null,
  researchArea: null,
  searchSpecies: null,
  specialties: [],
  researchAreas: [],
}

export default PersonalInfo
