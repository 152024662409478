import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from '@apollo/client'
import omit from 'lodash/omit'

import {
  CURATOR_PREVIEW,
  CURATOR_PANEL,
  SAVE_CURATOR_REVIEW,
  SEND_CHAT,
  SUBMIT_CURATOR_REVIEW,
  SUBMIT_CURATION,
} from '../../graphql'

import {
  ChatModal,
  DateParser,
  ReviewerPanel,
  CuratorSubmissionConfirmation,
  SyncedTabs,
} from '../../../ui'

import { ArticlePreview } from '../../components/ui'
import { exportManuscriptToPDF } from '../../fetch/exportManuscript'
import {
  transformChatMessages,
  getFromStorage,
  saveToStorage,
} from '../_helpers/common'

const makeOptions = theme => [
  {
    color: theme.colorSuccess,
    label: 'Meets DB standards',
    value: 'accept',
  },
  {
    color: theme.colorWarning,
    label: 'Needs modification to meet DB standards',
    value: 'revise',
  },
  {
    color: theme.colorError,
    label: 'I have major concerns with this article',
    value: 'reject',
  },
]

const makeCuratedOptions = theme => [
  {
    color: theme.colorSuccess,
    label: 'Curated in DB',
    value: 'curated in db',
  },
  {
    color: theme.colorWarning,
    label: 'Nothing to Curate',
    value: 'nothing to curate',
  },
]

/* eslint-disable-next-line react/prop-types */
const Label = ({ created, index }) => (
  <DateParser dateFormat="MM.DD.YY HH:mm" timestamp={new Date(Number(created))}>
    {timestamp => (
      <span>
        {index === 0
          ? `Original: ${timestamp}`
          : `Revision ${index}: ${timestamp}`}
      </span>
    )}
  </DateParser>
)

const CuratorView = props => {
  const { manuscriptId } = props

  /**
   * Handle modals with state
   */

  const [showChatModal, setShowChatModal] = useState(false)
  const [showConfirmSubmission, setShowConfirmSubmission] = useState(false)
  const [submissionData, setSubmissionData] = useState(null)

  /**
   * Queries & Mutations
   */

  const { data: previewData, loading: previewLoading } = useQuery(
    CURATOR_PREVIEW,
    {
      variables: {
        id: manuscriptId,
      },
    },
  )

  const { data: panelData, loading: panelLoading } = useQuery(CURATOR_PANEL, {
    variables: {
      id: manuscriptId,
    },
  })

  const [saveMutation] = useMutation(SAVE_CURATOR_REVIEW)
  const [submitMutation] = useMutation(SUBMIT_CURATOR_REVIEW)
  const [submitCurationMutation] = useMutation(SUBMIT_CURATION)

  const [sendChatMutation] = useMutation(SEND_CHAT, {
    refetchQueries: [
      {
        query: CURATOR_PANEL,
        variables: { id: manuscriptId },
      },
    ],
  })

  /**
   * Left side: preview
   */

  const submittedVersions =
    previewData &&
    previewData.manuscript &&
    previewData.manuscript.versions.filter(v => v.submitted)

  const leftSections =
    submittedVersions &&
    submittedVersions.map((version, index) => ({
      key: version.id,
      label: <Label created={version.created} index={index} />,
      content: (
        <ArticlePreview
          article={version}
          categories={previewData.manuscript.categories}
          exportManuscript={exportManuscriptToPDF}
          manuscriptId={manuscriptId}
          previousVersion={submittedVersions[index - 1]}
          showAdditionalData
          showHeader={false}
          submissionTypes={previewData.manuscript.submissionTypes}
        />
      ),
    }))

  /**
   * Right side: Curator panel
   */

  let chatMessages, sendChatMessage, saveChat, getSavedChat
  let rightSections
  let submitReview, submitCuration

  if (panelData && panelData.manuscript && panelData.manuscript.versions) {
    const { manuscript } = panelData
    const { chatThreads, dbReferenceId, doi, versions } = manuscript

    // there can only be one chat per curator per manuscript
    const chatThread = chatThreads[0]
    chatMessages = transformChatMessages(chatThread.messages)

    sendChatMessage = content =>
      sendChatMutation({
        variables: {
          input: {
            chatThreadId: chatThread.id,
            content,
          },
        },
      })

    saveChat = message => saveToStorage(message, `chat_${chatThread.id}`)
    getSavedChat = () => getFromStorage(`chat_${chatThread.id}`)

    const submittedPanelVersions = versions.filter(v => v.submitted)

    rightSections = submittedPanelVersions.map((version, index) => {
      const { decision, curatorReviews } = version
      const latest = index === submittedPanelVersions.length - 1

      // there can only be one review per curator per version
      const thisReview = curatorReviews[0]
      const { submitted } = thisReview

      const review = {
        content: thisReview.content,
        recommendation: thisReview.recommendation,
        openAcknowledgement: thisReview.openAcknowledgement,
        curated: thisReview.curated,
      }

      let save, handleClickSubmit

      if (latest) {
        save = input => {
          const curatorInput = omit(input, [
            'confidentialComments',
            'reviseQualifier',
          ])

          return saveMutation({
            variables: {
              id: thisReview.id,
              input: curatorInput,
            },
          })
        }

        submitReview = async input => {
          const curatorInput = omit(input, [
            'confidentialComments',
            'reviseQualifier',
          ])

          return submitMutation({
            variables: {
              id: thisReview.id,
              input: curatorInput,
            },
          }).then(() => {
            setSubmissionData(null)
            setShowConfirmSubmission(false)
          })
        }

        handleClickSubmit = input => {
          setSubmissionData(input)
          setShowConfirmSubmission(true)
        }

        submitCuration = curated =>
          submitCurationMutation({ variables: { id: thisReview.id, curated } })
      }

      const canStillReview = latest && !submitted && decision !== 'publish'
      const linkerLink = `/linker/${manuscriptId}`

      return {
        key: version.id,
        label: <Label created={version.created} index={index} />,
        content: (
          <ReviewerPanel
            canStillReview={canStillReview}
            chatMessages={chatMessages}
            dbReferenceId={dbReferenceId}
            decision={decision}
            doi={doi}
            getSavedChat={getSavedChat}
            key={version.id}
            linkerLink={linkerLink}
            makeCuratedOptions={makeCuratedOptions}
            makeOptions={makeOptions}
            onClickChat={() => setShowChatModal(true)}
            openAcknowledgementLabel="I would like to be openly acknowledged as the curator"
            review={review}
            reviewTextAreaLabel="Curator assesssment"
            reviewTextAreaPlaceholder="Enter your curator feedback here"
            save={save}
            saveChat={saveChat}
            sendChatMessage={sendChatMessage}
            showConfidentialCommentsEditor={false}
            showCuratedCheckbox={latest}
            showLinkingButton
            showRequestToSeeRevision={false}
            submit={handleClickSubmit}
            submitCuration={submitCuration}
            submitted={submitted}
          />
        ),
      }
    })
  }

  return (
    <>
      <SyncedTabs
        leftHeader="Article Preview"
        leftLoading={previewLoading}
        leftSections={leftSections}
        rightHeader="Curator View"
        rightLoading={panelLoading}
        rightSections={rightSections}
      />

      {/* Modals */}

      <ChatModal
        getSavedChat={getSavedChat}
        isOpen={showChatModal}
        messages={chatMessages}
        onRequestClose={() => setShowChatModal(false)}
        saveChat={saveChat}
        sendMessage={sendChatMessage}
      />

      <CuratorSubmissionConfirmation
        isOpen={showConfirmSubmission}
        onConfirm={() => submitReview(submissionData)}
        onRequestClose={() => {
          setSubmissionData(null)
          setShowConfirmSubmission(false)
        }}
      />
    </>
  )
}

CuratorView.propTypes = {
  manuscriptId: PropTypes.string.isRequired,
}

export default CuratorView
