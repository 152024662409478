/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import get from 'lodash/get'
import set from 'lodash/set'
import styled from 'styled-components'

import { uuid } from '@coko/client'
import { th } from '@pubsweet/ui-toolkit'

import ReferenceList from './ReferenceList'
import ReferenceInputModal from '../modals/ReferenceInputModal'
import { getReference } from '../../../app/fetch/PubMedApi'
import { crossrefLookup } from '../../../app/fetch/CrossrefApi'
import { Button } from '../common'
import ReferenceUpload from './ReferenceUpload'

const Label = styled.label`
  display: block;
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
`

const GroupWrapper = styled.div`
  margin-bottom: calc(${th('gridUnit')} * 2);
`

const Error = styled.span`
  color: ${th('colorError')};
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  padding-left: ${th('gridUnit')};
`

const Info = styled.div`
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  margin-bottom: ${th('gridUnit')};
  width: 600px;
`

const ReferenceSection = props => {
  const {
    dataTestId,
    errors,
    handleChange,
    name,
    readOnly,
    required,
    setErrors,
    setFieldTouched,
    setFieldValue,
    touched,
    values,
  } = props

  const [currentReference, setCurrentReference] = useState(0)
  const [isInputOpen, setInputOpen] = useState(false)

  const handleAdd = () => {
    let data = get(values, name)

    if (!data) data = []

    const newItem = {
      id: uuid(),
      reference: '',
      doi: '',
      pubmedId: '',
    }

    data.push(newItem)
    setCurrentReference(data.length - 1)
    setFieldValue(name, data)
    handleChange(name)
    set(values, name, data)
    setInputOpen(true)
  }

  const handleRemove = async id => {
    const data = get(values, name)
    const changed = data.filter(val => val.id !== id)

    setFieldValue(name, changed)
    handleChange(name)
  }

  const fetchReference = async (value, fieldName) =>
    getReference(value)
      .then(async response => {
        if (!response.ok) {
          throw await response.text()
        }

        return response.json()
      })
      .then(json => {
        const { reference: pubMedResult } = json
        const referenceField = `${fieldName}.reference`
        setFieldValue(referenceField, `<p>${pubMedResult}</p>`)
        const referenceId = `${fieldName}.id`
        setFieldValue(referenceId, uuid())
      })
      .catch(e => {
        const referenceIndex = name.replace(/[^\d]/g, '')

        // eslint-disable-next-line no-console
        if (!errors.references) {
          errors.references = []
        }

        errors.references[referenceIndex] = e.message || e
        setErrors(errors)
      })

  const fetchCrossref = async (value, fieldName) =>
    crossrefLookup(value)
      .then(async response => {
        if (!response.ok) {
          throw await response.text()
        }

        return response.json()
      })
      .then(json => {
        const { reference: pubMedResult } = json
        const referenceField = `${fieldName}.reference`
        setFieldValue(referenceField, `<p>${pubMedResult}</p>`)
        const referenceId = `${fieldName}.id`
        setFieldValue(referenceId, uuid())
      })
      .catch(e => {
        const referenceIndex = name.replace(/[^\d]/g, '')

        // eslint-disable-next-line no-console
        if (!errors.references) {
          errors.references = []
        }

        errors.references[referenceIndex] = e.message || e
        setErrors(errors)
      })

  let data = get(values, name)

  if (!data) {
    // || data.length === 0) {
    data = []

    setFieldValue(name, data)
    handleChange(name)
    set(values, name, data)
  }

  const handleReorder = newList => {
    setFieldValue(name, newList)
    handleChange(name)
  }

  const error = get(errors, name)
  const referenceTouched = get(touched, name)

  const onClickEditRow = id => {
    setCurrentReference(values.references.findIndex(a => a.id === id))
    setInputOpen(true)
  }

  // const passProps = omit(props, ['dataTestId'])
  return (
    <GroupWrapper data-test-id={dataTestId}>
      <Label>
        References
        {referenceTouched && error && (
          <Error>There is an error with on or more references</Error>
        )}
      </Label>

      <Info>
        <p>
          References should be listed in alphabetical order. Enter the PubMed ID
          (not PMC ID) or the DOI and press &#34;Retrieve Citation&#34;. If the
          citation does not have a PubMed ID or DOI, enter the reference as
          text. In this case, please format the reference to be consistent with
          all other references.
          <br />
          Please make sure you attribute sources appropriately.
        </p>
      </Info>
      {!readOnly && (
        <GroupWrapper>
          <Button onClick={handleAdd} primary>
            Add reference
          </Button>
          <ReferenceInputModal
            currentReference={currentReference}
            errors={errors}
            fetchCrossref={fetchCrossref}
            fetchReference={fetchReference}
            handleAdd={handleAdd}
            handleChange={handleChange}
            isOpen={isInputOpen}
            name={name}
            onConfirm={() => setInputOpen(false)}
            onRequestClose={() => setInputOpen(false)}
            readOnly={readOnly}
            required={required}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            touched={touched}
            // {...passProps}
            values={values}
          />
        </GroupWrapper>
      )}
      <ReferenceUpload
        name="references"
        readOnly={readOnly}
        references={get(values, 'references')}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        touched={touched}
      />
      <ReferenceList
        error={error}
        onClickEditRow={onClickEditRow}
        onClickRemoveRow={handleRemove}
        onReorder={handleReorder}
        references={get(values, 'references')}
      />
    </GroupWrapper>
  )
}

export default ReferenceSection
