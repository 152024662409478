import React from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from '@apollo/client'
import last from 'lodash/last'
import clone from 'lodash/clone'

import { FULL_PREVIEW, SEND_CHAT } from '../../graphql'
import { Loader } from '../../../ui'
import { ArticlePreview } from '../../components/ui'
import { exportManuscriptToPDF } from '../../fetch/exportManuscript'
import {
  getFromStorage,
  transformChatMessages,
  saveToStorage,
  parseCsv,
} from '../_helpers/common'

const PreviewPage = props => {
  const { isAuthor, isEditor, manuscriptId } = props

  const { data, loading } = useQuery(FULL_PREVIEW, {
    variables: { id: manuscriptId },
  })

  const [sendChatMessage] = useMutation(SEND_CHAT, {
    refetchQueries: [
      {
        query: FULL_PREVIEW,
        variables: { id: manuscriptId },
      },
    ],
  })

  let previewData,
    authorChatThread,
    authorChatMessages,
    saveAuthorChat,
    getSavedAuthorChat,
    sendChatMessageFn

  if (data && data.manuscript) {
    previewData = clone(last(data.manuscript.versions))

    if (previewData.image.data) {
      const parsedCsv = parseCsv(previewData.image.data)
      previewData.csvHeader = parsedCsv.csvHeader
      previewData.csvData = parsedCsv.csvData
    }

    authorChatThread = data.manuscript.chatThreads.find(
      thread => thread.chatType === 'author',
    )

    authorChatMessages =
      authorChatThread && transformChatMessages(authorChatThread.messages)

    sendChatMessageFn = content =>
      sendChatMessage({
        variables: {
          input: {
            chatThreadId: authorChatThread.id,
            content,
          },
        },
      })

    saveAuthorChat = message =>
      saveToStorage(message, `chat_${authorChatThread.id}`)
    getSavedAuthorChat = () => getFromStorage(`chat_${authorChatThread.id}`)
  }

  if (loading) return <Loader />

  // JUST SUBMITTED

  return (
    <ArticlePreview
      article={previewData}
      authorChatMessages={authorChatMessages}
      categories={data.manuscript.categories}
      exportManuscript={exportManuscriptToPDF}
      getSavedAuthorChat={getSavedAuthorChat}
      isAuthor={isAuthor}
      isEditor={isEditor}
      manuscriptId={manuscriptId}
      // previousVersion
      saveAuthorChat={saveAuthorChat}
      sendAuthorChatMessage={sendChatMessageFn}
      showAdditionalData
      showHeader
      submissionTypes={data.manuscript.submissionTypes}
    />
  )
}

PreviewPage.propTypes = {
  isAuthor: PropTypes.bool.isRequired,
  isEditor: PropTypes.bool.isRequired,
  manuscriptId: PropTypes.string.isRequired,
}

export default PreviewPage
