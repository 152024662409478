/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react'
import ReactTable from 'react-table-v6'
import 'react-table-v6/react-table.css'

const CsvPreview = props => {
  const { csv, header, source } = props

  if (!csv) return null

  return (
    <>
      <a href={source}>Download CSV</a>
      <ReactTable
        columns={header.map(h => ({
          Header: h.Header,
          accessor: d => d[h.accessor],
          Cell: ({ row }) => {
            const fontStyle = row[h.accessor].includes('<i>')
              ? 'italic'
              : 'normal'

            return (
              <div style={{ 'white-space': 'normal', 'font-style': fontStyle }}>
                {row[h.accessor].replace('<i>', '').replace('</i>', '')}
              </div>
            )
          },
          id: h.accessor,
        }))}
        data={csv}
        minRows="0"
        showPagination={csv.length > 20}
      />
    </>
  )
}

export default CsvPreview
