import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { css, ThemeContext, withTheme } from 'styled-components'
import * as yup from 'yup'
import get from 'lodash/get'

import { stripHTML } from '../_helpers'
import { Button, Form, PanelTextEditor, Radio, Status } from '../common'

const Wrapper = styled.div``

const Editor = styled(PanelTextEditor)`
  div[contenteditable] {
    ${props =>
      props.readOnly &&
      css`
        border-bottom: 0;
      `};
  }
`

const validations = yup.object().shape({
  confidentialComments: yup.string(),
  decision: yup.string().required('You need to make a decision'),
  decisionLetter: yup
    .string()
    .test(
      'decision-letter-not-empty',
      'You need to write a decision letter',
      val => {
        if (!val) return false
        return stripHTML(val).length > 0
      },
    ),
})

const makeOptions = theme => [
  {
    color: theme.colorSuccess,
    label: 'Accept and send proofs',
    value: 'accept',
  },
  {
    color: theme.colorError,
    label: 'Decline',
    value: 'decline',
  },
  {
    color: theme.colorWarning,
    label: 'Revise',
    value: 'revise',
  },
  {
    color: theme.colorError,
    label: 'Reject',
    value: 'reject',
  },
  {
    color: theme.colorWarning,
    label: 'Accept for 3rd party review',
    value: 'curation',
  },
  {
    color: theme.colorPrimary,
    label: 'Publish',
    value: 'publish',
  },
]

const DecisionSection = props => {
  const {
    articleTitle,
    articleUrl,
    authorName,
    citation,
    confidentialComments,
    curatorWarning,
    curatorResponse,
    dbReferenceId,
    getSavedDecision,
    paymentUrl,
    decision,
    decisionLetter,
    invoiceNo,
    invoiceUrl,
    isResubmission,
    onClickAcceptConfirm,
    onClickTeamManager,
    paymentStatus,
    proofLink,
    proteopedia,
    publishedUrl,
    reviewExists,
    reviseQualifier,
    saveDecision,
    skippedProof,
    submitDecision,
    submitted,
  } = props

  const theme = useContext(ThemeContext)
  const radioOptions = makeOptions(theme)

  const savedDecision = getSavedDecision()

  const initialValues = {
    confidentialComments:
      confidentialComments || get(saveDecision, 'confidentialComments') || '',
    decision: decision || get(savedDecision, 'decision') || '',
    decisionLetter:
      decisionLetter || get(savedDecision, 'decisionLetter') || '',
    key: '',
  }

  const handleSubmit = (values, formikBag) => {
    if (values.decision === 'accept') {
      onClickAcceptConfirm(values.decisionLetter)
    } else if (values.decision === 'curation' && curatorWarning) {
      onClickTeamManager()
    } else {
      submitDecision({
        confidentialComments: values.confidentialComments,
        decision: values.decision,
        decisionLetter: values.decisionLetter,
      })
    }
  }

  const billingText =
    paymentStatus === 'unpaid' || paymentStatus === 'seaUnpaid'
      ? `
      <p>
      Please submit your <a href="${paymentUrl}">payment here</a>. If you are unable to submit your payment, require a waiver, or have any questions regarding billing please contact us at <a href="mailto:billing@micropublication.org">billing@micropublication.org</a>.
      </p>
      <p>
      Your invoice (#${invoiceNo}) can be downloaded <a href="${invoiceUrl}">here</a>.
      </p>
      `
      : ''

  const proofsLetter = `
    <p>
    Dear ${authorName},
    </p>
    <p>
    We are happy to let you know that your article has been accepted for publication. Congratulations!
    </p>
    <p>
    Please take a careful look at the production proofs of your article: <a href="${proofLink}">proof download</a>.
    </p>
    <p>
    Please make sure there are no typos, errors or omissions in your article, including your title, author names, affiliations, 
    reagents, etc. in addition to your reported results. These are little things that if wrong will still require a separate 
    corrigendum article if they need correction after publication.
    </p>
    <p>
    Please make any change or approve the current version by following this <a href="${articleUrl}">link</a>.
    </p>
    <p>
    Please return your corrections within 72 hours. If you are unable to return your corrections within 72 hours, let us know.
    </p>
    ${billingText}
    <p>
    Do not hesitate to contact us if you have any questions.
    </p>
    <p>
    We look forward to publishing your work.
    </p>
    <p>
    Best wishes,
    </p>
    <p>
    The microPublication Editorial Team
    </p>
  `

  const rejectLetter = `
    <p>
    Dear ${authorName},
    </p>
    <p>
    We regret to inform you that your article "${stripHTML(
      articleTitle,
    )}" has been rejected following the reviewer's comments, see below.
    </p>
    <p>
    We are sorry not to have better news for you, however, we thank you for giving us the opportunity to consider your manuscript.
    </p>
    <p>
    Best wishes,
    </p>
    <p>
    The microPublication Editorial Team
    </p>
  `

  const reviseQualifierLetters = {
    'accept, with minor modifications to figure and/or text': `
      Your article "${stripHTML(articleTitle)}" has been accepted, with minor 
      modifications.
  `,

    'accept, with major modifications to figure and/or text': `
    Your article "${stripHTML(articleTitle)}" has been accepted, with major 
    modifications.
  `,
    'accept, with additional data/experiment or a caveat concerning the conclusion given the missing information, as well as any additional minor or major modifications': `
    Your article "${stripHTML(articleTitle)}" has been accepted, with 
    additional data, which may entail further experiments. If gathering more 
    data or doing more experiments is not possible, please include a caveat 
    that the relevant conclusion is preliminary.
  `,
    'accept, with the addition of missing essential data; in the absence of these data, then reject': `
    Your article "${stripHTML(articleTitle)}" has been conditionally accepted 
    as it requires the addition of missing essential data.  If gathering more 
    data or doing more experiments is not possible, we will, unfortunately, 
    need to reject your article. 
  `,
  }

  let reviseLetter = `
    <p>
    Dear ${authorName},
    </p>
    <p>
    Thank you for submitting your manuscript "${stripHTML(
      articleTitle,
    )}" to microPublication Biology.
    </p>
    <p>
    Before sending your article out for peer-review we kindly ask you to address the following in the <a href="${articleUrl}">submission platform</a>:
    </p>
  `

  if (reviewExists) {
    reviseLetter = `
      <p>
      Dear ${authorName},
      </p>
      <p>
      ${reviseQualifier && reviseQualifierLetters[reviseQualifier]}
      </p>
      <p>
      ** list revision requests here **
      </p>
      <p>
      We kindly ask you to address each point and summarize your changes in line with 
      the reviewer's response in the 'Comments to Editor' section on the platform. In 
      order to expedite the processing of your revised manuscript, please be as 
      specific as possible in your responses.
      </p>
      <p>
      The microPublication Editorial Team
      </p>
    `
  } else if (isResubmission) {
    reviseLetter = `
      <p>
      Dear ${authorName},
      </p>
      <p>
      Thank you for submitting a revised version of the manuscript "${stripHTML(
        articleTitle,
      )}" to microPublication Biology.
      </p>
      <p>
      Before we can move further, there are additional issues that need to be addressed:
      <p>
      ** list revision requests here **
      </p>
      <p>
      We kindly ask you to address each point and summarize your changes in line with 
      the request in the 'Comments to Editor' section on the platform. In order to expedite 
      the processing of your revised manuscript, please be as specific as possible in your 
      responses.
      </p>
      <p>
      The microPublication Editorial Team
      </p>
    `
  } else if (curatorResponse) {
    reviseLetter = `
      <p>
      Dear ${authorName},
      </p>
      <p>
      We received the curatorial review and we list below the additional issues that need to be addressed.
      </p>
      <p>
      Please address each point and summarize your changes in the ‘Comments to Editor’ section on the platform. In order to expedite the processing of your revised manuscript, please be as specific as possible in your responses.
      </p>
      <p>
      The microPublication Editorial Team
      </p>
      <p>
      Curator response:
      </p>
      <p>
      ${curatorResponse}
      </p>
    `
  }

  const declineLetter = `
      <p>
      Dear ${authorName},
      </p>
      <p>
      We regret to inform you we need to decline your manuscript "${stripHTML(
        articleTitle,
      )}".
      </p>
      <p>
      Sincerely,
      </p>
      <p>
      The microPublication  Editorial Team
      </p>
  `

  const publishLetter = `
    <p>
    Dear Authors,
    </p>
    <p>
    Congratulations on your new publication! We are pleased to let you know that your microPublication is 
    now available online. You can access it here: <a href="${publishedUrl}">${publishedUrl}</a>
    </p>
    <p>
    Your article will be sent to PubMed Central in 2 weeks. Please make sure there are no typos, errors or 
    omissions in your article, including your title, author names, affiliations, reagents, etc. in addition 
    to your reported results. If you want to make corrections, contact us with the title of your article and 
    your requested edits at <a href="mailto:editors@micropublication.org">editors@micropublication.org</a>.
    </p>
    <p>
    After two weeks, any correction will require a separate corrigendum article at the editor's discretion.
    </p>
    <p>
    Thank you for submitting your data to us. We look forward to working with you again.
    </p>
    <p>
    For your records, this is your article's citation:<br />
    "${citation}"
    </p>
    <p>
    Best wishes,
    </p>
    <p>
    The microPublication Team
    </p>
  `

  const curationLetter = proteopedia
    ? `
    <p>
    Dear ${authorName},
    </p>
    <p>
    We are happy to let you know that your article "${stripHTML(
      articleTitle,
    )}" has passed academic peer-review and is now in the hands of Proteopedia for building 3D-Complements, as 
    you agreed to at article submission. You will be contacted by the Proteopedia team as they need to work 
    with you directly. 
    </p>
    <p>
    Once we receive the models we will proceed with the proof stage.
    </p>
    <p>
    Best wishes,
    </p>
    <p>
    The microPublication Editors
    </p>
    <p>
    Dear ${authorName},
    </p>
    <p>
    We are happy to let you know that your article "${stripHTML(
      articleTitle,
    )}" has passed academic peer-review and is now in the 
    hands of a database curator for validation and review of adherence to community standards.  As a reminder, 
    each manuscript sent to microPublication Biology undergoes two rounds of reviews, academic peer review and 
    curatorial peer review. An article needs to pass both academic and curatorial peer-review in order to be 
    accepted. You can read more in our 
    <a href="https://www.micropublication.org/about/for-authors/#peer-review-process">instructions for authors</a>. 
    We will let you know if there are issues that still need to be addressed such as corrections to nomenclature 
    or addition of missing essential details.
    </p>
    <p>
    Best wishes,
    </p>
    <p>
    The microPublication Editors
    </p>
  `
    : `
    <p>
    Dear ${authorName},
    </p>
    <p>
    We are happy to let you know that your article "${stripHTML(
      articleTitle,
    )}" has passed academic peer-review and is now in the 
    hands of a database curator for validation and review of adherence to community standards.  As a reminder, 
    each manuscript sent to microPublication Biology undergoes two rounds of reviews, academic peer review and 
    curatorial peer review. An article needs to pass both academic and curatorial peer-review in order to be 
    accepted. You can read more in our 
    <a href="https://www.micropublication.org/about/for-authors/#peer-review-process">instructions for authors</a>. 
    We will let you know if there are issues that still need to be addressed such as corrections to nomenclature or 
    addition of missing essential details.
    </p>
    <p>
    Best wishes,
    </p>
    <p>
    The microPublication Editors
    </p>
  `

  return (
    <Wrapper>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validations}
      >
        {formProps => {
          const {
            errors,
            setFieldTouched,
            setFieldValue,
            touched,
            values,
          } = formProps

          saveDecision &&
            saveDecision({
              decision: values.decision,
              decisionLetter: values.decisionLetter,
            })

          let decisionStatus = decision
          if (decision === 'accept') decisionStatus = 'accept and send proofs'
          else if (decision === 'curation') decisionStatus = '3rd party review'

          let submitButton = 'Send to Authors'
          if (values.decision === 'curation' && curatorWarning)
            submitButton = 'Set Curator'
          if (values.decision === 'publish' && skippedProof)
            submitButton = 'Proofs Skipped'

          const defaultLetter = value => {
            setFieldValue('key', value)

            if (value === 'accept') {
              setFieldValue('decisionLetter', proofsLetter)
            } else if (value === 'publish') {
              setFieldValue('decisionLetter', publishLetter)
            } else if (value === 'revise') {
              setFieldValue('decisionLetter', reviseLetter)
            } else if (value === 'reject') {
              setFieldValue('decisionLetter', rejectLetter)
            } else if (value === 'decline') {
              setFieldValue('decisionLetter', declineLetter)
            } else if (value === 'curation') {
              setFieldValue('decisionLetter', curationLetter)
            }
          }

          return (
            <>
              {submitted && (
                <>
                  <Status status="primary">decision submitted</Status>: &nbsp;
                  <Status status={decision}>{decisionStatus}</Status>
                </>
              )}
              {!submitted && (
                <Radio
                  error={errors.decision}
                  handleChange={defaultLetter}
                  inline
                  label="Decision"
                  name="decision"
                  options={radioOptions}
                  readOnly={submitted}
                  required={!submitted}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  values={values}
                />
              )}
              {!submitted &&
                values.decision === 'curation' &&
                curatorWarning && (
                  <Status status="error">Need to assign curator</Status>
                )}

              {!submitted && values.decision === 'publish' && skippedProof && (
                <Status status="error">
                  Article has not been accepted to proofs
                </Status>
              )}

              <Editor
                bold
                error={errors.decisionLetter}
                italic
                key={`letter-${values.key}`}
                label="Decision letter"
                link
                name="decisionLetter"
                placeholder="Make some comments to the author"
                readOnly={submitted}
                required={!submitted}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched}
                value={values.decisionLetter}
              />
              {values.decision === 'reject' && (
                <Editor
                  bold
                  error={errors.confidentialComments}
                  italic
                  key={`comments-${values.key}`}
                  label="Confidential comments"
                  link
                  name="confidentialComments"
                  placeholder="Make some comments that will not be sent to the author"
                  readOnly={submitted}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  value={values.confidentialComments}
                />
              )}

              {!submitted && (
                <Button primary type="submit">
                  {submitButton}
                </Button>
              )}
              {!submitted &&
                values.decision === 'curation' &&
                !dbReferenceId && (
                  <Status status="error">Need to set DB Reference ID</Status>
                )}
            </>
          )
        }}
      </Form>
    </Wrapper>
  )
}

DecisionSection.propTypes = {
  /** Title of article */
  articleTitle: PropTypes.string,
  /** Url for the article */
  articleUrl: PropTypes.string,
  /** Name of submitting author */
  authorName: PropTypes.string,
  /** Citation of article */
  citation: PropTypes.string,
  /** Confidential comments */
  confidentialComments: PropTypes.string,
  /** Warn when no curator is assigned */
  curatorWarning: PropTypes.bool,
  dbReferenceId: PropTypes.string,
  /** Decision made by the editors */
  decision: PropTypes.oneOf([
    'accept',
    'decline',
    'reject',
    'revise',
    'curation',
  ]),
  /** Decision letter text content */
  decisionLetter: PropTypes.string,
  /** Url for the invoice */
  invoiceUrl: PropTypes.string,
  /** Is this a resubmission? */
  isResubmission: PropTypes.bool,
  /** Fetch saved (but not submitted) decision */
  getSavedDecision: PropTypes.func,
  /** Open confirmation modal when decision is accept */
  onClickAcceptConfirm: PropTypes.func,
  onClickTeamManager: PropTypes.func,
  /** Status of payment */
  paymentStatus: PropTypes.string,
  /** Link to the payment page */
  paymentUrl: PropTypes.string,
  /** Link to proof */
  proofLink: PropTypes.string,
  /** URL to final published article */
  publishedUrl: PropTypes.string,
  /** Whether a review has been submitted */
  reviewExists: PropTypes.bool,
  /** The qualifer for review revise decision */
  reviseQualifier: PropTypes.string,
  /** Save decision without submitting */
  saveDecision: PropTypes.func,
  /** Submit decision */
  submitDecision: PropTypes.func,
  /** Whether this decision is submitted or not */
  submitted: PropTypes.bool,
  proteopedia: PropTypes.string,
  skippedProof: PropTypes.bool,
  curatorResponse: PropTypes.string,
  invoiceNo: PropTypes.string,
}

DecisionSection.defaultProps = {
  articleTitle: null,
  articleUrl: null,
  authorName: null,
  citation: null,
  confidentialComments: null,
  curatorResponse: null,
  curatorWarning: false,
  dbReferenceId: null,
  decision: null,
  decisionLetter: null,
  invoiceUrl: null,
  isResubmission: false,
  getSavedDecision: null,
  onClickAcceptConfirm: null,
  onClickTeamManager: null,
  paymentStatus: null,
  paymentUrl: null,
  proofLink: null,
  publishedUrl: null,
  reviewExists: false,
  reviseQualifier: null,
  saveDecision: null,
  submitDecision: null,
  submitted: false,
  proteopedia: null,
  skippedProof: false,
  invoiceNo: null,
}

export default withTheme(DecisionSection)
