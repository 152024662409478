import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { diff } from './_cssFragments'

const Wrapper = styled.div``

const StyledContribution = styled.span`
  ${diff}
`

const Contribution = props => {
  const { index, isAdded, isRemoved, showDiff, value } = props

  return (
    <Wrapper>
      <StyledContribution
        isAdded={isAdded}
        isRemoved={isRemoved}
        key={`contribution-${index}`}
        showDiff={showDiff}
      >
        {value}
      </StyledContribution>
    </Wrapper>
  )
}

Contribution.propTypes = {
  /** The affiliation's position in the list. Number referenced next to author. */
  index: PropTypes.number.isRequired,
  /** Whether it was an addition. For diffs. */
  isAdded: PropTypes.bool,
  /** Whether it was a removal. For diffs. */
  isRemoved: PropTypes.bool,
  /** Whether to show diffs */
  showDiff: PropTypes.bool,
  /** The affiliation */
  value: PropTypes.string.isRequired,
}

Contribution.defaultProps = {
  isAdded: false,
  isRemoved: false,
  showDiff: true,
}

export default Contribution
