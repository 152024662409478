import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from '@apollo/client'
import omit from 'lodash/omit'

import {
  REVIEWER_PREVIEW,
  REVIEWER_PANEL,
  SAVE_REVIEW,
  SEND_CHAT,
  SUBMIT_REVIEW,
} from '../../graphql'

import {
  ChatModal,
  DateParser,
  ReviewerGuidelines,
  ReviewerPanel,
  ReviewSubmissionConfirmation,
  SyncedTabs,
} from '../../../ui'

import {
  transformChatMessages,
  getFromStorage,
  saveToStorage,
} from '../_helpers/common'

import { ArticlePreview } from '../../components/ui'
import { exportManuscriptToPDF } from '../../fetch/exportManuscript'
import BarModal from '../../../ui/src/reviewerPanel/BarModal'

const makeOptions = theme => [
  {
    color: theme.colorSuccess,
    label: 'ACCEPT version as is',
    value: 'accept version as is',
  },
  {
    color: theme.colorText,
    label: 'ACCEPT, with MINOR modifications to figure and/or text',
    value: 'accept, with minor modifications to figure and/or text',
  },
  {
    color: theme.colorText,
    label: 'ACCEPT, with MAJOR modifications to figure and/or text',
    value: 'accept, with major modifications to figure and/or text',
  },
  {
    color: theme.colorText,
    label:
      'ACCEPT, with ADDITIONAL DATA/experiment OR a CAVEAT concerning the conclusion given the missing information, as well as any additional minor or major modifications',
    value:
      'accept, with additional data/experiment or a caveat concerning the conclusion given the missing information, as well as any additional minor or major modifications',
  },
  {
    color: theme.colorText,
    label:
      'ACCEPT, with the addition of MISSING ESSENTIAL DATA; in the absence of these data, then REJECT',
    value:
      'accept, with the addition of missing essential data; in the absence of these data, then reject',
  },
  {
    color: theme.colorError,
    label: 'REJECT',
    value: 'reject',
  },
]

/* eslint-disable-next-line react/prop-types */
const Label = ({ created, index }) => (
  <DateParser dateFormat="MM.DD.YY HH:mm" timestamp={new Date(Number(created))}>
    {timestamp => (
      <span>
        {index === 0
          ? `Original: ${timestamp}`
          : `Revision ${index}: ${timestamp}`}
      </span>
    )}
  </DateParser>
)

const ReviewerView = props => {
  const { manuscriptId } = props

  /**
   * Handle modals with state
   */

  const [showChatModal, setShowChatModal] = useState(false)
  const [showConfirmSubmission, setShowConfirmSubmission] = useState(false)
  const [showReviewerGuidelines, setShowReviewerGuidelines] = useState(false)
  const [showBar, setShowBar] = useState(false)
  const [submissionData, setSubmissionData] = useState(null)

  /**
   * Queries & mutations
   */
  const { data: previewData, loading: previewLoading } = useQuery(
    REVIEWER_PREVIEW,
    {
      variables: {
        id: manuscriptId,
      },
    },
  )

  const { data: panelData, loading: panelLoading } = useQuery(REVIEWER_PANEL, {
    variables: { id: manuscriptId },
  })

  const refetchQuery = {
    refetchQueries: [
      {
        query: REVIEWER_PANEL,
        variables: { id: manuscriptId },
      },
    ],
  }

  const [saveReviewMutation] = useMutation(SAVE_REVIEW, refetchQuery)
  const [submitReviewMutation] = useMutation(SUBMIT_REVIEW, refetchQuery)
  const [sendChatMutation] = useMutation(SEND_CHAT, refetchQuery)

  /**
   * Left side: preview
   */

  const leftSections =
    previewData &&
    previewData.manuscript &&
    previewData.manuscript.versions.map((version, index) => ({
      key: version.id,
      label: <Label created={version.created} index={index} />,
      content: (
        <ArticlePreview
          article={version}
          categories={previewData.manuscript.categories}
          exportManuscript={exportManuscriptToPDF}
          manuscriptId={manuscriptId}
          previousVersion={previewData.manuscript.versions[index - 1]}
          showHeader={false}
          submissionTypes={previewData.manuscript.submissionTypes}
        />
      ),
    }))

  /**
   * Right side: reviewer panel
   */

  let chatMessages, sendChatMessage, saveChat, getSavedChat
  let rightSections
  let submitReview

  if (panelData && panelData.manuscript && panelData.manuscript.versions) {
    const { manuscript } = panelData
    const { chatThreads, versions } = manuscript

    // there can only be one chat per reviewer per manuscript
    const chatThread = chatThreads[0]
    chatMessages = transformChatMessages(chatThread.messages)

    sendChatMessage = content =>
      sendChatMutation({
        variables: {
          input: {
            chatThreadId: chatThread.id,
            content,
          },
        },
      })

    saveChat = message => saveToStorage(message, `chat_${chatThread.id}`)
    getSavedChat = () => getFromStorage(`chat_${chatThread.id}`)

    rightSections = versions.map((version, index) => {
      const { decision, reviewerMessage, reviews } = version
      const latest = index === versions.length - 1

      // there can only be one review per reviewer per version
      const thisReview = reviews[0]
      const { submitted } = thisReview.status

      const review = {
        content: thisReview.content,
        recommendation: thisReview.recommendation,
        openAcknowledgement: thisReview.openAcknowledgement,
        askedToSeeRevision: thisReview.askedToSeeRevision,
        confidentialComments: thisReview.confidentialComments,
        reviseQualifier: thisReview.reviseQualifier,
      }

      let save, handleClickSubmit

      if (latest) {
        save = input => {
          const reviewInput = omit(input, ['curated'])

          return saveReviewMutation({
            variables: {
              reviewId: thisReview.id,
              input: reviewInput,
            },
          })
        }

        submitReview = input => {
          const reviewInput = omit(input, ['curated'])
          return submitReviewMutation({
            variables: {
              reviewId: thisReview.id,
              input: reviewInput,
            },
          }).then(() => {
            setSubmissionData(null)
            setShowConfirmSubmission(false)
          })
        }

        handleClickSubmit = input => {
          setSubmissionData(input)
          setShowConfirmSubmission(true)
        }
      }

      const canStillReview = latest && !decision && !submitted

      return {
        key: version.id,
        label: <Label created={version.created} index={index} />,
        content: (
          <ReviewerPanel
            canStillReview={canStillReview}
            chatMessages={chatMessages}
            decision={decision}
            getSavedChat={getSavedChat}
            key={version.id}
            makeOptions={makeOptions}
            onClickChat={() => setShowChatModal(true)}
            review={review}
            reviewerMessage={reviewerMessage}
            save={save}
            saveChat={saveChat}
            sendChatMessage={sendChatMessage}
            showBar={() => setShowBar(true)}
            showReviewerGuidelines={() => setShowReviewerGuidelines(true)}
            submit={handleClickSubmit}
            submitted={submitted}
          />
        ),
      }
    })
  }

  /**
   * Render
   */

  return (
    <>
      <SyncedTabs
        leftHeader="Article Preview"
        leftLoading={previewLoading}
        leftSections={leftSections}
        rightHeader="Reviewer Panel"
        rightLoading={panelLoading}
        rightSections={rightSections}
      />

      {/* Modals */}

      <ChatModal
        getSavedChat={getSavedChat}
        isOpen={showChatModal}
        messages={chatMessages}
        onRequestClose={() => setShowChatModal(false)}
        saveChat={saveChat}
        sendMessage={sendChatMessage}
      />

      <ReviewSubmissionConfirmation
        isOpen={showConfirmSubmission}
        onConfirm={() => submitReview(submissionData)}
        onRequestClose={() => {
          setSubmissionData(null)
          setShowConfirmSubmission(false)
        }}
      />

      <ReviewerGuidelines
        isOpen={showReviewerGuidelines}
        onConfirm={() => setShowReviewerGuidelines(false)}
        onRequestClose={() => setShowReviewerGuidelines(false)}
      />

      <BarModal
        isOpen={showBar}
        onConfirm={() => setShowBar(false)}
        onRequestClose={() => setShowBar(false)}
      />
    </>
  )
}

ReviewerView.propTypes = {
  manuscriptId: PropTypes.bool.isRequired,
}

export default ReviewerView
