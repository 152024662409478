import React, { useState, useContext } from 'react'
import {
  useQuery,
  useLazyQuery,
  useMutation,
  useApolloClient,
} from '@apollo/client'
import clone from 'lodash/clone'

import {
  GET_REVIEWS,
  USER_MANAGER_DATA,
  GET_MANUSCRIPTS,
  GET_REMINDERS,
  CANCEL_JOB,
  RESCHEDULE_JOB,
  UPDATE_USERNAME,
  UPDATE_PERSONAL_INFORMATION,
  TOGGLE_IS_ACTIVE,
  OVERRIDE_VERIFY_EMAIL,
  RESEARCH_AREAS,
  SPECIALTIES,
} from '../graphql'
import SEARCH_SPECIES from '../graphql/species'

import NewUserManager from '../../ui/src/userManager/UserManager'
import ProfileModal from '../../ui/src/modals/ProfileModal'
import ReviewsModal from '../../ui/src/modals/ReviewsModal'
import ArticlesModal from '../../ui/src/modals/ArticlesModal'
import RemindersModal from '../../ui/src/modals/RemindersModal'
import CurrentUserContext from '../userContext'

const UserManagerPage = () => {
  const { currentUser } = useContext(CurrentUserContext)

  const isScienceOfficer =
    currentUser &&
    (currentUser.auth.isGlobalScienceOfficer ||
      currentUser.auth.isGlobalSectionEditor)

  const isEditor =
    currentUser && (currentUser.admin || currentUser.auth.isGlobalEditor)

  if (!isEditor && !isScienceOfficer)
    return 'You do not have need privileges to view this page'

  const [showProfileModal, setShowProfileModal] = useState(false)
  const [showReviewModal, setShowReviewModal] = useState(false)
  const [showArticleModal, setShowArticleModal] = useState(false)
  const [showRemindersModal, setShowRemindersModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [nameSearch, setNameSearch] = useState(null)

  const client = useApolloClient()

  const searchSpecies = async input => {
    const res = await client.query({
      query: SEARCH_SPECIES,
      fetchPolicy: 'network-only',
      variables: {
        search: input,
        limit: 10,
      },
    })

    if (res.data && res.data.searchSpecies) {
      return res.data.searchSpecies.map(value => ({
        value: value.scientificName,
        label: value.scientificName,
      }))
    }

    return []
  }

  const { data, loading } = useQuery(USER_MANAGER_DATA)

  const { data: researchAreasData } = useQuery(RESEARCH_AREAS)

  const { data: specialtiesData } = useQuery(SPECIALTIES)

  const [cancelJob] = useMutation(CANCEL_JOB, {
    refetchQueries: [
      {
        query: GET_REMINDERS,
        variables: { userId: selectedUser && selectedUser.id },
      },
      {
        query: USER_MANAGER_DATA,
      },
    ],
  })

  const [rescheduleJob] = useMutation(RESCHEDULE_JOB, {
    refetchQueries: [
      {
        query: GET_REMINDERS,
        variables: { userId: selectedUser && selectedUser.id },
      },
    ],
  })

  const [updateUsername] = useMutation(UPDATE_USERNAME)

  const [updatePersonalInformation] = useMutation(UPDATE_PERSONAL_INFORMATION, {
    refetchQueries: [
      {
        query: USER_MANAGER_DATA,
      },
    ],
  })

  const [toggleIsActive] = useMutation(TOGGLE_IS_ACTIVE)
  const [overrideVerifyEmail] = useMutation(OVERRIDE_VERIFY_EMAIL)

  const [getReviews, { data: reviewsData }] = useLazyQuery(GET_REVIEWS)
  const [getManuscripts, { data: articleData }] = useLazyQuery(GET_MANUSCRIPTS)
  const [getReminders, { data: remindersData }] = useLazyQuery(GET_REMINDERS)

  const users =
    data &&
    clone(data.users).sort((a, b) => {
      if (!a.surname) return -1
      if (!b.surname) return 1
      if (a.surname.toLowerCase() < b.surname.toLowerCase()) return -1
      if (a.surname.toLowerCase() > b.surname.toLowerCase()) return 1
      return 0
    })

  const editUser = userId => {
    setShowProfileModal(true)
    setSelectedUser(users.find(user => user.id === userId))
  }

  const openReviewData = userId => {
    getReviews({ variables: { userId } })
    setShowReviewModal(true)
  }

  const openArticleData = userId => {
    getManuscripts({ variables: { userId } })
    setShowArticleModal(true)
  }

  const openRemindersData = userId => {
    getReminders({ variables: { userId } })
    setShowRemindersModal(true)
    setSelectedUser(users.find(user => user.id === userId))
  }

  const updateUserData = input => {
    const {
      userId,
      username,
      givenNames,
      surname,
      orcid,
      isActive,
      reviewer,
      species,
      researchArea,
      specialty,
      notReviewer,
      isConfirmed,
    } = input

    updateUsername({ variables: { input: { userId, username } } })

    const user = data.users.find(u => u.id === userId)
    if (user.isActive !== isActive) toggleIsActive({ variables: { userId } })

    if (!user.isConfirmed && isConfirmed) {
      overrideVerifyEmail({
        variables: { userId },
      })
    }

    return updatePersonalInformation({
      variables: {
        input: {
          givenNames,
          surname,
          orcid,
          userId,
          reviewer,
          species,
          researchArea,
          specialty,
          notReviewer,
        },
      },
    })
  }

  return (
    <>
      <NewUserManager
        editUser={editUser}
        isEditor={isEditor}
        loading={loading}
        nameSearch={nameSearch}
        openArticleData={openArticleData}
        openRemindersData={openRemindersData}
        openReviewData={openReviewData}
        setNameSearch={setNameSearch}
        users={users}
      />
      <ProfileModal
        isOpen={showProfileModal}
        onConfirm={() => setShowProfileModal(false)}
        onRequestClose={() => setShowProfileModal(false)}
        researchAreas={researchAreasData && researchAreasData.researchAreas}
        searchSpecies={searchSpecies}
        specialties={specialtiesData && specialtiesData.specialties}
        updateUserData={updateUserData}
        user={selectedUser}
      />
      <ReviewsModal
        isOpen={showReviewModal}
        onConfirm={() => setShowReviewModal(false)}
        onRequestClose={() => setShowReviewModal(false)}
        reviewsData={reviewsData && reviewsData.reviewHistory}
      />
      <ArticlesModal
        articleData={articleData && articleData.authorManuscripts}
        isOpen={showArticleModal}
        onConfirm={() => setShowArticleModal(false)}
        onRequestClose={() => setShowArticleModal(false)}
      />
      <RemindersModal
        cancelJob={cancelJob}
        isOpen={showRemindersModal}
        onConfirm={() => setShowRemindersModal(false)}
        onRequestClose={() => setShowRemindersModal(false)}
        remindersData={remindersData && remindersData.userReminders}
        rescheduleJob={rescheduleJob}
      />
    </>
  )
}

export default UserManagerPage
