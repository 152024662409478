/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react'

import Modal from './Modal'
import AcceptJs from '../authorizeNet/AcceptJs'
import ModalHeader from './ModalHeader'

const PaymentModal = props => {
  const { handlePayment, amount, manuscriptId } = props

  const Header = <ModalHeader text="Payment" />

  return (
    <Modal headerComponent={Header} size="medium" {...props}>
      <AcceptJs
        amount={amount}
        handlePayment={handlePayment}
        manuscriptId={manuscriptId}
      />
    </Modal>
  )
}

export default PaymentModal
